import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  Typography,
  Box,
  Stack
} from '@mui/material'
import Zoom from '@mui/material/Zoom'
import EmojiPicker from 'emoji-picker-react'
import gsap from 'gsap'
import { forwardRef, useEffect, useRef } from 'react'

import { useEmojiReactionContext } from '@/contexts/Events/EventView/EmojiReactionContext'
import { Button } from '@c/uiParts/common/Button'

const Transition = forwardRef((props: any, ref) => (
  <Zoom ref={ref} {...props}>
    {props.children}
  </Zoom>
))

export function EmojiReactionDialog() {
  const {
    emoji, updateEmoji,
    isOpenPicker, updateIsOpenPicker,
    user,
    submitEmoji
  } = useEmojiReactionContext()
  const emojiRef = useRef<HTMLDivElement>(null)

  const closeDialog = () => {
    updateIsOpenPicker(false)
  }

  const onSubmit = async () => {
    try {
      await submitEmoji()
      closeDialog()
    } catch (e) {
      console.error(e)
    }
  }

  // useEffectでemojiRefの要素をgsapで10秒ごとに揺らす
  useEffect(() => {
    const emojiElement = emojiRef.current
    if (emojiElement) {
      gsap.timeline({ repeat: 0, delay: 0.5, defaults: { yoyo: true, duration: 0.3 } })
        .to(emojiElement, { rotate: '5deg' })
        .to(emojiElement, { rotate: '-5deg' })
        .to(emojiElement, { rotate: '0deg' })
    }
  }, [emoji, isOpenPicker])

  return (
    <Box>
      <Dialog
        open={isOpenPicker}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={(theme) => ({
          fontWeight: 'bold',
          backgroundColor: theme.palette.base.light,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 24px',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
        })}
        >
          {user?.user?.display_name || '???'}
          さんを応援する🥳
          <MuiButton disableRipple sx={{ color: 'text.primary' }} onClick={closeDialog}>
            <CloseIcon
              sx={{
                width: '32px',
                height: '32px'
              }}
            />
          </MuiButton>
        </DialogTitle>
        <DialogContent sx={{ mt: '8px' }}>
          <Stack direction="row" spacing="8px" sx={{ alignItems: 'center' }}>
            <EmojiPicker onEmojiClick={(moji) => {
              updateEmoji(moji.emoji)
            }}
            />
            <Stack width="120px" sx={{ alignItems: 'center' }}>
              <Typography sx={{ fontSize: '64px' }} ref={emojiRef}>
                {emoji === '' ? '👍' : emoji}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '12px 20px'
          })}
        >
          <Stack direction="row" spacing="16px">
            <Button
              bgColor={(theme) => theme.palette.primary.main}
              textColor={(theme) => theme.palette.base.main}
              isRounded
              isShadow
              sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
              onClick={onSubmit}
            >
              応援する
              {' '}
              <SendIcon />
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

    </Box>
  )
}
