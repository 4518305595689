import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore'

import { db } from '@/firebase'
import { EventReactionDoc, PartiallyPartial } from '@/types/common'

export const useEventReactions = () => {
  // --- core methods ---
  // const getParticipant = async ({ eventId, userId }: {
  //   eventId: string; userId: string;
  // }) => {
  //   const participantDocRef = doc(db, 'events', eventId, 'participants', userId)
  //   const res = await getDoc(participantDocRef)
  //   return res.data()
  // }

  // // useCase: 主催者の有無チェック→いなくば自動退室
  // const listActiveParticipants = async ({ eventId }: {eventId: string}): Promise<ParticipantDoc[]> => {
  //   // アクティブな参加者のみ
  //   const participantColRef = collection(
  //     db,
  //     'events',
  //     eventId,
  //     'participants'
  //   ) as CollectionReference<ParticipantDoc>
  //   const q = query(participantColRef, where('finished_at', '==', null))
  //   const querySnapshot = await getDocs(q)
  //   return querySnapshot.docs.map((resdoc) => resdoc.data())
  // }
  // useCase: 参加時
  // useCase: 退室時にfinished_atを更新
  const updateReactions = async ({ eventId, data }: {
    eventId: string
    data: PartiallyPartial<EventReactionDoc, 'created_at'|'updated_at'>
  }) => {
    // firestoreのcollection refであるreactionColRefに対して、要素を追加する
    const reactionRef = doc(collection(db, 'events', eventId, 'reactions'))
    await setDoc(reactionRef, {
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
      ...data
    })
  }

  return {
    // getParticipant,
    // listActiveParticipants,
    updateReactions
  }
}
