import GridViewIcon from '@mui/icons-material/GridView'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { Box, Stack } from '@mui/material'
import { ReactNode } from 'react'

import { ShareScreen } from '@/components/uiParts/Events/EventView/ShareScreen'
import { MY_PEER_ID, SCREEN_MODE } from '@/constants/common'
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '@/constants/styles'
import { useScreenModeContext } from '@/contexts/ScreenModeContext'

type Props = {
  remoteStreams: MediaStream[]
  canvasLocalStream: MediaStream | null
}

const SCALE_NUM = 2.1
const MINOR_SCALE_NUM = 0.5

const renderScreen = (
  stream: any,
  index: number,
  onClickScreen: (streamProp: any) => void,
  screenScale: number,
  icon: ReactNode
) => (
  <Box
    key={stream?.peerId || index}
    sx={{
      minWidth: `${SCREEN_WIDTH * screenScale}px`,
      width: `${SCREEN_WIDTH * screenScale}px`
    }}
  >
    <ShareScreen
      stream={stream}
      mode={stream?.peerId === MY_PEER_ID ? 'local' : 'remote'}
      width={SCREEN_WIDTH * screenScale}
      height={SCREEN_HEIGHT * screenScale}
      onClickScreen={() => onClickScreen(stream)}
      hoverIcon={icon}
    />
  </Box>
)

export function StageUI({
  remoteStreams,
  canvasLocalStream
}: Props) {
  const { selectedScreenId, updateSelectedScreenId, updateMode } = useScreenModeContext()

  const switchStageScreen = (stream: any) => {
    updateSelectedScreenId(stream?.peerId || MY_PEER_ID)
  }
  const switchUiModeToGrid = () => {
    updateMode(SCREEN_MODE.grid)
  }
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        maxWidth: '880px',
        maxHeight: 'calc(100vh - 236px)',
        overflowY: 'auto',
        paddingRight: '20px',
        gap: '32px',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      {[canvasLocalStream, ...remoteStreams]
        .filter((stream) => (stream as any)?.peerId === selectedScreenId)
        .map((stream, index) => renderScreen(
          stream,
          index,
          switchUiModeToGrid,
          SCALE_NUM,
          <GridViewIcon />
        ))}

      <Stack
        direction="row"
        sx={{
          width: '100%',
          maxWidth: '880px',
          overflowX: 'auto',
          gap: '32px',
          flexWrap: 'no-wrap'
        }}
      >
        {[canvasLocalStream, ...remoteStreams]
          .filter((stream) => (stream as any)?.peerId !== selectedScreenId)
          .map((stream, index) => renderScreen(
            stream,
            index,
            switchStageScreen,
            MINOR_SCALE_NUM,
            <ZoomOutMapIcon />
          ))}
      </Stack>
    </Stack>

  )
}
