// 1. participant_idsにレコードを作成
// 2. イベントのparticipant_id_stringsを更新
// （離脱時に、participant_idsから削除）

import { doc, getDoc, serverTimestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { useAuthContext } from '#/src/contexts/AuthContext'
import { useParticipateEventContext } from '#/src/contexts/ParticipateEvent'
import { db } from '@/firebase'
import { updateEvent } from '@/hooks/firestore/useEvents/updateEvent'
import { removeDuplicateFromArray } from '@/utils/object/removeDuplicateFromArray'
import { removeElementFromArray } from '@/utils/object/removeElmentFromArray'

// only called by useEvents
export const useParticipateHooks = () => {
  const { updateParticipant, participants } = useParticipateEventContext()
  const { user } = useAuthContext()

  const [isFirstParticipate, setIsFirstParticipate] = useState(true)

  useEffect(() => {
    setIsFirstParticipate(!(participants.some((participant) => participant.user_id === user?.uid)))
  }, [participants, user])

  const participate = async (
    eventId: string,
    peerId: string
  ) => {
    // 既存のevent情報を取得する
    const eventRef = doc(db, 'events', eventId)
    const res = await getDoc(eventRef)
    const participant_id_strings = removeDuplicateFromArray([
      ...(res.data()?.participant_id_strings || []),
      user?.uid || ''
    ])

    const startedAt = res.data()?.started_at || serverTimestamp()
    const isOrganizer = (res.data()?.organizer_ids || []).includes(user?.uid || '')
    // event情報の参加者箇所を更新する
    await updateEvent(eventId, {
      ...res.data(),
      started_at: startedAt,
      participant_id_strings,
      ...(isOrganizer ? {
        finished_at: null
      } : {})
    })

    if (isFirstParticipate) {
      await updateParticipant({
        data: {
          user_id: user?.uid || '',
          peer_id: peerId,
          started_at: serverTimestamp(),
          finished_at: null,
          updated_at: serverTimestamp(),
          created_at: serverTimestamp()
        } })
    } else {
      await updateParticipant({
        data: {
          peer_id: peerId,
          finished_at: null,
          updated_at: serverTimestamp()
        } })
    }
  }

  const leave = async (eventId: string, userId: string) => {
    // 既存のevent情報を取得する
    const eventRef = doc(db, 'events', eventId)
    const res = await getDoc(eventRef)
    const participant_id_strings = removeElementFromArray(
      removeDuplicateFromArray(res.data()?.participant_id_strings || []),
      userId
    )

    // event情報の参加者箇所を更新する
    await updateEvent(eventId, {
      ...res.data(),
      participant_id_strings
    })
    if (!isFirstParticipate) {
      // participants subcollectionを更新
      await updateParticipant({
        data: {
          finished_at: serverTimestamp(),
          updated_at: serverTimestamp()
        }
      })
    }
  }

  return { participate, leave }
}
