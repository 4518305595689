import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/uiParts/common/Button'
import { EventStartDialog } from '@/components/uiParts/Events/EventStartDialog'
import { EventSummary } from '@/components/uiParts/Events/EventSummary'
import { useEvents } from '@/hooks/firestore/useEvents'
import { JoinedEventDoc } from '@/types/common'

export function EventsInprogress() {
  const [events, setEvents] = useState<JoinedEventDoc[]|null>(null)
  const navigate = useNavigate()

  const { listEvents, submitCreateEventForm } = useEvents()
  const fetchEvents = async () => {
    const resEvents = await listEvents({ mode: 'now' })
    setEvents(resEvents)
  }
  const handleEventCreateComplete = (id?: string) => {
    if (id) {
      navigate(`/events/${id}/preview`)
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <Stack spacing="10px" sx={{ width: '680px' }}>
      <Stack direction="row" sx={{ justifyContent: 'flex-end', padding: '0 16px' }}>
        <EventStartDialog
          titleText="新規イベント開始"
          submitText="イベント開始"
          onSubmit={submitCreateEventForm}
          onComplete={handleEventCreateComplete}
        >
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isShadow
            sx={{ fontSize: '12px', fontWeight: 'bold', padding: '8px 12px' }}
          >
            イベントを作成
          </Button>
        </EventStartDialog>
      </Stack>
      <Stack
        direction="row"
        sx={{
          padding: '12px',
          overflowY: 'auto',
          flexWrap: 'wrap',
          gap: '16px 8px'
        }}
      >
        {events && events.map((event) => (
          <EventSummary items={event} key={event.id} />
        ))}
        {(!events || events.length === 0) && (
          <Typography sx={(theme) => ({
            textAlign: 'center',
            fontSize: '12px',
            width: '100%',
            color: theme.palette.text.primary
          })}
          >
            現在イベントはありません😴
          </Typography>
        )}
      </Stack>

    </Stack>
  )
}
