import { collection, documentId, onSnapshot, query, where } from 'firebase/firestore'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { db } from '@/firebase'
import { UserDoc } from '@/types/common'

type ContextTypes = {
  dbUsers: UserDoc[]
  updateDBUsers: (userIds: string[]) => Promise<void>
}

const INITIAL_VALUES = {
  dbUsers: [],
  updateDBUsers: async () => {}
}

const UsersDBContext = createContext<ContextTypes>(INITIAL_VALUES)

export const useUsersDBContext = () => useContext(UsersDBContext)

type Props = {
  children: ReactNode
}

export function UsersDBProvider({ children }: Props) {
  const [users, setUsers] = useState<UserDoc[]>(INITIAL_VALUES.dbUsers)
  const [watchedUserIds, setWatchedUserIds] = useState<string[]>([])

  const updateDBUsers = async (userIds: string[]) => {
    const newWatchedUserIds = Array.from(new Set([...userIds, ...watchedUserIds])).sort()
    setWatchedUserIds(newWatchedUserIds)
  }

  useEffect(() => {
    if (watchedUserIds.length === 0) return () => {}
    const userColRef = collection(db, 'users')
    const userQ = query(userColRef, where(documentId(), 'in', watchedUserIds))
    const unsubUsersCollection = onSnapshot(userQ, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        switch (change.type) {
          case 'added':
          case 'modified':
            setUsers((prev) => {
              const prevUsers = prev.filter((user) => user.id !== change.doc.id)
              return [...prevUsers, { id: change.doc.id, ...change.doc.data() } as UserDoc]
            })
            break
          case 'removed':
            setUsers((prev) => prev.filter((user) => user.id !== change.doc.id))
            break
          default:
        }
      })
    })
    return () => {
      unsubUsersCollection()
    }
  }, [watchedUserIds])

  const value = useMemo(() => ({
    dbUsers: users, updateDBUsers
  }), [users, updateDBUsers])

  return (
    <UsersDBContext.Provider value={value}>
      {children}
    </UsersDBContext.Provider>
  )
}
