/* eslint-disable max-len */
const name = process.env.REACT_APP_ADMINISTRATOR_NAME
const email = process.env.REACT_APP_ADMINISTRATOR_EMAIL

export const markdown = `

# プライバシーポリシー

事業運営者（以下、「運営者」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、運営者がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。

## １．事業者情報

法人名：個人事業主

代表者：${name}

## ２．個人情報の取得方法

運営者は、ユーザーが利用登録をするとき、氏名・メールアドレス・クレジットカード番号など個人を特定できる情報を取得させていただきます。

## ３．個人情報の利用目的

- ユーザーが利用しているサービスの新機能や更新情報、キャンペーン情報などをメール送付によりご案内するため
- ユーザーが利用しているサービスのメンテナンスなど、必要に応じたご連絡をするため
- ユーザーからのお問い合わせに回答するため
- 利用規約に違反したユーザーの特定、その他不正不当な目的でサービスを利用したユーザーの特定をし、ご利用をお断りするため

個人情報の利用目的は、変更前後の関連性について合理性が認められる場合に限って変更するものとします。

個人情報の利用目的について変更を行った際は、変更後の目的について運営者所定の方法によってユーザーに通知し、加えてWebサイト上にも公表するものとします。

## ４．個人データの第三者提供について

運営者は以下の場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。

- 法令に基づく場合
- 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
- 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
- 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
- 次に掲げる事項をあらかじめ本人に通知または公表し、かつ運営者が個人情報保護委員会に届出をしたとき

1. 第三者への提供を利用目的とすること
2. 第三者に提供される個人データの項目
3. 第三者への提供の方法
4. 本人の求めに応じて当該個人情報の第三者への提供を停止すること
5. 本人の求めを受け付ける方法

## ５．匿名加工情報に関する取扱い

運営者は、匿名加工情報（特定の個人を識別できないよう加工した個人情報であって、復元ができないようにしたもの）を作成する場合、以下の対応を行います。

- 法令で定める基準に従い適正な加工を施す
- 法令で定める基準に従い安全管理措置を講じる
- 匿名加工情報に含まれる個人に関する情報の項目を公表する
- 作成元となった個人情報の本人を識別するため、他の情報と照合すること

## ６．保有個人データの開示、訂正

運営者は、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。

但し、個人情報保護法その他の法令により、運営者が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、1件あたり手数料1,000円（税込）を頂戴しておりますので、あらかじめ御了承ください。

## ７．個人情報取扱いに関する相談や苦情の連絡先

運営者の個人情報の取扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせ窓口よりご連絡ください。

## ８．SSL（Secure Socket Layer）について

運営者のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名、Emailアドレスなどの個人情報は自動的に暗号化されます。

## ９．cookieについて

cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当Webサイトを利用することができます。当Webサイトがcookieとして送るファイルは、個人を特定するような情報を含んでおりません。
お使いのWebブラウザの設定により、cookieを無効にすることも可能です。

## １０．プライバシーポリシーの制定日及び改定日

制定：2022年11月26日

## １１．免責事項

- 当Webサイトに掲載されている情報の正確さには万全を期していますが、利用者が当Webサイトの情報を用いて行う一切の行為に関して、運営者は一切の責任を負わないものとします。
- 運営者は、利用者が当Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
- 当Webサイトのご利用に際して、利用者が被った損害について運営者が責任を負う場合であっても、運営者の故意または重過失がない限り、運営者の責任は直接かつ通常の損害に限られるものとします。
- 適用法令により許容される限度において、本サービス及びコンテンツは、いかなる保証もなしに、「現状有姿」で提供されます。
- 当サイトはシステム障害またはサーバのメンテナンス等により、予告なく一時的または長期に停止する場合がございます。
- 当サイトは、本サービス等の提供の一時的中断または停止により、ユーザーが被ったいかなる損害も責任を負わないものとします。

## １２．著作権・肖像権

当Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されており、無断での使用や転用は禁止されています。

## １３．リンク

当Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンク設置をお断りすることがあります。

## １４．お問い合わせ

開示などのお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。

運営者名： ${name}

E-mail： ${email}
（なお、受付時間は平日9時から18時までとさせていただきます。）




`
