import 'react-native-get-random-values'
import { addHours, addSeconds, format } from 'date-fns'
import { createContext, useContext, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import type { ChildrenReactElement } from '@/types/common'
import type { SnackBarStateType, SnackBarActionType } from '@/types/contexts/Snackbar'

import { SNACKBAR_CLOSE_TIME } from '@/constants/common'

type ContextTypes = {
  messages: SnackBarStateType,
  snackbar: SnackBarActionType,
  closeSnackbar: (id: string) => void
}
const INITIAL_VALUES: ContextTypes = {
  messages: [],
  snackbar: () => {},
  closeSnackbar: () => {}
}

const SnackbarContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useSnackbarContext() {
  return useContext(SnackbarContext)
}

/**
 * reducerとcontextを子コンポーネントに渡す
 * @param children 子コンポーネント
 * @returns 子コンポーネント
 */
export function SnackbarContextProvider({ children }: ChildrenReactElement) {
  const [messages, setMessages] = useState<ContextTypes['messages']>([])

  const snackbar: ContextTypes['snackbar'] = ({
    position = 'bottom-left',
    severity = 'info',
    message,
    lastSeconds = SNACKBAR_CLOSE_TIME
  }) => {
    const closeDate = format(
      // 負の値に設定すると、1時間表示することになる
      lastSeconds > 0 ? addSeconds(new Date(), lastSeconds) : addHours(new Date(), 1),
      'yyyy-MM-dd HH:mm:ss'
    )
    setMessages([
      ...messages,
      {
        id: uuid(),
        position,
        message,
        severity,
        closeDate: closeDate || format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      }
    ])
  }

  const closeSnackbar: ContextTypes['closeSnackbar'] = (id: string) => {
    const newMessages = messages.filter(
      (message: ContextTypes['messages'][number]) => message.id !== id
    )
    setMessages(newMessages)
  }

  const context = useMemo(
    () => ({
      messages,
      snackbar,
      closeSnackbar
    }),
    [messages, snackbar, closeSnackbar]
  )

  return <SnackbarContext.Provider value={context}>{children}</SnackbarContext.Provider>
}
