import { styled, Button as MuiButton, Theme, ButtonProps } from '@mui/material'
import { ReactNode } from 'react'

type StyleProps = {
  bgColor: (value: Theme) => string
  textColor: (value: Theme) => string
  isShadow?: Boolean
  isRounded?: Boolean
}

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => !([
    'bgColor',
    'textColor',
    'isShadow',
    'isRounded'
  ].includes(prop as string))
})<StyleProps>(({ theme, bgColor, textColor, isShadow, isRounded }) => ({
  backgroundColor: bgColor(theme),
  color: textColor(theme),
  ...(isShadow ? { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' } : {}),
  ...(isRounded ? { borderRadius: '32px' } : {}),
  '&:hover': {
    backgroundColor: bgColor(theme)
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.button.disabled,
    color: theme.palette.text.disabled
  }
}))

type Props = StyleProps & ButtonProps & {
  children: ReactNode
}

export function Button({
  children,
  bgColor,
  textColor,
  isShadow = false,
  isRounded = false,
  ...rest
}: Props) {
  return (
    <StyledButton
      bgColor={bgColor}
      textColor={textColor}
      isShadow={isShadow}
      isRounded={isRounded}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}
