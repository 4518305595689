import { Stack } from '@mui/material'

import { S3_BUCKET_URL } from '@/constants/common'
import useMediaQuery from '@/hooks/common/useMediaQuery'
import { FeatureCardItem } from '@c/uiParts/Lp/ForthSection/FeatureCardItem'

export function ForthSection() {
  const isTablet = useMediaQuery({ variant: 'tablet' })
  return (
    <Stack sx={{ padding: '64px', alignItems: 'center' }}>
      <Stack spacing="48px" sx={{ padding: '48px 80px', backgroundColor: (theme) => theme.palette.base.main }}>
        <Stack
          spacing="80px"
          direction={isTablet ? 'column' : 'row'}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <FeatureCardItem
            imgLink={`${S3_BUCKET_URL}/imagine_members.png`}
            headText="作業部屋をスケジュールして、人を集める"
            bodyText="好きな時間にIDとパスワードを設定して、作業部屋を作成いただけます。"
          />
          <FeatureCardItem
            imgLink={`${S3_BUCKET_URL}/office_room.png`}
            headText="人のスケジュールした部屋に参加"
            bodyText="他の方がスケジュールした部屋に参加を申し込むことができます。時間になるとルームが作成され、通知がくるのでご参加ください。"
          />
        </Stack>
        <Stack
          spacing="80px"
          direction={isTablet ? 'column' : 'row'}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <FeatureCardItem
            imgLink={`${S3_BUCKET_URL}/man_charing_blur.png`}
            headText="画面のぼかしで安心して作業"
            bodyText="会社の作業をするような際に、画面に適度なぼかしを設定いただくことで、セキュアかつ作業している感を出すことができます。"
          />
          <FeatureCardItem
            imgLink={`${S3_BUCKET_URL}/man_cheeringup.png`}
            headText="応援リアクション"
            bodyText="他の方の作業をご覧になる際は、是非応援リアクション（絵文字など）を送っていただけると、お互いに集中できる環境になります。"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
