import { Box, Stack, styled } from '@mui/material'

const StyledContainer = styled(Stack)({
  alignItems: 'center'
})

const StyledHeadText = styled(Box)({
  fontSize: '17px',
  lineHeight: '24px',
  fontWeight: 'bold',
  textAlign: 'center'
})

const StyledBodyText = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '26px',
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

type Props = {
  imgLink: string
  headText: string
  bodyText: string
}

export function FeatureCardItemSp({ imgLink, headText, bodyText }: Props) {
  return (
    <StyledContainer spacing="20px">
      <img src={imgLink} width="200px" height="200px" alt="" />
      <Stack spacing="8px">
        <StyledHeadText>{headText}</StyledHeadText>
        <StyledBodyText>{bodyText}</StyledBodyText>
      </Stack>
    </StyledContainer>
  )
}
