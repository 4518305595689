import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props= {
  label: string;
  required?: boolean
  children: ReactNode
}

export function DialogFormWithLabel({ label, required = false, children }:Props) {
  return (
    <Stack
      direction="row"
      spacing="8px"
      sx={{
        padding: '10px 40px',
        alignItems: 'flex-start',
        width: '560px',
        justifyContent: 'flex-start'
      }}
    >
      <Stack direction="row">
        <Typography
          sx={{ width: '160px',
            fontSize: '14px',
            marginTop: '12px'
          }}
        >
          {label}
          &ensp;
          {required && ('*')}
        </Typography>
      </Stack>
      {children}
    </Stack>
  )
}
