import CloseIcon from '@mui/icons-material/Close'
import TextsmsIcon from '@mui/icons-material/Textsms'
import {
  Box,
  Dialog, DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  TextField,
  styled,
  Stack,
  Typography
} from '@mui/material'
import { serverTimestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { ParticipantDoc } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'

const StyledOpenIcon = styled(TextsmsIcon)(({ theme }) => ({
  color: theme.palette.base.main,
  width: '20px'
}))

type Props = {
  user: ParticipantDoc | null;
  mode: 'local' | 'remote';
  width: number;
}

export function EditUserStatusDialog({ user, mode, width }: Props) {
  const userName = mode === 'local' ? 'あなた' : user?.user?.display_name || 'anonymous'
  const userStatusDB = user?.user_status || ''
  const isLocal = mode === 'local'

  const [dialogOpen, setDialogOpen] = useState(false)
  const [userStatus, setUserStatus] = useState(userStatusDB)

  const { updateParticipant } = useParticipateEventContext()

  const updateStatus = async (nextStatus: string) => {
    if (user) {
      await updateParticipant({
        data: {
          user_status: nextStatus,
          updated_at: serverTimestamp()
        }
      })
    }
  }

  const handleClickOpen = () => {
    if (isLocal) {
      setDialogOpen(true)
    }
  }
  const handleClickClose = () => {
    setDialogOpen(false)
  }

  const handleSubmit = async () => {
    await updateStatus(userStatus)
    setDialogOpen(false)
  }

  const handleChangeText = (e: any) => {
    setUserStatus(e.target.value)
  }

  useEffect(() => {
    setUserStatus(userStatusDB)
  }, [userStatusDB, dialogOpen])

  return (
    <Box>
      <Stack direction="row" spacing="8px">
        <Typography sx={(theme) => ({
          color: theme.palette.base.light,
          maxWidth: `${width - 120}px`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        })}
        >
          {userName}
        </Typography>
        <Stack
          direction="row"
          spacing="4px"
          sx={{
            opacity: '0.8',
            color: 'base.main',
            ...(isLocal ? { '&:hover': { cursor: 'pointer', opacity: '1' } } : {})
          }}
          onClick={handleClickOpen}
        >
          {isLocal ? (
            <StyledOpenIcon />
          ) : null}
          {userStatusDB !== '' && '「'}
          <Typography sx={{
            color: 'base.main',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: `${width - 120}px`,
            overflow: 'hidden'
          }}
          >
            {userStatusDB === '' ? '' : `${userStatusDB}`}
          </Typography>
          {userStatusDB !== '' && '」'}
        </Stack>
      </Stack>

      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        sx={{
          '& .MuiDialog-paperScrollPaper': {
            overflowY: 'visible',
            maxWidth: 'none'
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 24px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          })}
        >
          ステータス編集
          <MuiButton disableRipple sx={{ color: 'text.primary' }} onClick={handleClickClose}>
            <CloseIcon
              sx={{
                width: '32px',
                height: '32px'
              }}
            />
          </MuiButton>
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'visible', padding: '20px 72px', marginTop: '24px' }}>
          <TextField
            value={userStatus}
            onChange={handleChangeText}
            maxRows="1"
            sx={{ '& .MuiInputBase-input': { padding: '8px' } }}
          />
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.14)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '12px 20px'
          })}
        >
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isRounded
            isShadow
            sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
            onClick={handleSubmit}
          >
            更新
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}
