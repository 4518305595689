import { useEffect, useState } from 'react'

type Props = {
  variant: 'mobile' | 'tablet'
}

const borderSizes = {
  mobile: { minWidth: 768 },
  tablet: { minWidth: 1024 }
}

const useMediaQuery = ({ variant }: Props) => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: false
  })

  const { minWidth } = borderSizes[variant]

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth
      const isDesiredWidth = currentWindowWidth < minWidth
      setState({ windowWidth: currentWindowWidth, isDesiredWidth })
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => window.removeEventListener('resize', resizeHandler)
  }, [state.windowWidth])

  return state.isDesiredWidth
}

export default useMediaQuery
