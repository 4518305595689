import { styled, Box, Stack, Typography, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import { CSSProperties, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'

import StopShareScreenImagePath from '@/assets/camp_rest.png'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { useSettingContext } from '@/contexts/SettingContext'
import { useStreamContext } from '@/contexts/StreamContext'
import { EventReactionDoc, ParticipantDoc } from '@/types/common'
import { EditUserStatusDialog } from '@c/uiParts/Events/EventView/EditUserStatusDialog'
import { EmojiReaction } from '@c/uiParts/Events/EventView/ShareScreen/EmojiReaction'

type Props = {
  stream: MediaStream | null
  width: number
  height: number
  mode?: 'local' | 'remote'
  onClickScreen: () => void
  hoverIcon: ReactNode
}
const styles: Record<string, CSSProperties> = {
  show: { position: 'relative', opacity: 1 },
  hide: { position: 'absolute', opacity: 0, left: '-9999px' }
}

const StyledHoverScreen = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.base.light,
  // backgroundColor: theme.palette.text.primary,
  opacity: '0',
  zIndex: '10',
  transition: 'all 0.1s ease-out',
  '&:hover': {
    opacity: '1.0'
  }
}))

const StyledIconBox = styled(Box)(() => ({
  fontSize: '2.5rem',
  cursor: 'pointer',
  '&:hover': {
    '& svg': { fontSize: '4rem' }
  },
  '& svg': {
    transition: 'all 0.1s ease-out 0.1s',
    fontSize: '2.5rem'
  }
}))

export function ShareScreen({
  stream,
  mode = 'local',
  width,
  height,
  onClickScreen,
  hoverIcon
} : Props) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { shareScreen } = useSettingContext()
  const { peer } = useStreamContext()
  const { reactions, participants } = useParticipateEventContext()

  const [user, setUser] = useState<ParticipantDoc | null>(null)
  const [toMeReactions, setToMeReactions] = useState<EventReactionDoc[]>([])

  const getUserByPeerId = (peerId: string) => {
    const matchedParticipant = participants.find((participant) => participant.peer_id === peerId)
    return matchedParticipant || null
  }
  const getUserByUserId = (userId: string) => {
    const matchedParticipant = participants.find((participant) => participant.user_id === userId)
    return matchedParticipant || null
  }

  useEffect(() => {
    (videoRef as MutableRefObject<HTMLVideoElement>).current.srcObject = stream
  }, [stream])

  useEffect(() => {
    const peerId = mode === 'local' ? peer?.current?.id : (stream as any).peerId
    if (stream && peerId) {
      const gotUser = getUserByPeerId(peerId)
      setUser(gotUser)
    }
  }, [stream, participants, mode])

  // userとreactionsを元に自分に向けたリアクションを取得する
  useEffect(() => {
    if (user) {
      const result = reactions.filter((reaction) => reaction.to_user_id === user.user_id)
      setToMeReactions(result)
    }
  }, [reactions, user])

  return (
    <Stack sx={{ position: 'relative', border: '1px solid green' }}>
      <EditUserStatusDialog
        width={width}
        user={user}
        mode={mode}
      />
      <Box sx={{ position: 'relative' }}>
        <StyledHoverScreen sx={{ width: `${width}px`, height: `${height}px` }}>
          {/* 隠し */}
          <Box sx={(theme) => ({
            position: 'absolute',
            backgroundColor: theme.palette.text.primary,
            opacity: '0.6',
            width: `${width}px`,
            height: `${height}px`
          })}
          />
          {/* スクリーンをホバーしたときに使えるアクション [拡大縮小, 絵文字リアクション] */}
          <Stack direction="row" spacing="12px" sx={{ alignItems: 'center', zIndex: 10 }}>
            <StyledIconBox onClick={onClickScreen}>{hoverIcon}</StyledIconBox>
            {mode !== 'local' && (
            <StyledIconBox>
              <EmojiReaction
                peerId={(stream as any).peerId}
              />
            </StyledIconBox>
            )}
          </Stack>
        </StyledHoverScreen>
        <Stack>
          <video
            width={`${width}px`}
            height={`${height}px`}
            autoPlay
            playsInline
            style={{
              width: '100%',
              ...(mode === 'remote' || shareScreen ? styles.show : styles.hide)
            }}
            ref={videoRef}
          />
          <img
            alt=""
            src={StopShareScreenImagePath}
            width={`${width}px`}
            height={`${height}px`}
            style={{
              width: '100%',
              ...(mode === 'remote' || shareScreen ? styles.hide : styles.show)
            }}
          />
        </Stack>
      </Box>
      <Stack>
        <Box sx={(theme) => ({
          position: 'absolute',
          bottom: '0px',
          width: '100%',
          backgroundColor: theme.palette.text.primary,
          opacity: '0.3',
          height: '1.5rem'
        })}
        />
        <Box sx={() => ({
          position: 'absolute',
          bottom: '0px',
          p: `0 8px 0 ${toMeReactions.length > 4 ? 44 : 8}px`,
          width: 'calc(100% - 8px)',
          maxWidth: '120px',
          zIndex: '10',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          transition: 'all 0.5s ease-out',
          '&:before': {
            color: 'white',
            fontWeight: 'bold',
            content: `'${toMeReactions.length > 4 ? `${toMeReactions.length}件` : ''}'`,
            position: 'absolute',
            left: '8px',
            bottom: '0'
          },
          '&:hover': {
            maxWidth: '100%',
            pl: '8px',
            '&:before': {
              content: '""'
            }
          }
        })}
        >
          <Stack
            direction="row"
            sx={(theme) => ({
              color: theme.palette.base.light,
              p: '0 8px',
              alignItems: 'flex-end'
            })}
          >
            {toMeReactions
              .filter((reaction) => reaction.created_at)
              .sort((a, b) => (b.created_at as any).toDate() - (a.created_at as any).toDate())
              .map((reaction, index) => (
                <Tooltip
                  title={`${
                    getUserByUserId(reaction.from_user_id)?.user?.display_name || '???'
                  } (🕑${format((reaction.created_at as any).toDate(), 'HH:mm:ss')})`}
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <Typography sx={{
                    '&:hover': {
                      fontSize: '28px',
                      transition: 'all 0.2s ease-out 0.2s',
                      cursor: 'default'
                    } }}
                  >
                    {reaction.emoji}
                  </Typography>
                </Tooltip>
              ))}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}
