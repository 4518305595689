/* eslint-disable no-param-reassign */
import { Stack } from '@mui/material'
import { format } from 'date-fns'
import {
  createContext,
  MutableRefObject, ReactNode,
  useContext,
  useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Peer, { MeshRoom } from 'skyway-js'

import { ProcessStream } from '@/components/uiParts/Events/EventView/ProcessStream'
import { useSettingContext } from '@/contexts/SettingContext'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { sha256 } from '@/utils/generateHash'

type ContextTypes = {
  local: MediaStream | null
  canvasLocal: MediaStream | null
  remoteStreams: MediaStream[]
  updateLocal: ((stream: MediaStream | null) => void) | null
  updateCanvasLocal: ((stream: MediaStream | null) => void) | null
  peer: MutableRefObject<Peer | null> | null
  updatePeer?: (peer: Peer) => void

  onStart?: ({ roomId }: {roomId: string}) => void
  onEnd?: () => void
}

const INITIAL_VALUES = {
  local: null,
  canvasLocal: null,
  remoteStreams: [],
  updateLocal: null,
  updateCanvasLocal: null,
  peer: null
}

const StreamContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useStreamContext() {
  return useContext(StreamContext)
}

// [Todo] 現状はlawしてないか使っ
// const constraints = {
//   high: {
//     width: { min: 640, ideal: 1280 },
//     heiht: { min: 480, ideal: 960 }
//   },
//   mid: {
//     width: { min: 640, ideal: 960 },
//     heiht: { min: 480, ideal: 720 }
//   },
//   law: {
//     width: { min: 320, ideal: 480 },
//     heiht: { min: 240, ideal: 360 }
//   }
// }

type Props = {
  children: ReactNode
}

export function StreamProvider({ children }: Props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { snackbar } = useSnackbarContext()
  const { shareScreen, updateSettings } = useSettingContext()

  const [local, setLocal] = useState<ContextTypes['local']>(null)
  const [canvasLocal, setCanvasLocal] = useState<ContextTypes['canvasLocal']>(null)
  const [remoteStreams, setRemoteStreams] = useState<MediaStream[]>([])
  // peerは参照なのでstateに入れるべきではない
  // const [peer, setPeer] = useState<Peer | null>(null)
  const peer = useRef<Peer | null>(null)
  const roomRef = useRef<MeshRoom>(null!)

  const onFailedToJoinEvent:
  (message?: string) => void = (message = 'イベントに参加出来ませんでした。') => {
    snackbar({ message, severity: 'error' })
    navigate(`${location.pathname}/preview`)
  }

  useEffect(() => {
    if (local) {
      // chromeの「共有停止」ボタンを押したときの処理
      local.getVideoTracks()[0].onended = () => {
        if (updateSettings)updateSettings({ is_share_screen: false })
        snackbar({ message: '画面共有をOFFにしました。' })
      }

      // contentHintの設定
      local.getVideoTracks()[0].contentHint = 'text'
    }
  }, [local])

  const updateCanvasLocal = async (stream: MediaStream | null) => {
    const switchStream = () => {
      if (!roomRef.current) return
      if (stream) {
        roomRef.current.replaceStream(stream)
      }
    }
    setCanvasLocal(stream)
    switchStream()
  }

  const stopStream = () => {
    if (canvasLocal) {
      canvasLocal.getTracks().forEach((track) => {
        track.stop()
      })
    }
    if (local) {
      local.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }

  const updateLocal = (stream: MediaStream | null) => {
    setLocal(stream)
    stopStream()
  }

  const updatePeer = (newPeer: Peer) => {
    peer.current = newPeer
  }

  const onStart = async ({ roomId }: {roomId: string}) => {
    if (!canvasLocal) onFailedToJoinEvent()
    if (!canvasLocal) return
    if (!(peer.current?.open)) return

    // イベント参加
    const hashedRoomId = await sha256(roomId)
    const meshRoom = peer.current.joinRoom(hashedRoomId, {
      mode: 'mesh',
      stream: canvasLocal,
      videoBandwidth: 200
    })

    const updateRemoteStreams = () => {
      const streams = Object.values(meshRoom.connections).map((streamObj) => (streamObj[0] as any).remoteStream)
      setRemoteStreams(streams.filter((el) => el))
    }

    // meshRoom.once('log', (logs: any) => {
    //   logs.forEach((logStr: any) => {
    //     console.log('🦊log...', JSON.parse(logStr))
    //   })
    // })
    meshRoom.once('open', () => {
      console.log(`📢 You joined\n${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
    })
    meshRoom.once('close', () => {
      console.log(`📢 You away\n${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
    })
    meshRoom.on('error', (err) => {
      console.log(`📢 error\n${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`, err)
    })
    meshRoom.on('peerJoin', (peerId) => {
      console.log(`📢 ${peerId} joined\n${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
      // updateRemoteStreams()
    })
    meshRoom.on('stream', async () => {
      console.log(`🐈get other member's stream${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
      updateRemoteStreams()
    })
    meshRoom.on('peerLeave', (peerId) => {
      console.log(`📢 ${peerId} left\n${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
      updateRemoteStreams()
    })
    roomRef.current = meshRoom
  }

  // 部屋をクローズして、共有された画面を消していく
  const onEnd = () => {
    if (peer.current) {
      peer.current.disconnect()
      peer.current.destroy()
      peer.current = null
    }
    if (updateLocal) updateLocal(null)
    stopStream()
    setRemoteStreams([])
  }

  const value = useMemo(() => ({
    local,
    canvasLocal,
    remoteStreams,
    updateLocal,
    updateCanvasLocal,
    peer,
    updatePeer,

    // skyway method
    onStart,
    onEnd
  }), [
    local, canvasLocal, peer, remoteStreams, location,
    roomRef, shareScreen
  ])

  useEffect(() => () => {
    onEnd()
  }, [])

  // previewとview画面以外では、画面共有を継続させない
  useEffect(() => {
    const isPreviewPage = (/\/events\/[^/]{15,}\/preview$/.test(location.pathname))
    const isViewPage = (/\/events\/[^/]{15,}$/.test(location.pathname))

    if (!(isPreviewPage || isViewPage)) {
      if (updateLocal)updateLocal(null)
    }
  }, [location])

  useEffect(() => {
    if (!shareScreen) {
      stopStream()
    }
  }, [shareScreen])

  return (
    <StreamContext.Provider value={value}>
      <Stack>
        <ProcessStream key="processscreen" />
        {children}
      </Stack>
    </StreamContext.Provider>
  )
}
