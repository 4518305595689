// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined'
// import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined'
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined'
import StopScreenShareOutlinedIcon from '@mui/icons-material/StopScreenShareOutlined'
import { Stack } from '@mui/material'
import { CSSProperties, MutableRefObject, useEffect, useRef } from 'react'

import StopShareScreenImagePath from '@/assets/camp_rest.png'
import { useSettingContext } from '@/contexts/SettingContext'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { useStreamContext } from '@/contexts/StreamContext'
import { useUseragent } from '@/hooks/common/useUseragent'
import { UserDoc } from '@/types/common'
import { QualitySelect } from '@c/uiParts/common/QualitySelect'
import { ScreenCheckbox } from '@c/uiParts/common/ScreenCheckbox'
import { StyledVideoActionContainer, StyledVideoContainer } from '@c/uiParts/Preview/Video/styles'

type Props = {stream: any}

const styles: Record<string, CSSProperties> = {
  show: { position: 'relative', opacity: 1 },
  hide: { position: 'absolute', opacity: 0 }
}

export function Video({ stream }: Props) {
  const { snackbar } = useSnackbarContext()
  const videoRef = useRef<HTMLVideoElement>(null)

  const { browserType } = useUseragent()
  const { updateLocal } = useStreamContext()

  const {
    voiceOn,
    shareScreen,
    quality,
    updateSettings
  } = useSettingContext()
  const handleUpdate = ({ is_voice_on, is_share_screen, screen_quality } : Partial<UserDoc>) => {
    if (browserType === 'safari' && is_share_screen) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: false })
        .then((streamObj) => {
          if (updateLocal) updateLocal(streamObj)
        })
        .catch(() => {
          if (updateSettings)updateSettings({ is_share_screen: false })
          snackbar({ message: '画面共有をOFFにしました。' })
        })
    }
    if (updateSettings) {
      updateSettings({
        is_voice_on: is_voice_on === undefined ? voiceOn : is_voice_on,
        is_share_screen: is_share_screen === undefined ? shareScreen : is_share_screen,
        screen_quality: screen_quality === undefined ? quality : screen_quality
      })
    }
  }

  useEffect(() => {
    (videoRef as MutableRefObject<HTMLVideoElement>).current.srcObject = stream
  }, [stream])

  return (
    <StyledVideoContainer>
      <>
        {/* 画面共有OFF時にはvideoではなく画像を表示 */}
        <video
          width="400px"
          autoPlay
          playsInline
          ref={videoRef}
          style={{
            width: '100%',
            maxWidth: '800px',
            ...(shareScreen ? styles.show : styles.hide)
          }}
        />
        <img
          alt=""
          src={StopShareScreenImagePath}
          width="400px"
          style={{
            width: '100%',
            maxWidth: '800px',
            ...(shareScreen ? styles.hide : styles.show)
          }}
        />
      </>
      <StyledVideoActionContainer
        direction="row"
        spacing="8px"
      >
        <Stack
          direction="row"
          spacing="24px"
          sx={{
            justifyContent: 'center',
            flexGrow: '1'
          }}
        >
          {/* <ScreenCheckbox
            value={voiceOn}
            onClick={() => handleUpdate({ is_voice_on: !voiceOn })}
            onIcon={<MicNoneOutlinedIcon />}
            offIcon={<MicOffOutlinedIcon />}
          /> */}
          <ScreenCheckbox
            value={shareScreen}
            onClick={() => handleUpdate({ is_share_screen: !shareScreen })}
            onIcon={<ScreenShareOutlinedIcon />}
            offIcon={<StopScreenShareOutlinedIcon />}
          />
        </Stack>
        <QualitySelect
          value={quality}
          onChange={(nextQuality) => {
            handleUpdate({ screen_quality: nextQuality })
          }}
        />
      </StyledVideoActionContainer>
    </StyledVideoContainer>
  )
}
