import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from '@/App'
import reportWebVitals from '@/reportWebVitals'

if (['staging', 'production'].includes(process.env.REACT_APP_ENV || '')) {
  Sentry.init({
    dsn: 'https://f9de17cb1a5b4fe3a1ccaafc33cd3ac2@o1319656.ingest.sentry.io/4504627473809408',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
