import { Outlet } from 'react-router-dom'

import { UsersDBProvider } from '#/src/contexts/UserDBContext'
import { AuthProvider } from '@/contexts/AuthContext'
import { ParticipateEventProvider } from '@/contexts/ParticipateEvent'
import { ScreenModeProvider } from '@/contexts/ScreenModeContext'
import { SettingProvider } from '@/contexts/SettingContext'
import { StreamProvider } from '@/contexts/StreamContext'
import { GoogleAnalyticsTrack } from '@c/templates/GoogleAnalyticsTrack'
import { UserAgentCheck } from '@c/templates/UserAgentCheck'

export function WholeGuard() {
  return (
    <AuthProvider>
      <SettingProvider>
        <StreamProvider>
          <UsersDBProvider>
            <ParticipateEventProvider>
              <ScreenModeProvider>
                <UserAgentCheck>
                  <GoogleAnalyticsTrack>
                    <Outlet />
                  </GoogleAnalyticsTrack>
                </UserAgentCheck>
              </ScreenModeProvider>
            </ParticipateEventProvider>
          </UsersDBProvider>
        </StreamProvider>
      </SettingProvider>
    </AuthProvider>
  )
}
