import {
  styled,
  Typography, Box, Stack,
  Drawer,
  Badge
} from '@mui/material'
import { addDays, parse } from 'date-fns'
import { ReactNode, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@/constants/styles'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { AnnounceItem } from '@c/uiParts/Events/EventView/HamburgerMenu/AnnounceItem'
import { HamburgerIcon } from '@c/uiParts/Events/EventView/HamburgerMenu/HamburgerIcon'
import { MessageBox } from '@c/uiParts/Events/EventView/HamburgerMenu/MessageBox'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '320px',
    width: '320px',
    backgroundColor: theme.palette.text.primary,
    marginTop: `calc(${HEADER_HEIGHT} + 4px)`,
    height: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - 8px)`,
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: '4px',
    overflow: 'visible'
  }
}))
const StyledDrawerHeader = styled(Stack)(({ theme }) => ({
  color: theme.palette.base.main,
  fontSize: '16px',
  fontWeight: '700 !important',
  padding: '8px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
  justifyContent: 'space-between',
  alignItems: 'center'
}))
const StyledDrawerBody = styled(Stack)(() => ({
  padding: '8px 12px'
}))

function Hamburger({ onClick, open, count = 0 }:
  {onClick: () => void, open: boolean, count?: number}) {
  return (
    <Box sx={{
      position: 'absolute',
      right: '16px',
      top: open ? '6px' : `calc(12px + ${HEADER_HEIGHT})`
    }}
    >
      <Badge badgeContent={count} color="primary">
        <HamburgerIcon onClick={onClick} />
      </Badge>
    </Box>
  )
}

type Props = {
  children: ReactNode
}

export function HamburgerMenu({ children }: Props) {
  const [cookies, setCookies] = useCookies()
  const [open, setOpen] = useState(true)
  const [badgeCount, setBadgeCount] = useState(0)
  const { participateEvent: event, isOrganizer } = useParticipateEventContext()

  useEffect(() => {
    const seenAnnounceIds = cookies.seen_announce_ids || []
    setBadgeCount((
      event?.announces || []
    ).filter(
      ({ id }) => !(seenAnnounceIds.includes(id))
    ).length)
  }, [cookies, event])

  useEffect(() => {
    if (open) {
      const cookieDate = addDays(new Date(), 1)
      const newIds = Array.from(new Set([
        ...(cookies.seen_announce_ids || []),
        ...((event?.announces || []).map(({ id }) => id))
      ]))
      setCookies(
        'seen_announce_ids',
        newIds,
        { expires: cookieDate, path: '/' }
      )
    }
  }, [open])

  const closeDrawer = () => {
    setOpen(false)
  }
  const toggleDrawer = () => {
    setOpen(!open)
  }
  return (
    <Box>
      <Box sx={{ marginRight: open ? '320px' : '0', transition: 'all 0.1s' }}>
        {children}
      </Box>
      <Hamburger onClick={toggleDrawer} open={open} count={badgeCount} />
      <StyledDrawer
        variant="persistent"
        anchor="right"
        open={open}
        onClose={closeDrawer}
      >
        <StyledDrawerHeader direction="row">
          <Typography>主催アナウンス📢</Typography>
          <Hamburger onClick={toggleDrawer} open={open} />
        </StyledDrawerHeader>
        <StyledDrawerBody spacing="8px" sx={{ height: '100%', justifyContent: 'space-between' }}>
          <Stack spacing="8px" sx={{ maxHeight: '50vh', overflowY: 'auto', paddingRight: '4px' }}>
            {event && event.announces && event.announces.map(({ id, body, created_at }) => (
              <AnnounceItem
                key={id}
                announceId={id}
                message={body}
                postedTime={parse(created_at, 'yyyy-MM-dd HH:mm:ss', new Date())}
              />
            ))}
            {event && event.announces && event.announces.length === 0 && (
              <Typography sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: '12px',
                textAlign: 'center'
              })}
              >
                現在アナウンスはありません
              </Typography>
            )}
          </Stack>
          {isOrganizer && (
            <MessageBox />
          )}
        </StyledDrawerBody>
      </StyledDrawer>
    </Box>
  )
}
