import { Box } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

import { useAuthContext } from '@/contexts/AuthContext'

type Props = {
  children: ReactNode
}

export function GoogleAnalyticsTrack({ children }: Props) {
  const { user } = useAuthContext()
  const isUseAnalytics = ['production', 'staging'].includes(
    process.env.REACT_APP_ENV || '---'
  )

  const location = useLocation()
  useEffect(() => {
    if (user && isUseAnalytics) {
      ReactGA.initialize(
        process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
        {
          gaOptions: {
            name: user.displayName,
            userId: user.uid
          }
        }
      )
      ReactGA.gtag(
        'config',
        process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
        { page_path: location.pathname + location.search }
      )
      ReactGA.gtag('event', 'custom_agent_event', { custom_agent: window.navigator.userAgent })
    }
  }, [user])
  useEffect(() => {
    if (isUseAnalytics) {
      ReactGA.gtag('js', new Date())
      ReactGA.gtag(
        'event',
        'page_view',
        {
          page_location: `https://mokupa.com${location.pathname}`,
          send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || ''
        }
      )
    }
    // ReactGA.send({
    //   hitType: 'pageview',
    //   page: location.pathname + location.search
    // })
  }, [location])

  return (
    <Box>
      {children}
    </Box>
  )
}
