import { User } from 'firebase/auth'
import { serverTimestamp } from 'firebase/firestore'
import { UseFormReturn } from 'react-hook-form'

import { createEvent } from '@/hooks/firestore/useEvents/createEvent'
import { EventDoc, EventForm } from '@/types/common'
import { dateToFirestoredate } from '@/utils/date/dateToFirestoredate'

export const submitCreateEventForm = (user: User | null) => async (
  form: UseFormReturn<EventForm, any>,
  handleComplete: (id: string) => void,
  handleError: () => void
) => {
  form
    .handleSubmit(
      async ({
        event_capacity,
        event_start_time,
        event_end_time,
        event_name,
        event_public,
        forbid_voice,
        hashtags
      }) => {
        const data: EventDoc = {
          name: event_name,
          max_user_number: Number(event_capacity),

          start_time: dateToFirestoredate(event_start_time),
          end_time: dateToFirestoredate(event_end_time),
          started_at: null,
          finished_at: null,

          is_public_event: event_public,
          is_voice_on_prohibited: forbid_voice,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          organizer_ids: [user ? user.uid : '0'],
          hashtag_ids: hashtags,

          participant_id_strings: [],
          reserver_id_strings: [],
          announces: []
        }

        try {
          const event = await createEvent(data)
          handleComplete(event.id)
        } catch (err) {
          handleError()
        }
      }
    )()
}
