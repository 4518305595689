import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { serverTimestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useSnackbarContext } from '@/contexts/Snackbar'
import { useHashtags } from '@/hooks/firestore/useHashtags'
import { HashtagDoc } from '@/types/common'
import { FormText } from '@c/uiParts/common/FormText'

const StyledCreateHashtagButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  padding: '4px',
  height: '40px',
  justifyContent: 'flex-start',
  borderRadius: '2px',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.base.main,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  opacity: '0.8',
  '&.Mui-focusVisible': { opacity: '1' },
  textTransform: 'none'
}))

export function SelectHashTag() {
  const { watch, setValue } = useFormContext()
  const { snackbar } = useSnackbarContext()
  const [isFocus, setIsFocus] = useState(false)
  // isFocusをそのまま使うと、選択肢を押せないため時差を設ける
  const [isAsyncFocus, setIsAsyncFocus] = useState(false)
  const hashtagText = watch('hashtagtext')
  const hashtagIds = watch('hashtags')

  const [hashtags, setHashtags] = useState<Required<HashtagDoc>[]>([])
  const [filteredHashtags, setFilteredHashtags] = useState<Required<HashtagDoc>[]>([])
  const { createHashtag, listHashtags } = useHashtags()

  const fetchHashtags = async () => {
    const res = await listHashtags()
    setHashtags(res)
  }
  const selectHashtag = (id: string) => {
    setValue('hashtags', [...hashtagIds, id])
    setValue('hashtagtext', '')
  }
  const handleClickCreateTag = async (tagLabel:string) => {
    try {
      const hashtagDoc = await createHashtag({
        name: tagLabel,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp()
      })
      selectHashtag(hashtagDoc.id)
      snackbar({ message: 'ハッシュタグを作成しました。', severity: 'success' })
    } catch (err) {
      snackbar({ message: 'ハッシュタグの作成に失敗しました。', severity: 'error' })
      console.error(err)
    } finally {
      fetchHashtags()
    }
  }
  const handleDeleteTag = (tagId: string) => {
    setValue('hashtags', hashtagIds.filter((id: string) => id !== tagId))
  }

  useEffect(() => {
    fetchHashtags()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsAsyncFocus(isFocus)
    }, 300)
  }, [isFocus])

  useEffect(() => {
    // [Todo] 漢字ひらがなローマ字などの違いを検知
    setFilteredHashtags(hashtags.filter(({ name, id }) =>
      name.includes(hashtagText)
      && !hashtagIds.includes(id)))
  }, [hashtags, hashtagText])

  return (
    <Stack spacing="10px" sx={{ flexGrow: '1' }}>
      <Stack
        direction="row"
        sx={{
          flexWrap: 'wrap',
          gap: '4px'
        }}
      >
        {hashtags
          .filter((tag) => hashtagIds.includes(tag.id))
          .map(({ id, name }) => (
            <Stack
              direction="row"
              spacing="8px"
              key={id}
              sx={(theme) => ({
                backgroundColor: theme.palette.base.dark,
                borderRadius: '24px',
                padding: '4px 8px',
                boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
                alignItems: 'center'
              })}
            >
              <Typography sx={{
                fontSize: '12px',
                fontWeight: 'medium'
              }}
              >
                #
                {name}
              </Typography>
              <CloseIcon
                onClick={() => handleDeleteTag(id)}
                sx={{ width: '20px',
                  height: '20px',
                  padding: '4px',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  opacity: '0.3',
                  '&:hover': { opacity: '0.8' }
                }}
              />
            </Stack>
          ))}
      </Stack>
      <StyledCreateHashtagButton
        disableRipple
        disabled={Boolean(!hashtagText)}
        onClick={() => handleClickCreateTag(hashtagText)}
      >
        <Typography sx={{ fontSize: '14px' }}>+新規作成</Typography>
        <Typography sx={(theme) => ({
          color: theme.palette.border.main,
          fontSize: '14px'
        })}
        >
          #
          {hashtagText}
        </Typography>
      </StyledCreateHashtagButton>
      <Box sx={{ position: 'relative' }}>
        <FormText
          name="hashtagtext"
          rules={{ }}
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
        />
        {filteredHashtags.length > 0 && hashtagText && isAsyncFocus && (
          <Stack
            sx={(theme) => ({ position: 'absolute',
              top: '40px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
              backgroundColor: theme.palette.base.light,
              borderRadius: '4px',
              padding: '8px 12px',
              width: '290px',
              zIndex: '99'
            })}
          >
            {filteredHashtags.map(({ id, name }) => (
              <Typography
                sx={(theme) => ({
                  fontSize: '14px',
                  lineHeight: '20px',
                  padding: '12px 12px',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: theme.palette.base.main }
                })}
                onClick={() => selectHashtag(id)}
                key={id}
              >
                {name}
              </Typography>
            ))}
          </Stack>
        )}
      </Box>
    </Stack>
  )
}
