import {
  styled, Box, Stack, FormControl, TextField, TextFieldProps
} from '@mui/material'
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form'

const StyledErrorText = styled(Box)({
  marginTop: '4px',
  fontSize: '10px',
  color: 'red'
})

type Props = TextFieldProps & {
  name: string
  rules: RegisterOptions
  handleChange?: (value: any) => void
  handleBlur?: (event: FocusEvent) => void
  disabled?: boolean
  variant?: 'filled' | 'standard' | 'outlined' | undefined
}
export function FormText({
  name, rules, handleChange, variant, handleBlur, disabled, ...rest
}: Props) {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <FormControl error={Boolean(errors[name])} sx={{ width: '100%' }}>
      <Stack>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <TextField
              fullWidth
              size="small"
              variant={variant}
              ref={field.ref}
              disabled={disabled}
              error={Boolean(errors[name])}
              value={field.value == null ? '' : field.value}
              onChange={(event) => {
                field.onChange(event)
                if (handleChange) handleChange(event)
              }}
              {...rest}
            />
          )}
        />
        {Boolean(errors[name]) && <StyledErrorText>{(errors[name]?.message as string) || ''}</StyledErrorText>}
      </Stack>
    </FormControl>
  )
}
