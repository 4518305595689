// // 情報系メッセージ
// export const I0001 = (val0: string) => `以下の内容での${val0}の見積依頼がありました。`
// export const I0002 = () => '以下の内容でユーザークライアントの追加がありました。'
// export const I0005 = () => '以下の内容でのチャットがありました。'
// export const I0006 = () => 'Booking依頼を受け付けました。'
// /** 登録: 正常完了通知 */
// export const I0007 = (val0: string) => `${val0}情報が登録されました。`
// /** 更新: 正常完了通知 */
// export const I0008 = (val0: string) => `${val0}情報が更新されました。`
// /** 削除: 正常完了通知 */
// export const I0009 = (val0: string) => `${val0}情報が削除されました。`
// /** 認証切れ */
// export const I0010 = () => 'セッションの有効期限が切れました。再ログインをお願いいたします。'
// /** Booking完了 */
// export const I0011 = () => `ご依頼された情報に関しては迅速に対応させていただきます。
//   ご質問等がありましたら、お気軽に営業担当者まで、ご連絡いただきますようお願いいたします。`
// /** アップロード完了 */
// export const I0012 = (val0: 'ドキュメント' | '写真') => `${val0}をアップロードしました。`

// エラー系メッセージ
export const E0001 = (val0: string) => `${val0}は必須入力項目です。`
export const E0002 = (val0: string) => `${val0}が正しい形式ではありません。`
export const E0003 = (val0: string, val1: number) => `${val0}は${val1}以下の数字を指定してください。`
export const E0004 = (val0: string, val1: string) => `${val0}は${val1}より未来の日時に設定してください。`
export const E0005 = (val0: string, val1: number) => `${val0}は${val1}以上の数字を指定してください。`
// export const E0003 = (val0: string, val1: number) => `${val0}は${val1}文字以上で入力してください。`
// export const E0004 = () => 'メールアドレスまたはパスワードが間違っています。'
