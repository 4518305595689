import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useAuthContext } from '@/contexts/AuthContext'
import { auth } from '@/firebase'

export function AuthGuard() {
  const location = useLocation()
  const navigate = useNavigate()

  const { isAuthenticated, signOut } = useAuthContext()

  useEffect(() => {
    if (!auth.currentUser?.emailVerified && signOut) {
      signOut()
    }
    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated, location.pathname])

  return <Outlet />
}
