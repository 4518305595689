import { addHours, format } from 'date-fns'

import { EventForm } from '@/types/common'

export const INITIAL_FORM_VALUES: EventForm = {
  event_capacity: '5',
  event_start_time: format(new Date(), "yyyy-MM-dd'T'HH:00"),
  event_end_time: format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:00"),
  event_name: '',
  event_public: false,
  forbid_voice: false,
  hashtags: [],
  hashtagText: '',
  event_started_at: '',
  event_finished_at: '',
  participant_ids: [],
  reserver_ids: [],
  announces: []
}
