import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  where
} from 'firebase/firestore'

import { useAuthContext } from '@/contexts/AuthContext'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { db } from '@/firebase'
import { createEvent } from '@/hooks/firestore/useEvents/createEvent'
import { INITIAL_FORM_VALUES } from '@/hooks/firestore/useEvents/INITIAL_FORM_VALUES'
import { listEvents } from '@/hooks/firestore/useEvents/listEvents'
import { normalizeEventToForm } from '@/hooks/firestore/useEvents/normalizeEventToForm'
import { useParticipateHooks } from '@/hooks/firestore/useEvents/participate'
import { submitCreateEventForm } from '@/hooks/firestore/useEvents/submitCreateEventForm'
import { submitUpdateEventForm } from '@/hooks/firestore/useEvents/submitUpdateEventForm'
import { updateEvent } from '@/hooks/firestore/useEvents/updateEvent'

export const useEvents = () => {
  const { user } = useAuthContext()
  const { snackbar } = useSnackbarContext()

  const { participate, leave } = useParticipateHooks()

  const getEvent = async (eventId: string) => {
    const eventDocRef = doc(db, 'events', eventId)
    const resEvent = await getDoc(eventDocRef)
    return { ...resEvent.data(), id: resEvent.id }
  }

  // [Todo] snackbarをここに実装していく
  const countOwnEvents = async () => {
    const eventColRef = collection(db, 'events')
    const q = query(eventColRef, where('organizer_ids', 'array-contains-any', [user?.uid]))
    const snapshot = await getCountFromServer(q)
    return snapshot.data().count
  }
  const listOwnEvents = async () => {
    const eventColRef = collection(db, 'events')
    const q = query(eventColRef, where('organizer_ids', 'array-contains-any', [user?.uid]))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((resdoc) => resdoc.data())
  }
  const deleteEvent = async (id: string) => {
    try {
      deleteDoc(doc(db, 'events', id))
      snackbar({ message: 'イベントを削除しました。', severity: 'success' })
    } catch (err) {
      snackbar({ message: 'イベントの削除に失敗しました。', severity: 'error' })
      throw err
    }
  }

  // const useCase = ['イベントの開始', 'イベントの終了', 'イベントの予約', 'イベントの参加']
  // const startEvent = async (event_id: string) => {}
  // const finishEvent = async () => {}
  return {
    getEvent,
    listEvents: listEvents(user),
    createEvent,
    updateEvent,
    deleteEvent,

    countOwnEvents,
    listOwnEvents,

    participate,
    leave,

    INITIAL_FORM_VALUES,
    submitCreateEventForm: submitCreateEventForm(user),
    submitUpdateEventForm: submitUpdateEventForm(user),
    normalizeEventToForm
  }
}
