import { Stack, Grid, Typography } from '@mui/material'

import { BaseTemplate } from '@c/templates/BaseTemplate'
import { EmailSignup } from '@c/uiParts/Signup/EmailSignup'
import { SSOSignup } from '@c/uiParts/Signup/SSOSignup'

export function Signup() {
  return (
    <BaseTemplate>
      <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Stack sx={{ minWidth: '300px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>サインアップ</Typography>
          <Stack direction="row" spacing="16px" sx={{ width: '100%', marginTop: '32px' }}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6}>
                <SSOSignup />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <EmailSignup />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </BaseTemplate>
  )
}
