import { useState, createContext, ReactNode, useContext } from 'react'

import { MY_PEER_ID, SCREEN_MODE } from '@/constants/common'
import { ScreenModeTypes } from '@/types/common'

type ContextTypes = {
  selectedScreenId: string | typeof MY_PEER_ID
  mode: ScreenModeTypes
  updateSelectedScreenId: (id: string | typeof MY_PEER_ID) => void
  updateMode: (mode: ScreenModeTypes) => void
}

const INITIAL_VALUES: ContextTypes = {
  selectedScreenId: undefined,
  mode: SCREEN_MODE.grid,
  updateSelectedScreenId: () => undefined,
  updateMode: () => undefined
}

const ScreenModeContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useScreenModeContext() {
  return useContext(ScreenModeContext)
}

type Props = {
  children: ReactNode
}

export function ScreenModeProvider({ children }: Props) {
  const [
    selectedScreenId,
    setSelectedScreenId
  ] = useState<ContextTypes['selectedScreenId']>(INITIAL_VALUES.selectedScreenId)
  const [mode, setMode] = useState<ContextTypes['mode']>(INITIAL_VALUES.mode)

  const updateSelectedScreenId = (id: ContextTypes['selectedScreenId']) => {
    setSelectedScreenId(id)
  }
  const updateMode = (nextMode: ContextTypes['mode']) => {
    setMode(nextMode)
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    selectedScreenId,
    updateSelectedScreenId,
    mode,
    updateMode
  }

  return <ScreenModeContext.Provider value={value}>{children}</ScreenModeContext.Provider>
}
