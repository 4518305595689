import { styled, Button, ButtonProps } from '@mui/material'

import GoogleIcon from '@/assets/googleIcon.svg'

type Props = ButtonProps & {
  label?: String
}

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  padding: '8px 30px',
  fontWeight: '700',
  fontSize: '13px',
  color: theme.palette.link.main,
  textTransform: 'none',
  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 10%)',
  borderRadius: '8px',
  border: '1px solid rgba(0,0,0,0.1)',
  backgroundColor: theme.palette.base.light
}))

export function GoogleLoginButton({
  label = 'Googleにログイン',
  ...rest
}: Props) {
  return (
    <StyledButton {...rest}>
      <img src={GoogleIcon} alt="google icon" width="24px" height="24px" />
      {label}
    </StyledButton>
  )
}
