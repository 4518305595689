import { Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

// import { S3_BUCKET_URL } from '@/constants/common'
import StopShareScreenImagePath from '@/assets/camp_rest.png'
import { useSettingContext } from '@/contexts/SettingContext'
import { useStreamContext } from '@/contexts/StreamContext'
import { useAnimationFrame } from '@/hooks/common/useAnimationFrame'
import { ScreenQuality } from '@/types/common'

const WIDTH = 406
const HEIGHT = 245

export function ProcessStream() {
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)
  const [loaded, setLoaded] = useState(false)

  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef(new Image())

  const { local, updateCanvasLocal } = useStreamContext()
  const { shareScreen, quality } = useSettingContext()

  // [Todo] commonメソッドやutilに移動
  // clearity=4だとはっきり見える
  const qualitySettings = (qualityString: ScreenQuality) => {
    switch (qualityString) {
      case 'law':
        return { blur: 2, clearity: 1 }
      case 'mid':
        return { blur: 2, clearity: 3 }
      case 'high':
      default:
        return { blur: 0, clearity: 3 }
    }
  }

  // 1. videoからref、canvasからref&contextを取得
  useEffect(() => {
    if (canvasRef.current) {
      const canvasContext = canvasRef.current.getContext('2d')
      setContext(canvasContext)
    }
    imgRef.current.src = StopShareScreenImagePath
    imgRef.current.onload = () => {
      setLoaded(true)
    }
  }, [])

  // 2. video.current.srcObjectにlocalを入れる
  useEffect(() => {
    if (shareScreen && local !== null && videoRef.current !== null) {
      videoRef.current.srcObject = local
    }
    if (videoRef.current !== null && loaded) {
      const processedStream = canvasRef.current && canvasRef.current.captureStream()
      if (processedStream && updateCanvasLocal) {
        updateCanvasLocal(processedStream)
      }
    }
  }, [local, shareScreen, quality, loaded])

  // 状態にコンテキストが登録されたらそれに対して操作できる
  const updateCanvas = () => {
    if (context !== null && videoRef.current !== null && loaded) {
      if (shareScreen && local) {
        context.drawImage(
        videoRef.current as CanvasImageSource,
        // target start
        0,
        0,
        WIDTH * qualitySettings(quality).clearity,
        HEIGHT * qualitySettings(quality).clearity
        )
        context.filter = `sepia(0%) blur(${qualitySettings(quality).blur}px)`
      } else {
        context.drawImage(
          imgRef.current,
          // original start
          0,
          0,
          // original size
          420,
          285,
          // target start
          0,
          0,
          WIDTH * qualitySettings(quality).clearity,
          HEIGHT * qualitySettings(quality).clearity
        )
      }
    }
  }
  useAnimationFrame(updateCanvas)

  return (
    <Stack sx={{
      opacity: '0',
      position: 'absolute',
      left: '-9999px'
    }}
    >
      <video width={WIDTH} height={HEIGHT} autoPlay playsInline ref={videoRef} />
      <canvas
        id="processcanvas"
        width={WIDTH * qualitySettings(quality).clearity}
        height={HEIGHT * qualitySettings(quality).clearity}
        ref={canvasRef}
      />
    </Stack>
  )
}
