import { doc, getDoc, serverTimestamp } from 'firebase/firestore'
import { useState, createContext, ReactNode, useContext, useEffect } from 'react'

import { SCREEN_QUALITY_OPTIONS } from '@/constants/common'
import { useAuthContext } from '@/contexts/AuthContext'
import { db } from '@/firebase'
import { useUsers } from '@/hooks/firestore/useUsers'
import { ScreenQuality, UserDoc } from '@/types/common'

type ContextTypes = {
  voiceOn: boolean
  shareScreen: boolean
  quality: ScreenQuality
  updateSettings?: (next: Partial<UserDoc>) => Promise<void>
  fetchSettings?: () => Promise<void>
}

const INITIAL_VALUES = {
  voiceOn: false,
  shareScreen: false,
  quality: SCREEN_QUALITY_OPTIONS.LAW.value as ScreenQuality
}

const SettingContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useSettingContext() {
  return useContext(SettingContext)
}

type Props = {
  children: ReactNode
}

export function SettingProvider({ children }: Props) {
  const { user } = useAuthContext()
  const { updateUser } = useUsers()
  const [meUser, setMeUser] = useState<UserDoc | null>(null)
  const [voiceOn, setVoiceOn] = useState<ContextTypes['voiceOn']>(false)
  const [shareScreen, setShareScreen] = useState<ContextTypes['shareScreen']>(false)
  const [quality, setQuality] = useState<ContextTypes['quality']>(INITIAL_VALUES.quality)

  const fetchSettings = async () => {
    if (!user) return
    const userDocRef = doc(db, 'users', user ? user.uid : '')
    const resMe = await getDoc(userDocRef)

    if (!(resMe.data())) return

    const { is_voice_on, is_share_screen, screen_quality } = resMe.data() as UserDoc

    setVoiceOn(is_voice_on)
    setShareScreen(is_share_screen)
    setQuality(screen_quality)
    setMeUser(resMe.data() as UserDoc)
  }

  const updateSettings = async (next: Partial<UserDoc>) => {
    if (user) {
      await updateUser(user?.uid, { ...meUser, ...next, updated_at: serverTimestamp() })
      fetchSettings()
    }
  }
  useEffect(() => {
    fetchSettings()
  }, [user])

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    voiceOn,
    shareScreen,
    quality,
    updateSettings,
    fetchSettings
  }

  return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>
}
