import LogoutIcon from '@mui/icons-material/Logout'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Stack
} from '@mui/material'
import Zoom from '@mui/material/Zoom'
import { serverTimestamp } from 'firebase/firestore'
import { forwardRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useEvents } from '@/hooks/firestore/useEvents'
import { EventDoc } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'

type Props = {
  event: EventDoc | null
  userId: string
}

const Transition = forwardRef((props: any, ref) => (
  <Zoom ref={ref} {...props}>
    {props.children}
  </Zoom>
))

export function EventExitDialog({ event, userId }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const navigate = useNavigate()
  const { updateEvent } = useEvents()

  const handleExitEvent = async () => {
    // eventのfinished_atを更新する
    const eventId = event?.id
    const isOrganizer = event && event.organizer_ids.includes(userId)
    if (event && eventId) {
      if (isOrganizer) {
        const { id, ...idRemovedEvent } = event
        await updateEvent(eventId, {
          ...idRemovedEvent,
          finished_at: serverTimestamp()
        })
      }
      navigate(`/events/${eventId}/exit`)
    }
  }
  const openDialog = () => {
    setDialogOpen(true)
  }
  const handleBack = () => {
    setDialogOpen(false)
  }

  return (
    <Box>
      <Stack
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.base.light,
          width: '52px',
          height: '52px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '32px',
          cursor: 'pointer',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            opacity: '0.8'
          }
        })}
        onClick={openDialog}
      >
        <LogoutIcon />
      </Stack>

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>退室</DialogTitle>
        <DialogContent>
          主催者が退室した場合、
          <br />
          他の参加者も強制的に退室となりますのでご注意ください。
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '12px 20px'
          })}
        >
          <Stack direction="row" spacing="16px">
            <Button
              bgColor={(theme) => theme.palette.text.secondary}
              textColor={(theme) => theme.palette.base.main}
              isRounded
              isShadow
              sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
              onClick={handleBack}
            >
              戻る
            </Button>
            <Button
              bgColor={(theme) => theme.palette.primary.main}
              textColor={(theme) => theme.palette.base.main}
              isRounded
              isShadow
              sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
              onClick={handleExitEvent}
            >
              退室する
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

    </Box>
  )
}
