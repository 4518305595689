import { Box, styled, Link, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import MokupaIcon from '@/assets/mokupa.svg'
import { useAuthContext } from '@/contexts/AuthContext'
import { Button } from '@c/uiParts/common/Button'
import { AccountPopup } from '@c/uiParts/Header/AccountPopup'

const StyledContiner = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  width: '100vw',
  position: 'fixed',
  top: '0',
  padding: '10px 48px',
  filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
  zIndex: '99'
})

export function Header() {
  const navigate = useNavigate()
  const { user, isAuthenticated, signOut } = useAuthContext()
  const transitToLogin = () => {
    navigate('/login')
  }
  const transitToSignup = () => {
    navigate('/signup')
  }
  return (
    <StyledContiner>
      <Link href="/" sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <img src={MokupaIcon} alt="mokupa icon" width="100px" height="32px" />
      </Link>
      {isAuthenticated ? (
        <AccountPopup userName={user?.displayName || ''} signOut={signOut} />
      ) : (
        <Stack direction="row" spacing="16px">
          <Button
            bgColor={(theme) => theme.palette.base.main}
            textColor={(theme) => theme.palette.primary.main}
            isRounded
            sx={{ padding: '8px 32px', fontSize: '16px' }}
            onClick={transitToLogin}
          >
            ログイン&ensp;&gt;
          </Button>
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isRounded
            isShadow
            sx={{ padding: '8px 32px', fontWeight: 'bold', fontSize: '16px' }}
            onClick={transitToSignup}
          >
            登録&ensp;&gt;
          </Button>
        </Stack>
      )}
    </StyledContiner>
  )
}
