import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  updateProfile as firebaseUpdateProfile,
  User,
  signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import { useState, useEffect, ReactNode, createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { INITIAL_USER_DOC } from '@/constants/common'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { auth, db } from '@/firebase'
import { UserDoc } from '@/types/common'

type Props = {
  children: ReactNode
}
type ContextTypes = {
  user: User | null
  isAuthenticated: boolean
  loading: boolean
  signIn?: (email: string, password: string) => Promise<void>
  signUp?: (email: string, password: string, userProfile?: Partial<User>, userDoc?: Partial<UserDoc>) => Promise<void>
  signUpGoogle?: () => Promise<void>
  signOut?: () => Promise<void>
  updateProfile?: (Profile: Partial<User>) => Promise<void | null>
}

const INITIAL_VALUES = {
  user: null,
  isAuthenticated: false,
  loading: false
}

const AuthContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useAuthContext() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: Props) {
  const navigate = useNavigate()
  const { snackbar } = useSnackbarContext()
  const [user, setUser] = useState<ContextTypes['user']>(null)

  const [loading, setLoading] = useState(true)

  const updateProfile = async (profile: Partial<User>) => {
    if (!user) return null
    try {
      const res = await firebaseUpdateProfile(user, profile)
      snackbar({ message: 'プロフィールを更新しました。', severity: 'success' })
      return res
    } catch (err) {
      snackbar({ message: 'プロフィールの更新に失敗しました。', severity: 'error' })
    }
    return null
  }

  const signIn: ContextTypes['signIn'] = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (err) {
      snackbar({ message: 'ログインに失敗しました。', severity: 'error' })
    }
  }
  const signUp: ContextTypes['signUp'] = async (
    email,
    password,
    // firebaseAuth用
    userProfile = {},
    // firestoreのuserテーブル用
    userDoc = {}
  ) => {
    try {
      const { user: newUser } = await createUserWithEmailAndPassword(auth, email, password)
      await firebaseUpdateProfile(newUser, userProfile)

      const newUserRef = doc(db, 'users', newUser.uid)
      await setDoc(newUserRef, {
        ...INITIAL_USER_DOC,
        ...userDoc,
        // [Todo] Auth変更時に、functionから更新
        display_name: newUser.displayName,
        email: newUser.email,
        photo_url: newUser.photoURL
      })

      await auth.currentUser?.reload()
    } catch (err) {
      console.log('👹', err)
      snackbar({ message: '管理者に連絡してください。', severity: 'error' })
    }
  }

  const signUpGoogle: ContextTypes['signUpGoogle'] = async () => {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)

      const newUser = result.user
      const newUserRef = doc(db, 'users', newUser.uid)
      const docSnap = await getDoc(newUserRef)

      const isSignup = !docSnap.data()
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result)
      // if (!credential) throw new Error('Auth Credential is null')
      // const token = credential.accessToken
      // console.log('🐈', token)
      // IdP data available using getAdditionalUserInfo(result)
      if (isSignup) {
        await setDoc(newUserRef, {
          ...INITIAL_USER_DOC,
          ...({
            created_at: serverTimestamp(),
            updated_at: serverTimestamp()
          }),
          display_name: newUser.displayName,
          email: newUser.email,
          photo_url: newUser.photoURL
        })

        await auth.currentUser?.reload()
      }
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      const { email } = error.customData

      const credential = GoogleAuthProvider.credentialFromError(error)
      console.error(`👹[ERROR]::errorCode_${errorCode} errorMessage_${errorMessage} email_${email}`)
      console.error(`[ERROR]::${credential}`)
    }
  }

  const signOut = async () => {
    firebaseSignOut(auth)
  }

  const value = useMemo(
    () => ({
      user,
      isAuthenticated: user !== null,
      loading,
      signIn,
      signUp,
      signUpGoogle,
      signOut,
      updateProfile
    }),
    [user, loading]
  )

  useEffect(() => {
    const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged((authUser) => {
      if (auth.currentUser?.emailVerified === false) {
        navigate('/email-verify')
      }
      setUser(authUser)
      setLoading(false)
    })
    const unsubscribeOnIdTokenChanged = auth.onIdTokenChanged((authUser) => {
      // [memo] userを更新していても、userが変わらずにuseEffectが発火しないことあり
      // おそらくはglobal変数ゆえ
      setUser(authUser)
      setLoading(false)
    })

    return () => {
      unsubscribeOnAuthStateChanged()
      unsubscribeOnIdTokenChanged()
    }
  }, [])

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}

// const resetPassword = (email: string) => {
//   // .env use case      url: process.env.REACT_APP_MAIL_URL + '?email=' + email,
//   // local dev case     url: "http://localhost:3000/?email=" + email,
//   // product case     url: "https://you-domain/?email=' + email,
//   const actionCodeSettings = {
//     url: 'https://sszxu.csb.app/'
//   }
//   // return auth.sendPasswordResetEmail(email, actionCodeSettings);
//   return auth.sendPasswordResetEmail(email, actionCodeSettings)
// }

// const sendEmailVerification = () => {
//   // .env use case      url: process.env.REACT_APP_MAIL_URL + 'dashboard'
//   // local dev case     url: "http://localhost:3000/dashboard"
//   // product case     url: "https://you-domain/dashboard'

//   const actionCodeSettings = {
//     url: 'https://sszxu.csb.app/dashboard'
//   }
//   return user.sendEmailVerification(actionCodeSettings)
// }
// const updatePassword = (password: string) => user.updatePassword(password)
// const updateEmail = (email: string) => user.updateEmail(email)
// const updateProfile = (profiledata) => user.updateProfile(profiledata)
