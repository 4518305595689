import { Box, Stack, styled } from '@mui/material'

import { S3_BUCKET_URL } from '@/constants/common'

const StyledHeaderText = styled(Box)({
  fontSize: '24px',
  lineHeight: '52px',
  fontWeight: 'bold',
  textAlign: 'center'
})

const StyledBodyText = styled(Box)({
  fontSize: '16px',
  lineHeight: '32px',
  fontWeight: 'bold',
  textAlign: 'center'
})

export function SecondSectionSp() {
  return (
    <Stack direction="column" spacing="70px" sx={{ alignItems: 'center' }}>
      <Stack
        sx={{ width: '100%', maxWidth: '960px', justifyContent: 'center' }}
      >
        <img
          src={`${S3_BUCKET_URL}/service_screenShot.png`}
          width="1907"
          height="1225"
          alt="サービスイメージ"
          style={{ width: '100%', height: 'auto' }}
        />
      </Stack>
      <Stack sx={{ maxWidth: '960px' }}>
        <Stack spacing="24px">
          <StyledHeaderText># 家が集中できる場所になる</StyledHeaderText>
          <StyledBodyText>
            本当にやりたいことに上司はいないから、
            <br />
            期限も周りの目もなくて手につかない。
          </StyledBodyText>
          <StyledBodyText>
            カフェやもくもく会に行けば集中できるけど
            <br />
            お金もかかるし身支度は手間...
          </StyledBodyText>
          <StyledBodyText>
            『MokuPa』なら家からも、
            <br />
            応援しあえる仲間を身近に感じられます。
          </StyledBodyText>
        </Stack>
      </Stack>
    </Stack>
  )
}
