import { useEffect, useState } from 'react'

type UAType = 'ie' | 'edge' | 'chrome' | 'safari' | 'firefox' | 'opera' | 'other'

export const useUseragent = () => {
  const [browserType, setBrowserType] = useState<UAType | null>(null)
  const userAgent = window.navigator.userAgent.toLowerCase()

  useEffect(() => {
    if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
      setBrowserType('ie')
    } else if (userAgent.indexOf('edge') !== -1) {
      setBrowserType('edge')
    } else if (userAgent.indexOf('chrome') !== -1) {
      setBrowserType('chrome')
    } else if (userAgent.indexOf('safari') !== -1) {
      setBrowserType('safari')
    } else if (userAgent.indexOf('firefox') !== -1) {
      setBrowserType('firefox')
    } else if (userAgent.indexOf('opera') !== -1) {
      setBrowserType('opera')
    } else {
      setBrowserType('other')
    }
  }, [])

  return {
    userAgent,
    browserType
  }
}
