import { styled, Stack, Typography, Link } from '@mui/material'

import useMediaQuery from '@/hooks/common/useMediaQuery'

const StyledFooter = styled('footer')({
  position: 'fixed',
  bottom: '0'
})
const StyledContainer = styled(Stack)({
  width: '100vw',
  padding: '32px 48px',
  backgroundColor: 'white',
  filter: 'drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25))',
  zIndex: '99',
  justifyContent: 'space-between'
})

// const br = '%0d%0a'
// const space = '%20'
// const subject = '【MokuPa 問い合わせ】'
// const body = [
//   `MokuPa${space}問い合わせ窓口`,
//   `担当者${space}宛`,
//   '',
//   '【送信者情報】',
//   '氏名：',
//   '連絡先：',
//   '（送信元と同じアドレスで問題なければ、記載不要）',
//   '',
//   '【問い合わせ内容】'
// ].join(br)

// const mailTo = `mailto:${
//   process.env.REACT_APP_ADMINISTRATOR_EMAIL
// }?subject=${subject}&body=${body}`
const GoogleFormLink = 'https://forms.gle/5Sq1Fk8yGrLA24M56'

export function Footer() {
  const isMobile = useMediaQuery({ variant: 'mobile' })
  return (
    <StyledFooter>
      {isMobile ? (
        <StyledContainer spacing="16px" sx={{ padding: '16px' }}>
          <Stack spacing="8px">
            <Link
              href="/privacy"
              sx={(theme) => ({ color: theme.palette.text.secondary, fontSize: '14px', textAlign: 'center' })}
            >
              プライバシーポリシー
            </Link>
            <Link
              href={GoogleFormLink}
              sx={(theme) => ({ color: theme.palette.text.secondary, fontSize: '14px', textAlign: 'center' })}
            >
              お問い合わせ
            </Link>
          </Stack>
          <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>
            © 2022
            {' '}
            {process.env.REACT_APP_ADMINISTRATOR_NAME}
            {' '}
            All Rights Reserved
          </Typography>
        </StyledContainer>
      ) : (
        <StyledContainer
          direction="row"
        >
          <Typography>
            © 2022
            {' '}
            {process.env.REACT_APP_ADMINISTRATOR_NAME}
            {' '}
            All Rights Reserved
          </Typography>
          <Stack direction="row" spacing="32px">
            <Link
              href="/privacy"
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              プライバシーポリシー
            </Link>
            <Link
              href={GoogleFormLink}
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              お問い合わせ
            </Link>
          </Stack>
        </StyledContainer>
      )}

    </StyledFooter>
  )
}
