import {
  styled, Box, Stack, FormControl, Checkbox, CheckboxProps
} from '@mui/material'
import { ReactNode } from 'react'
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form'

const StyledErrorText = styled(Box)({
  marginTop: '4px',
  fontSize: '10px',
  color: 'red'
})

type Props = CheckboxProps & {
  name: string
  rules: RegisterOptions
  handleChange?: (value: any) => void
  labelElement: ReactNode
}
export function FormCheckbox({
  name, rules, handleChange, labelElement, ...rest
}: Props) {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <FormControl error={Boolean(errors[name])}>
      <Stack>
        <Stack direction="row" spacing="8px">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Checkbox
                {...rest}
                size="small"
                checked={field.value}
                onChange={(event) => {
                  field.onChange(event)
                  if (handleChange) handleChange(event)
                }}
                ref={field.ref}
              />
            )}
          />
          {labelElement}
        </Stack>

        {Boolean(errors[name]) && <StyledErrorText>{(errors[name]?.message as string) || ''}</StyledErrorText>}
      </Stack>
    </FormControl>
  )
}
