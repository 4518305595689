import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { MenuItem, Select, Stack } from '@mui/material'
import { useState } from 'react'

import { SCREEN_QUALITY_OPTIONS } from '@/constants/common'
import { ScreenQuality } from '@/types/common'

type Props = {
  value: ScreenQuality
  onChange: (next: ScreenQuality) => void
}

export function QualitySelect({ value, onChange }: Props) {
  const [qualitySelectOpen, setQualitySelectOpen] = useState(false)
  const handleClickQualityButton = () => {
    setQualitySelectOpen(!qualitySelectOpen)
  }

  return (
    <Stack
      onClick={handleClickQualityButton}
      sx={(theme) => ({
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width: '52px',
        height: '52px',
        backgroundColor: theme.palette.text.primary,
        borderRadius: '50%',
        border: `1px solid ${theme.palette.base.light}`,
        color: theme.palette.base.light,
        cursor: 'pointer',
        '&:hover': {
          opacity: '0.8',
          backgroundColor: 'rgba(255,255,255,0.7)'
        }
      })}
    >
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value as ScreenQuality)
        }}
        sx={{
          maxWidth: 0,
          maxHeight: 0,
          '& .MuiSvgIcon-root': { display: 'none' },
          '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
          '& .MuiSelect-select': { opacity: '0', transform: 'translateY(-180px)' }
        }}
        open={qualitySelectOpen}
      >
        {Object.values(SCREEN_QUALITY_OPTIONS).map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {/* open={qualitySelectOpen} */}
      <AutoAwesomeIcon />
    </Stack>

  )
}
