import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Box, Stack, Typography } from '@mui/material'

import { useUserByPathId } from '@/hooks/firestore/useUserByPathId'
import { BaseTemplate } from '@c/templates/BaseTemplate'

// mypage
export function Account() {
  const { user } = useUserByPathId({ pathReg: '/account/(.*)' })

  return (
    <BaseTemplate
      isFooter={false}
      isMaxWidth={false}
      sx={{
        width: '100vw',
        maxWidth: '1000px'
      }}
    >
      <Stack spacing="48px" sx={{ padding: '32px 16px' }}>
        <Typography sx={(theme) => ({
          fontSize: '24px',
          fontWeight: 'bold',
          lineHieght: '1.5',
          borderBottom: `1px solid ${theme.palette.border.main}`
        })}
        >
          {user?.display_name || ''}
          のページ
        </Typography>
        <Stack
          spacing="16px"
          sx={{
            width: '520px',
            alignItems: 'center'
          }}
        >
          <AccountCircleOutlinedIcon
            sx={{ width: '72px', height: '72px' }}
          />
          <Stack sx={{ padding: '0 40px', width: '100%' }}>
            <Stack direction="row" spacing="8px" sx={{ alignItems: 'center', padding: '8px' }}>
              <Box sx={{ width: '120px' }}>名前</Box>
              <Box sx={(theme) => ({
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: '4px',
                padding: '4px 8px',
                minHeight: '34px',
                flexGrow: '1'
              })}
              >
                {user?.display_name || ''}
              </Box>
            </Stack>
            <Stack direction="row" spacing="8px" sx={{ alignItems: 'center', padding: '8px' }}>
              <Box sx={{ width: '120px' }}>自己紹介</Box>
              <Box
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.border.main}`,
                  borderRadius: '4px',
                  padding: '4px 8px',
                  minHeight: '132px',
                  width: '296px',
                  flexGrow: '1',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap'
                })}
              >
                {`${user?.introduce || ''}`}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </BaseTemplate>
  )
}
