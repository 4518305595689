import {
  collection,
  CollectionReference,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore'

import { db } from '@/firebase'
import { UserDoc } from '@/types/common'

// @deprecated
export const useUsers = () => {
  const getUser = async (userId: string) => {
    const userRef = doc(db, 'users', userId)
    const res = await getDoc(userRef)
    return res.data() as UserDoc | undefined
  }

  // @deprecated use updateUsersDB instead And users from userDBContext
  const listUsers = async ({ userIds = [] }: {userIds: string[]}) => {
    const userColRef = collection(db, 'users') as CollectionReference<UserDoc>

    if (userIds.length === 0) {
      const querySnapshot = await getDocs(userColRef)
      return querySnapshot.docs.map((resdoc) => ({
        ...resdoc.data(),
        id: resdoc.id
      }))
    }
    const q = query(userColRef, where(documentId(), 'in', userIds))
    try {
      const querySnapshot = await getDocs(q)
      return querySnapshot.docs.map((resdoc) => ({
        ...resdoc.data(),
        id: resdoc.id
      }))
    } catch (err) {
      console.error(err)
    }
    return []
  }

  const updateUser = async (user_id: string, data: Partial<UserDoc>) => {
    const userRef = doc(db, 'users', user_id)
    // [Todo] DocumentReference<User>
    await setDoc(userRef, data)
  }

  return { getUser, listUsers, updateUser }
}
