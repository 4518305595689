import { Box, Stack } from '@mui/material'

import { S3_BUCKET_URL } from '@/constants/common'
import useMediaQuery from '@/hooks/common/useMediaQuery'

export function FiveSectionSp() {
  const isTablet = useMediaQuery({ variant: 'tablet' })
  return (
    <Stack spacing="48px" sx={{ alignItems: 'center', minHeight: '240px' }}>
      {/* リリース時期 */}
      <Stack direction="row" sx={{ justifyContent: 'space-around' }}>
        {!isTablet && (
          <Stack
            sx={{
              minWidth: '278px',
              height: '256px',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <img src={`${S3_BUCKET_URL}/sokosuto_connected.png`} width="245px" height="178px" alt="" />
          </Stack>
        )}

        <Stack spacing="40px" sx={{ maxWidth: '512px', alignItems: 'center' }}>
          <Box sx={{ fontSize: '16px', lineHeight: '26px' }}>
            この感動をいち早く体感いただきたいので
            <br />
            メールアドレスをご登録の上、️是非試してみてください！🏃
          </Box>
        </Stack>
        {!isTablet && (
          <Stack sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            <img src={`${S3_BUCKET_URL}/sokosuto_study.png`} width="278px" height="256px" alt="" />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
