import { Typography, Stack } from '@mui/material'

import { useAuthContext } from '@/contexts/AuthContext'
import { GoogleLoginButton } from '@c/uiParts/common/GoogleLoginButton'

export function SSOSignup() {
  const { signUpGoogle } = useAuthContext()
  const handleClick = async () => {
    if (signUpGoogle) {
      signUpGoogle()
    }
  }
  return (
    <Stack>
      <Typography
        sx={(theme) => ({
          fontSize: '20px',
          color: theme.palette.text.secondary
        })}
      >
        他のサービスで登録
      </Typography>

      <Stack sx={{ marginTop: '32px' }}>
        <GoogleLoginButton
          label="Google でサインアップ"
          onClick={handleClick}
        />
      </Stack>

    </Stack>
  )
}
