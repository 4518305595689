import { doc, serverTimestamp, setDoc } from 'firebase/firestore'

import { db } from '@/firebase'
import { EventDoc } from '@/types/common'

export const updateEvent = async (event_id: string, data: Partial<EventDoc>) => {
  const eventRef = doc(db, 'events', event_id)
  await setDoc(eventRef, {
    announces: [],
    ...data,
    updated_at: serverTimestamp()
  })
}
