import { collection, CollectionReference, doc,
  DocumentChange, DocumentData, onSnapshot, query, setDoc } from 'firebase/firestore'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuthContext } from '@/contexts/AuthContext'
import { useUsersDBContext } from '@/contexts/UserDBContext'
import { db } from '@/firebase'
import { EventDoc, EventReactionDoc, ParticipantDoc, UserDoc } from '@/types/common'
import { joinRecord } from '@/utils/joinRecord'
import { removeKeyFromObject } from '@/utils/removeKeyFromObject'

type ContextTypes = {
  participateEvent: EventDoc | null
  participants: ParticipantDoc[]
  updateParticipant: (data: { data: Partial<ParticipantDoc> }) => Promise<void>
  reactions: EventReactionDoc[]
  eventId: string | null
  isOrganizer: boolean
}

const INITIAL_VALUES = {
  participateEvent: null,
  participants: [],
  updateParticipant: async () => {},
  reactions: [],
  eventId: null,
  isOrganizer: false
}

const ParticipateEventContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useParticipateEventContext() {
  return useContext(ParticipateEventContext)
}

type Props = {
  children: ReactNode
}

// if not found → null
const getEventId = (pathname: string) => {
  const UrlRegExp = '^/events/([^/]{18,})(?=/preview|/exit|$)'
  const matched = pathname.match(new RegExp(UrlRegExp))
  return matched && matched[1]
}

export function ParticipateEventProvider({ children }: Props) {
  const location = useLocation()
  const { user } = useAuthContext()
  const { dbUsers, updateDBUsers } = useUsersDBContext()

  // !!eventId → isTargetUrl
  const [eventId, setEventId] = useState<string | null>(null)
  const [event, setEvent] = useState<EventDoc | null>(null)
  const [participants, setParticipants] = useState<ParticipantDoc[]>(INITIAL_VALUES.participants)
  const [reactions, setReactions] = useState<EventReactionDoc[]>(INITIAL_VALUES.reactions)
  const [isOrganizer, setIsOrganizer] = useState<boolean>(INITIAL_VALUES.isOrganizer)

  const [participantsRelation, setParticipantsRelation] = useState<ParticipantDoc[]>(INITIAL_VALUES.participants)

  // useCase: 参加時
  // useCase: 退室時にfinished_atを更新
  // 最初のpariticipateもページ移動後に実行されるので、eventIdが新しいことは信頼できる
  const updateParticipant: ContextTypes['updateParticipant'] = async ({ data }) => {
    const participantRef = doc(db, 'events', eventId || '', 'participants', user?.uid || '')
    const initialValues = { user_status: '' }
    const meParticipant = participants
      .find((participant) => participant.user_id === user?.uid) || {}
    await setDoc(participantRef, {
      ...initialValues,
      ...removeKeyFromObject(meParticipant, ['id']),
      ...data
    })
  }

  useEffect(() => {
    const resEventId = getEventId(location.pathname)
    setEventId(resEventId)
  }, [location])

  useEffect(() => {
    updateDBUsers(participants.map((participant) => participant.user_id))
  }, [participants])

  // participantsの変更を検知して、usersを取得
  // participantsからuser_idを配列で取得して、それを元にusersを取得
  useEffect(() => {
    const newParticipants = participants.map(
      (participant) => joinRecord<ParticipantDoc, UserDoc>(participant, dbUsers, 'user_id', 'id', 'user')
    )
    setParticipantsRelation(newParticipants)
  }, [participants, dbUsers])

  useEffect(() => {
    if (
      event && user
      && event.organizer_ids.includes(user.uid)
    ) {
      setIsOrganizer(true)
    } else {
      setIsOrganizer(false)
    }
  }, [event, user])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (eventId) {
      // 1. Event情報取得
      const unsubEventDoc = onSnapshot(doc(db, 'events', eventId), (document: any) => {
        setEvent({ ...document.data(), id: document.id })
      })

      // 2. Event/Participants情報取得
      const participantColRef = collection(
        db,
        'events',
        eventId,
        'participants'
      ) as CollectionReference<ParticipantDoc>
      // const participantQ = query(participantColRef, where('finished_at', '==', null))
      const participantQ = query(participantColRef)
      const unsubEventParticipantsCollection = onSnapshot(participantQ, (querySnapshot: any) => {
        querySnapshot.docChanges().forEach((change: DocumentChange<DocumentData>) => {
          switch (change.type) {
            case 'added':
            case 'modified':
              setParticipants((prev) => {
                const prevParticipants = prev.filter((participant) => participant.id !== change.doc.id)
                return [...prevParticipants, { id: change.doc.id, ...change.doc.data() as ParticipantDoc }]
              })
              break
            case 'removed':
              setParticipants((prev) => prev.filter((participant) => participant.id !== change.doc.id))
              break
            default:
          }
        })
      })

      // 3. Event/Reactions情報取得
      const reactionColRef = collection(
        db,
        'events',
        eventId,
        'reactions'
      ) as CollectionReference<EventReactionDoc>
      const reactionQ = query(reactionColRef)
      const unsubEventReactionsCollection = onSnapshot(reactionQ, (querySnapshot: any) => {
        querySnapshot.docChanges().forEach((change: DocumentChange<DocumentData>) => {
          switch (change.type) {
            case 'added':
            case 'modified':
              setReactions((prev) => {
                const prevReactions = prev.filter((reaction) => reaction.id !== change.doc.id)
                return [...prevReactions, { id: change.doc.id, ...change.doc.data() as EventReactionDoc }]
              })
              break
            case 'removed':
              setReactions((prev) => prev.filter((reaction) => reaction.id !== change.doc.id))
              break
            default:
          }
        })
      })

      // 9. unsub処理
      return () => {
        unsubEventDoc()
        unsubEventParticipantsCollection()
        unsubEventReactionsCollection()
      }
    }
    if (!eventId) {
      setEvent(null)
      setParticipants([])
    }
  }, [eventId])

  const value = useMemo(() => ({
    participateEvent: event,
    participants: participantsRelation,
    updateParticipant,
    reactions,
    eventId,
    isOrganizer
  }), [event, eventId, participantsRelation, reactions, isOrganizer, dbUsers, updateParticipant])

  return (
    <ParticipateEventContext.Provider value={value}>
      {children}
    </ParticipateEventContext.Provider>
  )
}
