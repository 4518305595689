import { Stack, Typography } from '@mui/material'
import { sendEmailVerification } from 'firebase/auth'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuthContext } from '@/contexts/AuthContext'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { auth } from '@/firebase'
import { BaseTemplate } from '@c/templates/BaseTemplate'
import { Button } from '@c/uiParts/common/Button'

export function EmailVerify() {
  const navigate = useNavigate()

  const { signOut, isAuthenticated } = useAuthContext()
  const { snackbar } = useSnackbarContext()

  const handleEmailSubmit = async () => {
    try {
      if (!(auth.currentUser)) throw new Error('user not found')
      await sendEmailVerification(auth.currentUser)
      snackbar({ message: 'メールを送信しました。' })
    } catch (err) {
      console.error(err)
      snackbar({ message: 'メール送信に失敗しました。', severity: 'error' })
    }
  }
  useEffect(() => {
    if (!isAuthenticated && signOut) {
      signOut()
      navigate('/login')
    }
  }, [isAuthenticated])
  return (
    <BaseTemplate>
      <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Stack sx={{ width: '400px', minWidth: '312px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>アカウントの有効化</Typography>
          <Stack spacing="20px" sx={{ marginTop: '32px', width: '100%' }}>
            <Typography sx={{ color: 'border.main' }}>
              アカウントがまだ有効化されていません。
              <br />
              「メール送信」ボタンをクリック後に、
              <br />
              <br />
              {auth.currentUser?.email || '???'}
              <br />
              <br />
              宛てに届く
              <strong>メール内のリンク</strong>
              からアカウントを有効化してください。
            </Typography>
            <Stack direction="row" spacing="12px" sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Typography
                onClick={() => {
                  if (signOut)signOut()
                }}
                sx={{
                  fontSize: '12px',
                  color: 'text.secondary',
                  opacity: '0.8',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: '1'
                  }
                }}
              >
                ← ログイン画面へ
              </Typography>
              <Button
                bgColor={(theme) => theme.palette.accent.main}
                textColor={(theme) => theme.palette.base.main}
                isShadow
                sx={{
                  borderRadius: '12px',
                  padding: '8px 32px',
                  fontWeight: 'bold',
                  fontSize: '16px'
                }}
                onClick={handleEmailSubmit}
              >
                メール送信
              </Button>
            </Stack>
          </Stack>

        </Stack>
      </Stack>
    </BaseTemplate>
  )
}
