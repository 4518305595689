// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined'
// import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined'
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined'
import StopScreenShareOutlinedIcon from '@mui/icons-material/StopScreenShareOutlined'
import { Typography, Stack, styled } from '@mui/material'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Peer from 'skyway-js'

import { BaseTemplate } from '@/components/templates/BaseTemplate'
import { QualitySelect } from '@/components/uiParts/common/QualitySelect'
import { ScreenCheckbox } from '@/components/uiParts/common/ScreenCheckbox'
import { EmojiReactionDialog } from '@/components/uiParts/Events/EventView/EmojiReactionDialog'
import { EventExitDialog } from '@/components/uiParts/Events/EventView/EventExitDialog'
import { ForceExitDialog } from '@/components/uiParts/Events/EventView/ForceExitDialog'
import { HamburgerMenu } from '@/components/uiParts/Events/EventView/HamburgerMenu'
import { ParticipantsListModal } from '@/components/uiParts/Events/EventView/ParticipantsListModal'
import { VideoUIPatterns } from '@/components/uiParts/Events/EventView/VideoUIPatterns'
import { useAuthContext } from '@/contexts/AuthContext'
import { EmojiReactionContextProvider } from '@/contexts/Events/EventView/EmojiReactionContext'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { useSettingContext } from '@/contexts/SettingContext'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { useStreamContext } from '@/contexts/StreamContext'
import { useUseragent } from '@/hooks/common/useUseragent'
import { useEvents } from '@/hooks/firestore/useEvents'
import { useForceExit } from '@/hooks/pages/events/EventView/useForceExit'
import { UserDoc } from '@/types/common'
import wait from '@/utils/wait'
import { EmojiAnimation } from '@c/uiParts/Events/EventView/EmojiAnimation'

const StyledContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '48px',
  '& ::-webkit-scrollbar': { width: '12px' },
  '& ::-webkit-scrollbar-thumb': {
    background: theme.palette.accent.main,
    borderRadius: '8px'
  },
  '& ::-webkit-scrollbar-track': {
    background: theme.palette.border.main,
    borderRadius: '8px'
  }
}))

const StyledEventTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: '12px',
  color: theme.palette.base.light,
  maxWidth: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export function EventView() {
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const isPrevPagePreview = Boolean(state && state.isPrevPagePreview)

  const { browserType } = useUseragent()

  const {
    canvasLocal,
    remoteStreams,
    updateLocal,
    peer,
    updatePeer,

    onStart,
    onEnd
  } = useStreamContext()

  const { user } = useAuthContext()
  const {
    voiceOn,
    shareScreen: isShareScreen,
    quality,
    updateSettings
  } = useSettingContext()

  const { snackbar } = useSnackbarContext()

  const { participate, leave } = useEvents()
  const { participateEvent: event, eventId, participants } = useParticipateEventContext()

  const {
    isFinished,
    isFinishedSec
  } = useForceExit({ isFinished: Boolean(event && event.finished_at) })

  const handleUpdate = ({ is_voice_on, is_share_screen, screen_quality } : Partial<UserDoc>) => {
    if (browserType === 'safari' && is_share_screen) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: false })
        .then((stream) => {
          if (updateLocal) {
            updateLocal(stream)
          }
        })
        .catch(() => {
          if (updateSettings)updateSettings({ is_share_screen: false })
          snackbar({ message: '画面共有をOFFにしました。' })
        })
    }
    if (updateSettings) {
      updateSettings({
        is_voice_on: is_voice_on === undefined ? voiceOn : is_voice_on,
        is_share_screen: is_share_screen === undefined ? isShareScreen : is_share_screen,
        screen_quality: screen_quality === undefined ? quality : screen_quality
      })
    }
  }

  const isJoinedEvent = useRef(false)
  useEffect(() => {
    const joinEvent = async () => {
      await wait(3)
      if (!(isJoinedEvent.current)
      && event && event.id
      && peer?.current && peer.current.open) {
      // [Todo] イベントへの参加権限があるかチェック
        if (onStart) {
          await onStart({ roomId: event.id || '' })
          await participate(
            event?.id || '',
            peer?.current?.id || ''
          )
          isJoinedEvent.current = true
        }
      }
    }

    const maxRetry = 5;
    (async () => {
      let retry = 0
      while (retry < maxRetry) {
        if (isJoinedEvent.current) break
        // eslint-disable-next-line no-await-in-loop
        await joinEvent()
        retry += 1
      }
    })()
  }, [event, participate])

  useEffect(() => {
    if (!isPrevPagePreview) {
      navigate(`${pathname}/preview`)
    } else {
      // 手動でlocation.stateをリセット
      navigate(pathname, {})
    }
    if (updatePeer)updatePeer(new Peer({ key: process.env.REACT_APP_SKYWAY_KEY as string }))

    return () => {
      if (onEnd)onEnd()
      if (eventId) {
        leave(eventId, user?.uid || '')
      }
    }
  }, [])

  const isFirstRefGetMedia = useRef(true)
  useEffect(() => {
    const isSafari = browserType === 'safari'
    if (isShareScreen
      && browserType && !isSafari
    // ok: ダイレクトアクセス
    // ng: プレビュー画面経由 && 初回
    && !(isFirstRefGetMedia.current)
    ) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: false })
        .then((stream) => {
          if (updateLocal) {
            updateLocal(stream)
          }
        })
        .catch(() => {
          if (updateSettings)updateSettings({ is_share_screen: false })
          snackbar({ message: '画面共有をOFFにしました。' })
        })
    }
    if (browserType && isFirstRefGetMedia.current) {
      isFirstRefGetMedia.current = false
    }
  }, [isShareScreen, browserType])

  return (
    <EmojiReactionContextProvider>
      <BaseTemplate
        isFooter={false}
        isMaxWidth={false}
        sx={(theme) => ({
          backgroundColor: theme.palette.text.primary,
          width: '100%'
        })}
      >
        <StyledContainer>
          <ForceExitDialog
            isFinished={isFinished}
            isFinishedSec={isFinishedSec}
            transitToTop={() => {
              navigate('/')
            }}
          />
          <HamburgerMenu>
            <VideoUIPatterns
              remoteStreams={remoteStreams}
              canvasLocalStream={canvasLocal}
            />
          </HamburgerMenu>
          <Stack
            direction="row"
            spacing="56px"
            sx={{
              justifyContent: 'center',
              position: 'fixed',
              bottom: '0',
              width: '100%',
              padding: '32px'
            }}
          >
            <Stack direction="row" spacing="24px" sx={{ alignItems: 'center' }}>
              <StyledEventTitle>
                {event?.started_at ? format(new Date((event.started_at as Timestamp).toMillis()), 'HH:mm ~ |') : ''}
              &ensp;
                {event?.name || ''}
              </StyledEventTitle>
              {/* <ScreenCheckbox
              value={voiceOn}
              onClick={() => handleUpdate({ is_voice_on: !voiceOn })}
              onIcon={<MicNoneOutlinedIcon />}
              offIcon={<MicOffOutlinedIcon />}
            /> */}
              <ScreenCheckbox
                value={isShareScreen}
                onClick={() => handleUpdate({ is_share_screen: !isShareScreen })}
                onIcon={<ScreenShareOutlinedIcon />}
                offIcon={<StopScreenShareOutlinedIcon />}
              />
              <ParticipantsListModal participants={participants} />
              <QualitySelect
                value={quality}
                onChange={(nextQuality) => {
                  handleUpdate({ screen_quality: nextQuality })
                }}
              />
            </Stack>
            <EventExitDialog event={event} userId={user?.uid || ''} />
            <EmojiReactionDialog />
            <EmojiAnimation />
          </Stack>
        </StyledContainer>
      </BaseTemplate>
    </EmojiReactionContextProvider>
  )
}
