import { Stack } from '@mui/material'

import { S3_BUCKET_URL } from '@/constants/common'
import { FeatureCardItemSp } from '@c/uiParts/Lp/ForthSection/FeatureCardItemSp'

export function ForthSectionSp() {
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Stack
        spacing="48px"
        sx={{
          padding: '48px 16px',
          backgroundColor: (theme) => theme.palette.base.main
        }}
      >
        <FeatureCardItemSp
          imgLink={`${S3_BUCKET_URL}/imagine_members.png`}
          headText="作業部屋をスケジュールして、人を集める"
          bodyText="好きな時間にIDとパスワードを設定して、作業部屋を作成いただけます。"
        />
        <FeatureCardItemSp
          imgLink={`${S3_BUCKET_URL}/office_room.png`}
          headText="人のスケジュールした部屋に参加"
          bodyText="他の方がスケジュールした部屋に参加を申し込むことができます。時間になるとルームが作成され、通知がくるのでご参加ください。"
        />
        <FeatureCardItemSp
          imgLink={`${S3_BUCKET_URL}/man_charing_blur.png`}
          headText="画面のぼかしで安心して作業"
          bodyText="会社の作業をするような際に、画面に適度なぼかしを設定いただくことで、セキュアかつ作業している感を出すことができます。"
        />
        <FeatureCardItemSp
          imgLink={`${S3_BUCKET_URL}/man_cheeringup.png`}
          headText="応援リアクション"
          bodyText="他の方の作業をご覧になる際は、是非応援リアクション（絵文字など）を送っていただけると、お互いに集中できる環境になります。"
        />
      </Stack>
    </Stack>
  )
}
