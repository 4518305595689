import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import PersonIcon from '@mui/icons-material/Person'
import { styled, Typography, Stack } from '@mui/material'

import { UserDoc } from '@/types/common'

const StyledAggregate = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.base.dark,
  borderRadius: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '2px 8px',
  gap: '4px'
}))

type Props = {
  participants: UserDoc[]
}
export function Participants({ participants }: Props) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
      {/* 最大表示人数は3人 */}
      {/* 主催者を先頭に持ってきて、頭に王冠をかぶせる */}
      <Stack direction="row" spacing="4px">
        {participants.length > 0 ? participants
          .filter((_, index) => index < 3)
          .map((participant) => (
            <AccountCircleOutlinedIcon
              sx={{
                borderRadius: '50%' }}
              key={participant.id}
            />
          )) : (
            <AccountCircleOutlinedIcon
              sx={{
                opacity: '0.3',
                borderRadius: '50%' }}
              key="noone"
            />
        )}
      </Stack>
      <StyledAggregate>
        <PersonIcon sx={{ fontSize: '12px' }} />
        <Typography sx={{ fontWeight: 'bold', fontSize: '10px' }}>
          {participants.length === 0 ? '-' : participants.length}
        </Typography>
      </StyledAggregate>
    </Stack>
  )
}
