import { Stack } from '@mui/material'
import { useState, useEffect } from 'react'

import { Button } from '@/components/uiParts/common/Button'
import { EventStartDialog } from '@/components/uiParts/Events/EventStartDialog'
import { useEvents } from '@/hooks/firestore/useEvents'
import { JoinedEventDoc } from '@/types/common'
import { ManagedEventSummary } from '@c/uiParts/Events/ManagedEventSummary'

export function EventsManage() {
  const [eventsDone, setEventsDone] = useState<JoinedEventDoc[]|null>(null)
  const [eventsLatest, setEventsLatest] = useState<JoinedEventDoc[]|null>(null)

  const { listEvents, submitCreateEventForm } = useEvents()

  const fetchEvents = async () => {
    const [resDone, resLatest] = await Promise.all([
      listEvents({ mode: 'owner_done' }),
      listEvents({ mode: 'owner_latest' })
    ])
    setEventsDone(resDone)
    setEventsLatest(resLatest)
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <Stack spacing="10px" sx={{ width: '680px' }}>
      <Stack direction="row" sx={{ justifyContent: 'flex-end', padding: '0 16px' }}>
        <EventStartDialog
          titleText="新規イベントを予約"
          submitText="イベント予約"
          onSubmit={submitCreateEventForm}
          onComplete={fetchEvents}
          isReserve
        >
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isShadow
            sx={{ fontSize: '12px', fontWeight: 'bold', padding: '8px 12px' }}
          >
            イベントを予約
          </Button>
        </EventStartDialog>
      </Stack>
      <Stack>
        {eventsLatest && eventsLatest.length > 0 && (
          <Stack
            direction="row"
            sx={{
              padding: '12px',
              overflowY: 'auto',
              flexWrap: 'wrap',
              gap: '16px 8px'

            }}
          >
            {eventsLatest && eventsLatest.map((items) => (
              <ManagedEventSummary items={items} refetchEvent={fetchEvents} key={items.id} />
            ))}
          </Stack>
        )}
        {eventsDone && eventsDone.length > 0 && (
          <Stack
            direction="row"
            sx={{
              padding: '12px',
              overflowY: 'auto',
              flexWrap: 'wrap',
              gap: '16px 8px'
            }}
          >
            {eventsDone && eventsDone.map((items) => (
              <ManagedEventSummary items={items} refetchEvent={fetchEvents} key={items.id} />
              // <ManagedFinishedEventSummary items={items} hashtags={hashtags} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
