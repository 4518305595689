import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    link: Palette['primary']
    base: Palette['primary']
    border: Palette['primary']
    alert: Palette['primary']
    label: Palette['primary']
    accent: Palette['primary']
    button: Palette['text']
  }
  interface PaletteOptions {
    link?: PaletteOptions['primary']
    base?: PaletteOptions['primary']
    border?: PaletteOptions['primary']
    alert?: PaletteOptions['primary']
    label?: PaletteOptions['primary']
    accent?: PaletteOptions['primary']
    button?: PaletteOptions['text']
  }
}

export const theme = createTheme({
  palette: {
    primary: { main: '#FF5E00', light: '#FFF' },
    secondary: { main: '#FFF', light: '#FFF' },
    error: { main: '#D32F2F' },
    base: { main: '#FFF8F8', dark: '#F0F0F0', light: '#FFF' },
    button: { primary: '#FF5E00', disabled: '#CCCCCC' },
    link: { main: '#3A89FF' },
    border: { main: '#575757' },
    alert: { main: '#FFF' },
    accent: { main: '#FFBB05' },
    text: { primary: '#1F1F1F', secondary: '#9F9F9F', disabled: '#FFF' },
    label: { main: '#FFF' }
  }
})
