import { Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { E0002 } from '@/constants/messages'
import { useAuthContext } from '@/contexts/AuthContext'
import { validEmailFormat } from '@/utils/validation/validEmailFormat'
import { BaseTemplate } from '@c/templates/BaseTemplate'
import { Button } from '@c/uiParts/common/Button'
import { FormText } from '@c/uiParts/common/FormText'
import { GoogleLoginButton } from '@c/uiParts/common/GoogleLoginButton'

const INITIAL_FORM_VALUE = { email: '', password: '' }

export function Login() {
  const navigate = useNavigate()
  const { signIn, signUpGoogle } = useAuthContext()

  const form = useForm({ defaultValues: INITIAL_FORM_VALUE, mode: 'all' })
  const handleSubmit = () => {
    form.handleSubmit(({ email, password }) => {
      if (signIn) { signIn(email, password) }
    })()
  }
  const transitToPasswordReset = () => {
    navigate('/password-reset')
  }

  const handleClick = async () => {
    if (signUpGoogle) {
      signUpGoogle()
    }
  }

  return (
    <BaseTemplate>
      <FormProvider {...form}>
        <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Stack sx={{ minWidth: '312px', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>ログイン</Typography>
            <GoogleLoginButton sx={{ marginTop: '12px' }} onClick={handleClick} />
            <Typography sx={(theme) => ({
              fontSize: '13px',
              color: theme.palette.text.secondary,
              marginTop: '32px'
            })}
            >
              またはメールアドレスでログイン
            </Typography>
            <Stack spacing="20px" sx={{ marginTop: '32px', width: '100%' }}>
              <FormText
                name="email"
                rules={{
                  required: '必須入力です。',
                  validate: (value: string) =>
                    validEmailFormat(value) || E0002('メールアドレス')
                }}
                placeholder="メールアドレス"
                variant="outlined"
                sx={(theme) => ({
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.border.main}!important`
                  }
                })}
              />
              <FormText
                name="password"
                rules={{
                  required: '必須入力です。'
                }}
                placeholder="パスワード"
                type="password"
                variant="outlined"
                sx={(theme) => ({
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.border.main}!important`
                  }
                })}
              />
              <Button
                bgColor={(theme) => theme.palette.primary.main}
                textColor={(theme) => theme.palette.base.main}
                isShadow
                sx={{
                  borderRadius: '12px',
                  padding: '8px 32px',
                  fontWeight: 'bold',
                  fontSize: '16px'
                }}
                onClick={handleSubmit}
              >
                ログイン
              </Button>
              <Button
                bgColor={(theme) => theme.palette.base.light}
                textColor={(theme) => theme.palette.link.main}
                isRounded
                sx={{ padding: '8px 32px', fontSize: '12px' }}
                onClick={transitToPasswordReset}
              >
                パスワードを忘れた
              </Button>
            </Stack>

          </Stack>
        </Stack>
      </FormProvider>
    </BaseTemplate>
  )
}
