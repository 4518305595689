import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'

import { INITIAL_FORM_VALUES } from '@/hooks/firestore/useEvents/INITIAL_FORM_VALUES'
import { EventDoc, EventForm, JoinedEventDoc } from '@/types/common'

export const normalizeEventToForm = (event: JoinedEventDoc | EventDoc): EventForm => ({
  event_capacity: `${event.max_user_number}`,
  event_start_time: format((event.start_time as Timestamp).toDate(), 'yyyy-MM-dd\'T\'HH:mm'),
  event_end_time: format((event.end_time as Timestamp).toDate(), 'yyyy-MM-dd\'T\'HH:mm'),
  event_name: event.name,
  event_public: event.is_public_event,
  forbid_voice: event.is_voice_on_prohibited,
  hashtags: event.hashtag_ids,
  hashtagText: '',
  event_started_at: event.started_at
    ? format((event.start_time as Timestamp).toDate(), 'yyyy-MM-dd\'T\'HH:mm')
    : INITIAL_FORM_VALUES.event_started_at,
  event_finished_at: event.finished_at
    ? format((event.finished_at as Timestamp).toDate(), 'yyyy-MM-dd\'T\'HH:mm')
    : INITIAL_FORM_VALUES.event_finished_at,
  participant_ids: event.participant_id_strings,
  reserver_ids: event.reserver_id_strings,
  announces: event.announces || []
})
