import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/uiParts/common/Button'
import { EventStartDialog } from '@/components/uiParts/Events/EventStartDialog'
import { useEvents } from '@/hooks/firestore/useEvents'
import { JoinedEventDoc } from '@/types/common'
import { UpcomingEventSummary } from '@c/uiParts/Events/UpcomingEventSummary'

export function EventsUpcoming() {
  const [events, setEvents] = useState<JoinedEventDoc[]|null>(null)

  const form = useForm({ defaultValues: { search_text: '' }, mode: 'all' })
  const { listEvents, submitCreateEventForm } = useEvents()
  const fetchEvents = async () => {
    const resEvents = await listEvents({ mode: 'latest' })
    setEvents(resEvents)
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <Stack spacing="10px" sx={{ width: '680px' }}>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between',
          padding: '12px 32px' }}
      >
        <Typography sx={{
          fontSize: '20px',
          fontWeight: 'bold'
        }}
        >
          直近イベント一覧
        </Typography>
        <FormProvider {...form}>
          <Stack
            direction="row"
            sx={{
              width: '180px',
              height: '24px',
              padding: '4px 4px 8px 12px',
              lineHeight: '1',
              alignItems: 'center'
            }}
          />
        </FormProvider>
      </Stack>
      <Stack spacing="10px" sx={{ padding: '12px', height: '50vh', overflowY: 'auto' }}>
        {events && events.map((event) => (
          <UpcomingEventSummary items={event} key={event.id} />
        ))}
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'flex-end', padding: '16px' }}>
        <EventStartDialog
          titleText="新規イベントを予約"
          submitText="イベント予約"
          onSubmit={submitCreateEventForm}
          onComplete={fetchEvents}
          isReserve
        >
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isRounded
            isShadow
            sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
          >
            新しいイベントを予約 →
          </Button>
        </EventStartDialog>

      </Stack>
    </Stack>
  )
}
