import CloseIcon from '@mui/icons-material/Close'
import { styled, Typography, Stack } from '@mui/material'
import { format } from 'date-fns'

import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { useEvents } from '@/hooks/firestore/useEvents'

const StyledContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.accent.main,
  borderRadius: '4px',
  padding: '8px',
  width: '100%'
}))

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.primary,
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap'

}))

type Props = {
  announceId: string
  message: string
  postedTime: Date
}

export function AnnounceItem({ announceId, message, postedTime }: Props) {
  const { snackbar } = useSnackbarContext()
  const { participateEvent: event, eventId, isOrganizer } = useParticipateEventContext()
  const { updateEvent } = useEvents()

  const handleClick = () => {
    if (eventId && event) {
      const { id: eveId, ...idRemovedEvent } = event
      try {
        const newAnnounces = (event.announces || []).filter(({ id }) => id !== announceId)
        updateEvent(eventId, {
          ...idRemovedEvent,
          announces: newAnnounces
        })
        snackbar({ message: 'アナウンスを削除しました。' })
      } catch (err) {
        console.error(err)
        snackbar({ message: 'アナウンスの削除に失敗しました。', severity: 'error' })
      }
    }
  }
  return (
    <StyledContainer>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <StyledText>
          {message}
        </StyledText>
        {isOrganizer && (
          <CloseIcon
            fontSize="small"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={handleClick}
          />
        )}
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
        <Typography sx={{ fontSize: '10px' }}>
          {format(postedTime, 'HH:mm')}
        </Typography>
      </Stack>
    </StyledContainer>
  )
}
