import { Stack, StackProps } from '@mui/material'
import { ReactNode } from 'react'

type Props = StackProps & {
  onClick?: (value: any) => void
  value: boolean
  onIcon: ReactNode
  offIcon: ReactNode
}

export function ScreenCheckbox({
  onClick,
  value,
  onIcon,
  offIcon,
  ...rest
}: Props) {
  return (
    <Stack
      onClick={onClick}
      {...rest}
      sx={(theme) => ({
        justifyContent: 'center',
        alignItems: 'center',
        width: '52px',
        height: '52px',
        backgroundColor: value
          ? theme.palette.text.primary
          : theme.palette.primary.main,
        borderRadius: '50%',
        border: value
          ? `1px solid ${theme.palette.base.light}` : 'none',
        color: theme.palette.base.light,
        cursor: 'pointer',
        '&:hover': value
          ? {
            opacity: '0.8',
            backgroundColor: 'rgba(255,255,255,0.7)'
          }
          : { opacity: '0.8' }
      })}
    >
      {value ? onIcon : offIcon}
    </Stack>
  )
}
