import { Timestamp } from '@firebase/firestore-types'
import { styled, Box, Stack } from '@mui/material'
import gsap from 'gsap'
import { useEffect, useRef, useState } from 'react'

import { useAuthContext } from '#/src/contexts/AuthContext'
import { EventReactionDoc } from '#/src/types/common'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'

const WaveBox = styled(Box)(() => ({
  position: 'absolute',
  bottom: '64px',
  left: '100px',
  transform: 'translate(-50%, -50%)',
  fontSize: '48px'
}))

function EmojiItem({ emoji }: {emoji: string}) {
  const ref = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref.current) {
      const initialX = Math.floor(Math.random() * 100)
      const fontSize = Math.floor(Math.random() * 28) + 24

      const waveTween = gsap.timeline()
      waveTween
        .set(ref.current, { x: initialX, fontSize, duration: 0 })
        .to(ref.current, { y: '-250px', opacity: 0.8, duration: 8, ease: 'none' })
        .to(ref.current, { y: '-300px', opacity: 0, duration: 1 })
      return () => { waveTween.kill() }
    }
  }, [])
  return (
    <WaveBox ref={ref}>
      <span role="img" aria-label="emoji">
        {emoji}
      </span>
    </WaveBox>
  )
}

export function EmojiAnimation() {
  const { reactions } = useParticipateEventContext()

  const [animateReactions, setAnimateReactions] = useState<EventReactionDoc[]>([])
  const { user } = useAuthContext()

  // - 直近10秒以内に追加された場合のみ、アニメーションを実行する
  // - リアクション対象が自分の場合のみ、アニメーションを実行する

  useEffect(() => {
    const newReactions = reactions.filter((reaction) => {
      const now = new Date()
      if (!(reaction.created_at)) return false
      const reactionTime = (reaction.created_at as Timestamp).toDate()
      const diff = now.getTime() - reactionTime.getTime()
      return diff < 10 * 1000 // 10s * 1000ms
    })
    const meReactions = newReactions.filter((reaction) => reaction.to_user_id === user?.uid)
    setAnimateReactions(meReactions)
  }, [reactions])

  return (
    <Stack>
      {animateReactions.map((reaction) => (
        // eslint-disable-next-line react/no-array-index-key
        <EmojiItem key={reaction.id} emoji={reaction.emoji} />
      ))}
    </Stack>
  )
}
