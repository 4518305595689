import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Box, Popover, Stack, styled } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  signOut?: () => Promise<void>
  userName: string
}

const StyledActionBox = styled(Box)({
  padding: '8px 32px',
  fontSize: '16px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#ddd'
  }
})

export function AccountPopup({ signOut, userName }: Props) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigateToMyPage = () => {
    navigate('/me')
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover-account' : undefined

  return (
    <Box>
      <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <AccountCircleOutlinedIcon sx={{ width: '40px', height: '40px' }} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Stack sx={{ padding: '16px 0' }}>
          <Box sx={{
            padding: '8px 32px',
            fontSize: '16px',
            fontWeight: 'bold'
          }}
          >
            {userName}
          </Box>
          <StyledActionBox
            onClick={navigateToMyPage}
          >
            プロフィール編集
          </StyledActionBox>
          <StyledActionBox
            onClick={signOut}
          >
            サインアウト
          </StyledActionBox>
        </Stack>
      </Popover>
    </Box>

  )
}
