import { createContext, useContext, useMemo, useState } from 'react'

import type { ChildrenReactElement, ParticipantDoc } from '@/types/common'

import { useSnackbarContext } from '#/src/contexts/Snackbar'
import { useAuthContext } from '@/contexts/AuthContext'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { useEventReactions } from '@/hooks/firestore/useEventReactions'

type ContextTypes = {
  emoji: string,
  updateEmoji: (emoji: string) => void,
  isOpenPicker: boolean,
  updateIsOpenPicker: (isOpen: boolean) => void,
  user: ParticipantDoc | undefined,
  updateTargetUser: (peerId: string) => void,
  submitEmoji: () => void
}
const INITIAL_VALUES: ContextTypes = {
  emoji: '👍',
  updateEmoji: () => {},
  isOpenPicker: false,
  updateIsOpenPicker: () => {},
  user: undefined,
  updateTargetUser: () => {},
  submitEmoji: () => {}
}

const EmojiReactionContext = createContext<ContextTypes>(INITIAL_VALUES)

export function useEmojiReactionContext() {
  return useContext(EmojiReactionContext)
}

/**
 * reducerとcontextを子コンポーネントに渡す
 * @param children 子コンポーネント
 * @returns 子コンポーネント
 */
export function EmojiReactionContextProvider({ children }: ChildrenReactElement) {
  const [emoji, setEmoji] = useState<ContextTypes['emoji']>(INITIAL_VALUES.emoji)
  const [user, setUser] = useState<ContextTypes['user']>(undefined)
  const [isOpenPicker, setIsOpenPicker] = useState<ContextTypes['isOpenPicker']>(false)

  const { participants, eventId } = useParticipateEventContext()
  const { updateReactions } = useEventReactions()
  const { user: authUser } = useAuthContext()
  const { snackbar } = useSnackbarContext()

  const updateEmoji = (moji: string) => {
    setEmoji(moji)
  }
  const updateIsOpenPicker = (isOpen: boolean) => {
    setIsOpenPicker(isOpen)
  }

  const updateTargetUser = (peerId: string) => {
    const targetUser = participants.find(({ peer_id }) => peer_id === peerId)
    setUser(targetUser)
  }

  const submitEmoji = async () => {
    try {
      if (!eventId) throw new Error('eventId is null')
      await updateReactions({ eventId,
        data: {
          emoji, from_user_id: authUser?.uid || '', to_user_id: user?.user_id || ''
        } })
      snackbar({ message: `絵文字${emoji}を送信しました。`, severity: 'success' })
    } catch (err) {
      snackbar({ message: '絵文字の送信に失敗しました。', severity: 'error' })
      throw err
    }
  }

  const context = useMemo(
    () => ({
      emoji,
      updateEmoji,
      isOpenPicker,
      updateIsOpenPicker,
      user,
      updateTargetUser,
      submitEmoji
    }),
    [emoji, isOpenPicker, updateEmoji, updateIsOpenPicker, user, updateTargetUser]
  )

  return <EmojiReactionContext.Provider value={context}>{children}</EmojiReactionContext.Provider>
}
