import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'

import { markdown } from '@/assets/privacy_policy'
import { BaseTemplate } from '@c/templates/BaseTemplate'

export function PrivacyPolicy() {
  return (
    <BaseTemplate>
      <Box sx={{
        padding: '80px 0',
        '& h2': { marginTop: '48px' },
        '& p': { margin: '4px 0 0' }
      }}
      >
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </Box>
    </BaseTemplate>
  )
}
