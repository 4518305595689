export const joinRecord = <T, V>(a: T, b: V[], aKey: string, bKey: string, key: string) => {
  // DBレコードaに対して、DBレコード配列のbを結合する
  // aKey: aのキー
  // bKey: bのキー
  //
  // 例: a = { id: '1', name: 'foo', user_id: '1' }
  //     b = [{ id: '1', name: 'bar' }, { id: '2', name: 'baz' }]
  //     aKey = 'user_id'
  //     bKey = 'id'
  //     key = 'user'
  //     => { id: '1', name: 'foo', user_id: '1', user: { id: '1', name: 'bar' } }
  //
  const bRecord = b.find((record: any) => record[bKey] === (a as any)[aKey])
  return { ...a, [key]: bRecord }
}
