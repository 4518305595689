/* eslint-disable import/no-duplicates */
import { styled, Box, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { Timestamp } from 'firebase/firestore'
import { useState, useEffect } from 'react'

import { useHashtags } from '@/hooks/firestore/useHashtags'
import { HashtagDoc, JoinedEventDoc } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'

type Props ={
  items: JoinedEventDoc
}

const StyledHashTag = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.base.dark,
  padding: '4px 8px',
  borderRadius: '24px',
  fontSize: '12px',
  fontWeight: 'medium',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)'
}))

export function UpcomingEventSummary({ items }: Props) {
  const [hashtags, setHashtags] = useState<Required<HashtagDoc>[]>([])
  const { listHashtags } = useHashtags()

  const fetchHashtags = async () => {
    const res = await listHashtags()
    setHashtags(res)
  }
  useEffect(() => {
    fetchHashtags()
  }, [])

  return (
    <Stack
      direction="row"
      spacing="16px"
      sx={(theme) => ({
        minWidth: '620px',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
        padding: '10px'
      })}
    >
      <Typography sx={{
        fontWeight: 'bold', fontSize: '12px', lineHeight: '24px' }}
      >
        {format(new Date((items.start_time as Timestamp).toDate()), 'MM/dd(E)', { locale: ja })}
      </Typography>
      <Stack>
        <Typography sx={{
          fontWeight: 'bold', fontSize: '12px', lineHeight: '24px' }}
        >
          開始&emsp;
          {format(new Date((items.start_time as Timestamp).toDate()), 'HH:mm')}
        </Typography>
        <Typography sx={{
          fontWeight: 'bold', fontSize: '12px', lineHeight: '24px' }}
        >
          終了&emsp;
          {format(new Date((items.end_time as Timestamp).toDate()), 'HH:mm')}
        </Typography>
      </Stack>

      <Stack sx={{ width: '280px', gap: '2px' }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          『
          {items.name}
          』
        </Typography>
        <Stack
          direction="row"
          sx={{ flexWrap: 'wrap', gap: '4px 12px' }}
        >
          {hashtags.filter((tag) => items.hashtag_ids.includes(tag.id)).map((tag) => (
            <StyledHashTag key={tag.id}>
              #
              {tag.name}
            </StyledHashTag>
          ))}
        </Stack>
      </Stack>
      <Stack>
        {/* <Participants participantIds={items.participant_ids} /> */}
        <Stack>
          <Typography sx={{
            fontWeight: 'bold', fontSize: '12px', lineHeight: '24px' }}
          >
            予約人数:&ensp;
            {items.participant_id_strings.length}
            /
            {items.max_user_number}
            人
          </Typography>
        </Stack>
      </Stack>
      <Button
        bgColor={(theme) => theme.palette.accent.main}
        textColor={(theme) => theme.palette.base.main}
        isRounded
        sx={{ fontSize: '14px', fontWeight: 'bold' }}
      >
        予約する
      </Button>
    </Stack>
  )
}
