import { addDays } from 'date-fns'
import { User } from 'firebase/auth'
import { collection, CollectionReference, getDocs, orderBy, query, where } from 'firebase/firestore'

import { db } from '@/firebase'
import { useHashtags } from '@/hooks/firestore/useHashtags'
import { useUsers } from '@/hooks/firestore/useUsers'
import { EventDoc } from '@/types/common'
import { getUserIdsFromEventColumns, joinUserAndHashtagToEvent } from '@/utils/getUserIdsFromEventColumns'

const { listUsers } = useUsers()
const { listHashtags } = useHashtags()

// 開催中, 直近, 主催
export const listEvents = (user: User | null) => async ({ mode } :
    {mode: 'latest'|'now'|'owner_done'|'owner_latest'}) => {
  const eventColRef = collection(db, 'events') as CollectionReference<EventDoc>

  const modeQueries = (() => {
    switch (mode) {
      // 直近開催予定のイベント（start_time > now && start_time < (now + 7days)）
      case 'latest':
        return [
          where('start_time', '>', new Date()),
          where('start_time', '<', addDays(new Date(), 7)),
          orderBy('start_time', 'asc')
        ]
        // 開催中のイベント（started_at !== null  && finished_at === null）
      case 'now':
        return [
          where('started_at', '!=', null),
          where('finished_at', '==', null),
          orderBy('started_at', 'desc')
        ]
        // [Todo] バッチであんまりに長くfinishedに入ってこないものを
        // 主催イベント && finished_at !== null
      case 'owner_done':
        return [
          where('organizer_ids', 'array-contains', user?.uid),
          where('finished_at', '!=', null),
          orderBy('finished_at', 'desc')
        ]
        // 主催イベント && finished_at == null
      case 'owner_latest':
        return [
          where('organizer_ids', 'array-contains', user?.uid),
          where('finished_at', '==', null),
          orderBy('start_time', 'asc')
        ]
      default:
        return []
    }
  })()
  const q = query(eventColRef, ...modeQueries)
  const querySnapshot = await getDocs(q)
  const events = querySnapshot.docs.map((resdoc) => ({ ...resdoc.data(), id: resdoc.id }))

  const userIds = getUserIdsFromEventColumns(events)

  const [users, hashtags] = await Promise.all([
    // [Todo] listUsersを使わずにuserContextを使用する
    listUsers({ userIds }),
    listHashtags()
  ])

  return joinUserAndHashtagToEvent({ events, users, hashtags })
}
