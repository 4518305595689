import CloseIcon from '@mui/icons-material/Close'
import { styled, Box, Alert, Stack, IconButton } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import { isAfter, parse } from 'date-fns'
import useInterval from 'use-interval'

import { HEADER_HEIGHT } from '@/constants/styles'
import { useSnackbarContext } from '@/contexts/Snackbar'

const StyledContainer = styled(Stack)({
  position: 'absolute'
})

const StyledAlert = styled(Alert)({
  '& .MuiAlert-message': {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  '& .MuiAlert-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const positions: {key: string, styles: SxProps}[] = [
  {
    key: 'bottom-left',
    styles: { left: '8px', bottom: '100px' }
  },
  {
    key: 'top-center',
    styles: { left: '40%', top: `calc(${HEADER_HEIGHT} + 8px)` }
  },
  {
    key: 'top-right',
    styles: { right: '8px', top: `calc(${HEADER_HEIGHT} + 8px)` }
  }
]

export function Snackbar() {
  const { messages, closeSnackbar } = useSnackbarContext()

  useInterval(() => {
    messages.forEach(({ id, closeDate }) => {
      if (isAfter(
        new Date(),
        parse(closeDate, 'yyyy-MM-dd HH:mm:ss', new Date())
      )) {
        closeSnackbar(id)
      }
    })
  }, 300)

  return (
    <Box>
      {positions.map(({ key, styles }) => (
        <StyledContainer key={key} spacing="8px" sx={styles}>
          {messages
            .filter((message) => message.position === key)
            .map(({ id, position, message, severity }) => (
              <StyledAlert
                key={`${id} ${position}`}
                elevation={5}
                variant="filled"
                severity={severity}
                sx={{ width: '100%' }}
                action={(
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { closeSnackbar(id) }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                )}
              >
                {message}
              </StyledAlert>
            ))}
        </StyledContainer>
      ))}
    </Box>
  )
}
