import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useUsers } from '@/hooks/firestore//useUsers'
import { UserDoc } from '@/types/common'

type Props = {
  pathReg: string
}

export const useUserByPathId = ({ pathReg }: Props) => {
  const [user, setUser] = useState<UserDoc| null>(null)

  const location = useLocation()
  const { getUser } = useUsers()

  useEffect(() => {
    (async () => {
      const userMatched = location.pathname.match(new RegExp(pathReg))
      const userId = userMatched && userMatched.length > 1
        ? userMatched[1] : null

      if (!userId) return
      const res = await getUser(userId) as UserDoc
      setUser(res)
    })()
  }, [])

  return { user }
}
