import { GlobalStyles, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import { EventsUpcoming } from '@/components/pages/Events/Upcoming'
import { MyPage } from '@/components/pages/MyPage'
import { Preview } from '@/components/pages/Preview'
import { SnackbarContextProvider } from '@/contexts/Snackbar'
import { theme } from '@/styles/theme'
import { Account } from '@c/pages/Account'
import { EmailVerify } from '@c/pages/EmailVerify'
import { Events } from '@c/pages/Events'
import { EventExit } from '@c/pages/Events/EventExit'
import { EventView } from '@c/pages/Events/EventView'
import { EventsInprogress } from '@c/pages/Events/Inprogress'
import { EventsManage } from '@c/pages/Events/manage'
import { Login } from '@c/pages/Login'
import { Lp } from '@c/pages/Lp'
import { NotSupported } from '@c/pages/NotSupported'
import { PasswordReset } from '@c/pages/PasswordReset'
import { PrivacyPolicy } from '@c/pages/Privacy'
import { Signup } from '@c/pages/Signup'
import { AnonymousAuthGuard } from '@c/templates/AnonymousAuthGuard'
import { AuthGuard } from '@c/templates/AuthGuard'
import { WholeGuard } from '@c/templates/WholeGuard'
import { Snackbar } from '@c/uiParts/common/Snackbar'

function App() {
  return (
    <SnackbarContextProvider>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          <GlobalStyles styles={{ body: { color: theme.palette.text.primary } }} />
          <Router>
            <Routes>
              <Route path="" element={<WholeGuard />}>
                <Route path="/email-verify" element={<EmailVerify />} />
                <Route path="/not-supported" element={<NotSupported />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                {/* 認証前 */}
                <Route path="" element={<AnonymousAuthGuard />}>
                  <Route path="/" element={<Lp />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/password-reset" element={<PasswordReset />} />
                </Route>

                {/* 認証済み */}
                <Route path="" element={<AuthGuard />}>
                  <Route path="/events/:id" element={<EventView />} />
                  <Route path="/events/:id/preview" element={<Preview />} />
                  <Route path="/events/:id/exit" element={<EventExit />} />
                  <Route path="events" element={<Events />}>
                    <Route path="upcoming" element={<EventsUpcoming />} />
                    <Route path="inprogress" element={<EventsInprogress />} />
                    <Route path="manage" element={<EventsManage />} />
                  </Route>
                  <Route path="/me" element={<MyPage />} />
                  <Route path="/account/:id" element={<Account />} />
                </Route>

              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
          <Snackbar />
        </>

      </ThemeProvider>
    </SnackbarContextProvider>
  )
}

export default App
