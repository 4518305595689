import { SCREEN_MODE } from '@/constants/common'
import { useScreenModeContext } from '@/contexts/ScreenModeContext'
import { GridUI } from '@c/uiParts/Events/EventView/VideoUIPatterns/GridUI'
import { StageUI } from '@c/uiParts/Events/EventView/VideoUIPatterns/StageUI'

type Props = {
  remoteStreams: MediaStream[]
  canvasLocalStream: MediaStream | null
}

export function VideoUIPatterns({
  remoteStreams,
  canvasLocalStream
}: Props) {
  const { mode } = useScreenModeContext()
  switch (mode) {
    case SCREEN_MODE.grid:
      return (
        <GridUI
          remoteStreams={remoteStreams}
          canvasLocalStream={canvasLocalStream}
        />
      )
    case SCREEN_MODE.stage:
      return (
        <StageUI
          remoteStreams={remoteStreams}
          canvasLocalStream={canvasLocalStream}
        />
      )
    default:
      return null
  }
}
