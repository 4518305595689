import { User } from 'firebase/auth'
import { FieldValue, serverTimestamp } from 'firebase/firestore'
import { UseFormReturn } from 'react-hook-form'

import { INITIAL_FORM_VALUES } from '@/hooks/firestore/useEvents/INITIAL_FORM_VALUES'
import { updateEvent } from '@/hooks/firestore/useEvents/updateEvent'
import { EventDoc, EventForm } from '@/types/common'
import { dateToFirestoredate } from '@/utils/date/dateToFirestoredate'

export const submitUpdateEventForm = (user: User | null) =>
  (created_at: FieldValue, eventId?: string) =>
    async (
      form: UseFormReturn<EventForm, any>,
      handleComplete: () => void,
      handleError: () => void
    ) => {
      if (!eventId) {
        return
      }
      form.handleSubmit(
        async ({
          event_capacity,
          event_start_time,
          event_end_time,
          event_name,
          event_public,
          forbid_voice,
          hashtags,
          event_started_at,
          event_finished_at,
          participant_ids,
          reserver_ids,
          announces
        }) => {
          const data: EventDoc = {
            name: event_name,
            max_user_number: Number(event_capacity),

            start_time: dateToFirestoredate(event_start_time),
            end_time: dateToFirestoredate(event_end_time),
            started_at:
            event_started_at === INITIAL_FORM_VALUES.event_started_at ? null : dateToFirestoredate(event_started_at),
            finished_at:
            event_finished_at === INITIAL_FORM_VALUES.event_finished_at ? null : dateToFirestoredate(event_finished_at),

            is_public_event: event_public,
            is_voice_on_prohibited: forbid_voice,
            created_at,
            updated_at: serverTimestamp(),
            organizer_ids: [user ? user.uid : '0'],
            hashtag_ids: hashtags,

            participant_id_strings: participant_ids,
            reserver_id_strings: reserver_ids,
            announces: announces || []
          }

          try {
            await updateEvent(eventId, data)
            handleComplete()
          } catch (err) {
            console.error(`[👹updateEvent_ERROR]::${err}`)
            handleError()
          }
        }
      )()
    }
