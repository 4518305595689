import { DialogActions } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Zoom from '@mui/material/Zoom'
import { useEffect, forwardRef } from 'react'

import { FORCE_EXIT_LIMIT_TIME } from '@/constants/common'
import { Button } from '@c/uiParts/common/Button'

type Props = {
  isFinished: boolean;
  isFinishedSec: number;
  transitToTop: () => void
}

const Transition = forwardRef((props: any, ref) => (
  <Zoom ref={ref} {...props}>
    {props.children}
  </Zoom>
))

export function ForceExitDialog({
  isFinished,
  isFinishedSec,
  transitToTop,
  ...rest
}: Props) {
  const handleClick = () => {
    transitToTop()
  }

  useEffect(() => {
    if (isFinishedSec <= 0) {
      transitToTop()
    }
  }, [isFinishedSec])
  return (
    <Dialog
      open={isFinished}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      {...rest}
    >
      <DialogTitle>自動で退室します</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          主催者不在のため
          {FORCE_EXIT_LIMIT_TIME}
          秒後に退室します。
          <br />
          残り時間は、
          {isFinishedSec}
          秒です。
          <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: theme.palette.base.light,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: '12px 20px'
        })}
      >
        <Button
          bgColor={(theme) => theme.palette.primary.main}
          textColor={(theme) => theme.palette.base.main}
          isRounded
          isShadow
          sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
          onClick={handleClick}
        >
          退室する
        </Button>
      </DialogActions>

    </Dialog>

  )
}
