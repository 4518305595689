import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Dialog, DialogTitle, DialogContent
} from '@mui/material'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ParticipantDoc } from '@/types/common'

type Props = {
  participants: ParticipantDoc[]
}

export function ParticipantsListModal({ participants }: Props) {
  const location = useLocation()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  // [Todo] 複数回のクリックで3秒未満で閉じる
  useEffect(() => {
    setTimeout(() => { setCopied(false) }, 3000)
  }, [copied])

  const handleClickClose = () => {
    setDialogOpen(false)
  }
  const handleClickCopy = () => {
    navigator.clipboard.writeText(`https://mokupa.com${location.pathname}`)
    setCopied(true)
  }

  return (
    <Box>
      <Stack
        sx={(theme) => ({
          backgroundColor: theme.palette.text.primary,
          color: theme.palette.base.light,
          width: '52px',
          height: '52px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '32px',
          border: `1px solid ${theme.palette.base.light}`,
          cursor: 'pointer',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.7)',
            opacity: '0.8'
          }
        })}
        onClick={() => setDialogOpen(true)}
      >
        <PermIdentityOutlinedIcon />
      </Stack>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        sx={{ '& .MuiDialog-paperScrollPaper': {
          overflowY: 'visible',
          maxWidth: 'none',
          minWidth: '400px'
        } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 24px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          })}
        >
          参加者一覧
          <CloseIcon
            sx={{
              width: '32px', height: '32px', cursor: 'pointer' }}
            onClick={handleClickClose}
          />
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'visible', marginTop: '24px' }}>
          <Stack spacing="20px" sx={{ minHeight: '72px' }}>
            {participants.map(({ user }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Stack key={index} direction="row" spacing="8px">
                <PersonIcon sx={{ backgroundColor: 'purple', borderRadius: '50px' }} />
                <Typography>{user?.display_name || '???'}</Typography>
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogContent sx={{ overflowY: 'visible' }}>
          <Stack direction="row" spacing="16px" sx={{ alignItems: 'center' }}>
            <Typography sx={{
              maxWidth: '296px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '12px'
            }}
            >
              {`招待リンク: https://mokupa.com${location.pathname}`}
            </Typography>
            <Tooltip open={copied} title="Copied" placement="top">
              <Stack
                sx={() => ({
                  width: '16px',
                  height: '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: '14px',
                  '&:hover': { opacity: '0.8' }
                })}
                onClick={handleClickCopy}
              >
                <ContentCopyIcon />
              </Stack>
            </Tooltip>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
