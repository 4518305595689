import Box from '@mui/material/Box'
import { ReactNode, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUseragent } from '@/hooks/common/useUseragent'

export function UserAgentCheck({ children }: {children: ReactNode}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [cookies] = useCookies()

  const { browserType } = useUseragent()
  const isAlreadyUnderstandUnsupported = cookies?.not_supported && cookies.not_supported === 'true'

  useEffect(() => {
    if (browserType
      && !isAlreadyUnderstandUnsupported
      && !(['chrome', 'firefox'].includes(browserType))
      && location.pathname !== '/'
    ) {
      navigate('/not-supported', { state: { browserType } })
    }
  }, [browserType, location])

  return (
    <Box>
      {children}
    </Box>
  )
}
