import { useEffect, useState } from 'react'

import { FORCE_EXIT_LIMIT_TIME } from '@/constants/common'

type Props = {
  isFinished: boolean
}

export const useForceExit = ({ isFinished }: Props) => {
  // 10秒以下になったらダイアログ表示
  const [isFinishedSec, setIsFinishedSec] = useState<number>(FORCE_EXIT_LIMIT_TIME + 10)

  // const getUsernameByPeerid = ({ peerId }: {peerId: string}) => {
  //   const matchedParticipant = activeParticipants.find((participant) => peerId === participant.peer_id)
  //   return matchedParticipant ? matchedParticipant?.user?.display_name : 'anonymous'
  // }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timer = setInterval(() => {
      if (isFinished) {
        setIsFinishedSec((prev) => prev - 1)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
      setIsFinishedSec(FORCE_EXIT_LIMIT_TIME + 10)
    }
  }, [isFinished])

  return {
    isFinished: isFinishedSec <= FORCE_EXIT_LIMIT_TIME,
    isFinishedSec
  }
}
