import { addDoc, collection } from 'firebase/firestore'

import { db } from '@/firebase'
import { EventDoc } from '@/types/common'

export const createEvent = async (data: EventDoc) => {
  const eventColRef = collection(db, 'events')
  const newDoc = await addDoc(eventColRef, data)
  return newDoc
}
