import { styled, Stack, StackProps } from '@mui/material'
import { ReactElement } from 'react'

import { FOOTER_HEIGHT, FOOTER_HEIGHT_SP, HEADER_HEIGHT } from '@/constants/styles'
import useMediaQuery from '@/hooks/common/useMediaQuery'
import { Footer } from '@c/uiParts/Footer'
import { Header } from '@c/uiParts/Header'
import { HeaderSp } from '@c/uiParts/Header/sp'

type Props = StackProps & {
  children: ReactElement;
  isFooter? :boolean;
  isHeader?: boolean;
  isMaxWidth?: boolean;
}

type StyleProps = {
  ismobile: boolean
  isheader: boolean
  isfooter: boolean
  ismaxwidth: boolean
}

const StyledBodyContainer = styled(Stack, {
  shouldForwardProp: (prop) => !([
    'ismobile',
    'ismaxwidth',
    'isheader',
    'isfooter'
  ].includes(prop as string))
})<StyleProps>(({ ismobile, ismaxwidth, isheader, isfooter }) => ({
  marginTop: HEADER_HEIGHT,
  minHeight: `calc(100vh - ${isheader ? HEADER_HEIGHT : '0px'} - ${
    (() => {
      if (!isfooter) return '0px'
      return ismobile ? FOOTER_HEIGHT_SP : FOOTER_HEIGHT
    })()
  })`,
  maxHeight: `calc(100vh - ${isheader ? HEADER_HEIGHT : '0px'} - ${
    (() => {
      if (!isfooter) return '0px'
      return ismobile ? FOOTER_HEIGHT_SP : FOOTER_HEIGHT
    })()
  })`,
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    display: 'none'
  },
  width: '100vw',
  maxWidth: ismaxwidth ? '1000px' : 'none',
  padding: `0 ${ismobile ? 20 : 80}px`
}))

export function BaseTemplate({
  children,
  isFooter = true,
  isHeader = true,
  isMaxWidth = true,
  ...rest
}: Props) {
  const isMobile = useMediaQuery({ variant: 'mobile' })

  return (
    <Stack sx={{ alignItems: 'center', backgroundColor: 'base.main' }}>
      {(() => {
        if (!isHeader) return null
        return isMobile ? (
          <HeaderSp />
        ) : (
          <Header />
        )
      })()}
      <StyledBodyContainer
        ismaxwidth={isMaxWidth}
        ismobile={isMobile}
        isheader={isHeader}
        isfooter={isFooter}
        {...rest}
      >
        {children}
      </StyledBodyContainer>
      { isFooter && <Footer />}
    </Stack>
  )
}
