/** パスワード形式チェック */
const MIN_LENGTH = 8

export const validPasswordFormat = (password: string) => {
  if (password.length < MIN_LENGTH) {
    return 'パスワードは、8文字以上の文字列が必要です。'
  }
  // Check for mixed case
  if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) {
    return 'パスワードは、少なくとも一つの大文字と小文字が必要です。'
  }
  // Check for numbers
  if (!password.match(/\d/)) {
    return 'パスワードは、少なくとも一つの数字が必要です。'
  }
  // Check for special characters
  if (!password.match(/[^a-zA-Z\d]/)) {
    return 'パスワードは、少なくとも一つの特殊文字が必要です。'
  }
  return true
}
