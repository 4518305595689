import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BaseTemplate } from '@/components/templates/BaseTemplate'
import { Button } from '@/components/uiParts/common/Button'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'

export function EventExit() {
  const [lastSec, setLastSec] = useState(30)
  const { participateEvent: event } = useParticipateEventContext()

  const navigate = useNavigate()

  const handleReassignEvent = () => {
    if (event) navigate(`/events/${event.id}/preview`)
  }
  const transitToEventList = () => {
    navigate('/events/inprogress')
  }

  useEffect(() => {
    if (lastSec <= 0) {
      navigate('/events/inprogress')
    }
    setTimeout(() => { setLastSec(lastSec - 1) }, 1000)
  }, [lastSec])
  return (
    <BaseTemplate sx={{ justifyContent: 'center' }}>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Stack spacing="32px" sx={{ marginTop: '-120px' }}>
          <Typography sx={{ fontSize: '32px' }}>イベントを退出しました</Typography>
          <Stack direction="row" spacing="16px" sx={{ justifyContent: 'center' }}>
            <Button
              bgColor={(theme) => theme.palette.base.main}
              textColor={(theme) => theme.palette.primary.main}
              isShadow
              sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
              onClick={handleReassignEvent}
            >
              再参加
            </Button>
            <Button
              bgColor={(theme) => theme.palette.primary.main}
              textColor={(theme) => theme.palette.base.main}
              isShadow
              sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
              onClick={transitToEventList}
            >
              イベント一覧に戻る
            </Button>

          </Stack>
          <Typography sx={{ fontSize: '16px' }}>
            {lastSec}
            秒後にイベント一覧画面に遷移します。
          </Typography>
        </Stack>
      </Stack>
    </BaseTemplate>
  )
}
