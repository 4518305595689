import { Stack } from '@mui/material'

import useMediaQuery from '@/hooks/common/useMediaQuery'
import { BaseTemplate } from '@c/templates/BaseTemplate'
import { FiveSection } from '@c/uiParts/Lp/FiveSection'
import { FiveSectionSp } from '@c/uiParts/Lp/FiveSection/sp'
import { ForthSection } from '@c/uiParts/Lp/ForthSection'
import { ForthSectionSp } from '@c/uiParts/Lp/ForthSection/sp'
import { SecondSection } from '@c/uiParts/Lp/SecondSection'
import { SecondSectionSp } from '@c/uiParts/Lp/SecondSection/sp'
import { ThirdSection } from '@c/uiParts/Lp/ThirdSection'
import { ThirdSectionSp } from '@c/uiParts/Lp/ThirdSection/sp'
import { TopSection } from '@c/uiParts/Lp/TopSection'
import { TopSectionSp } from '@c/uiParts/Lp/TopSection/sp'

export function Lp() {
  const isMobile = useMediaQuery({ variant: 'mobile' })
  return (
    <BaseTemplate isMaxWidth={false}>
      {isMobile ? (
        <Stack spacing="120px">
          <TopSectionSp />
          <SecondSectionSp />
          <ThirdSectionSp />
          <ForthSectionSp />
          <FiveSectionSp />
        </Stack>
      ) : (
        <Stack spacing="80px">
          <TopSection />
          <SecondSection />
          <ThirdSection />
          <ForthSection />
          <FiveSection />
        </Stack>
      )}
    </BaseTemplate>
  )
}
