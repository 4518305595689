import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { BaseTemplate } from '@/components/templates/BaseTemplate'
// import { FormSelect } from '@/components/uiParts/common/FormSelect'
import { Video } from '@/components/uiParts/Preview/Video'
// import { DEVICE_KINDS } from '@/constants/common'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { useSettingContext } from '@/contexts/SettingContext'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { useStreamContext } from '@/contexts/StreamContext'
// import { Devices } from '@/types/common'
import { useUseragent } from '@/hooks/common/useUseragent'
import { ParticipantDoc } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'

// [Todo] デバイス情報取得して、音声や再生機能を追加する
export function Preview() {
  const navigate = useNavigate()
  const params = useParams()
  const { participateEvent: event, participants } = useParticipateEventContext()
  const [users, setUsers] = useState<ParticipantDoc[]>([])

  const { browserType } = useUseragent()

  const { snackbar } = useSnackbarContext()
  const { shareScreen, updateSettings } = useSettingContext()

  useEffect(() => {
    setUsers(participants)
  }, [participants])
  // const [deviceOptions, setDeviceOptions] = useState<Devices>({
  //   audioinput: [],
  //   audiooutput: [],
  //   videoinput: []
  // })

  const { canvasLocal, updateLocal } = useStreamContext()

  const form = useForm({ defaultValues: {}, mode: 'all' })

  const transitToEvents = () => {
    navigate('/events/inprogress')
  }
  const joinToEvent = () => {
    navigate(`/events/${params.id}`, { state: { isPrevPagePreview: true } })
  }

  useEffect(() => {
    const isSafari = browserType === 'safari'
    if (shareScreen && browserType && !isSafari) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: false })
        .then((stream) => {
          if (updateLocal) updateLocal(stream)
        })
        .catch(() => {
          if (updateSettings)updateSettings({ is_share_screen: false })
          snackbar({ message: '画面共有をOFFにしました。' })
        })
    }
  }, [shareScreen, browserType])

  // useEffect(() => {
  //   (async () => {
  //     // [todo] 権限承認タイミングで実行したいので、少し早いかも↑ 要確認
  //     // 権限の取得
  //     await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
  //     const devices = await navigator.mediaDevices.enumerateDevices()

  //     const res = DEVICE_KINDS.reduce((acc, kind) => {
  //       const kindDevices: MediaDeviceInfo[] = devices.filter((device) => device.kind === kind)
  //       return { ...acc,
  //         [kind]: kindDevices.map(
  //           (kindDevice) => ({ value: kindDevice.deviceId, label: kindDevice.label })
  //         ) }
  //     }, { audioinput: [], audiooutput: [], videoinput: [] })

  //     setDeviceOptions(res)
  //   })()
  // }, [])

  return (
    <BaseTemplate isFooter={false} isMaxWidth={false}>
      <FormProvider {...form}>
        <Stack
          direction="row"
          spacing="24px"
          sx={{
            padding: '128px 58px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Stack spacing="24px" sx={{ width: '100%', maxWidth: '640px' }}>
            <Video stream={canvasLocal} />
            <Stack direction="row" spacing="16px">
              {/* [Todo] マイク設定追加時にコメントアウトオフ */}
              {/* <FormSelect
                name="mic_hard"
                options={deviceOptions.audioinput || []}
                defaultValue="default"
                sx={{
                  borderRadius: '50px',
                  maxWidth: '240px',
                  flexGrow: '0',
                  '& .MuiInputBase-input': { fontSize: '16px', padding: '8px 24px' }
                }}
                renderValue={(value) => `マイク（${(deviceOptions.audioinput || []).find(
                  (el) => el.value === value
                )?.label}）`}
              /> */}
              {/* <FormSelect
                name="speaker_hard"
                options={deviceOptions.audiooutput || []}
                defaultValue="default"
                sx={{
                  borderRadius: '50px',
                  maxWidth: '240px',
                  '& .MuiInputBase-input': { fontSize: '16px', padding: '8px 24px' }
                }}
                renderValue={(value) => `ヘッドホン（${(deviceOptions.audiooutput || []).find(
                  (el) => el.value === value
                )?.label}）`}
              /> */}
            </Stack>
          </Stack>
          <Stack spacing="40px" sx={{ width: '480px', height: '100%' }}>
            <Typography sx={{ fontSize: '28px', textAlign: 'center' }}>{event?.name || ''}</Typography>
            <Stack spacing="20px">
              <Stack direction="row" spacing="12px" sx={{ justifyContent: 'center' }}>
                {users.map((userEl) => (
                  <AccountCircleIcon width="24px" sx={{ borderRadius: '30px' }} key={userEl?.user_id || ''} />
                ))}
              </Stack>
              {users.length > 0 ? (
                <Stack sx={{ fontSize: '16px', alignItems: 'center' }}>
                  {users.map(({ user }) => `${user ? user.display_name : '???'}さん`).join('、')}
                  がこの通話に参加しています
                </Stack>
              ) : (
                <Stack sx={{ fontSize: '16px', alignItems: 'center' }}>あなた以外にまだ誰も参加していません</Stack>
              )}
            </Stack>
            <Stack direction="row" sx={{ justifyContent: 'center', gap: '8px' }}>
              <Button
                bgColor={(theme) => theme.palette.base.light}
                textColor={(theme) => theme.palette.primary.main}
                isRounded
                isShadow
                sx={{ minWidth: '148px', padding: '8px 32px', fontWeight: 'bold', fontSize: '16px' }}
                onClick={transitToEvents}
              >
                参加しない
              </Button>
              <Button
                bgColor={(theme) => theme.palette.primary.main}
                textColor={(theme) => theme.palette.base.light}
                isRounded
                isShadow
                sx={{ minWidth: '148px', padding: '8px 32px', fontWeight: 'bold', fontSize: '16px' }}
                onClick={joinToEvent}
              >
                今すぐ参加
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </BaseTemplate>
  )
}
