import { Stack } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { TOP_PAGE_LINK } from '@/constants/common'
import { useAuthContext } from '@/contexts/AuthContext'
import { useSettingContext } from '@/contexts/SettingContext'
import { auth } from '@/firebase'

function Loading() {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        marginTop: '-80px'
      }}
    >
      <CircularProgress size="60px" />
    </Stack>
  )
}

export function AnonymousAuthGuard() {
  const location = useLocation()
  const navigate = useNavigate()
  const { loading, isAuthenticated } = useAuthContext()
  const { fetchSettings } = useSettingContext()

  useEffect(() => {
    if (isAuthenticated && auth.currentUser?.emailVerified && fetchSettings) {
      setTimeout(() => {
        fetchSettings()
      }, 3000)
      navigate(TOP_PAGE_LINK)
    }
  }, [isAuthenticated, location.pathname])

  return loading || isAuthenticated ? <Loading /> : <Outlet />
}
