import { Box, Stack, Typography } from '@mui/material'

import { S3_BUCKET_URL } from '@/constants/common'
import useMediaQuery from '@/hooks/common/useMediaQuery'

export function TopSectionSp() {
  const isTablet = useMediaQuery({ variant: 'tablet' })
  return (
    <Stack spacing="48px" sx={{ alignItems: 'center' }}>
      <Stack sx={{ marginTop: '80px', alignItems: 'center' }}>
        <Stack sx={{ maxWidth: '700px' }} spacing="40px">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '40px',
              lineHeight: '44px',
              textAlign: 'center'
            }}
          >
            安定した集中を
            <br />
            好きなだけ。
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '16px',
              lineHeight: '32px'
            }}
          >
            MokuPaで仲間に画面を共有すると
            <br />
            あなたの集中力は驚くほど安定します。
            <br />
            気が散ってもすぐに集中できる環境が、ここにはあります。
          </Box>
        </Stack>
      </Stack>

      {/* リリース時期 */}
      <Stack direction="row" sx={{ justifyContent: 'space-around' }}>
        {!isTablet && (
        <Stack
          sx={{
            minWidth: '278px',
            height: '256px',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <img src={`${S3_BUCKET_URL}/sokosuto_connected.png`} width="245px" height="178px" alt="" />
        </Stack>
        )}

        <Stack spacing="40px" sx={{ maxWidth: '512px', alignItems: 'center' }}>
          <Typography sx={{ color: 'primary.main' }}>
            ※ご利用はPCからお願いいたします💻
          </Typography>
          <Box sx={{ fontSize: '16px', lineHeight: '26px' }}>
            この感動をいち早く体感いただきたいので
            <br />
            メールアドレスをご登録の上、是非試してみてください！🏃
          </Box>
        </Stack>
        {!isTablet && (
        <Stack sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
          <img src={`${S3_BUCKET_URL}/sokosuto_study.png`} width="278px" height="256px" alt="" />
        </Stack>
        )}
      </Stack>
    </Stack>
  )
}
