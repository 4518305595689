import { zodResolver } from '@hookform/resolvers/zod'
import { Stack, Typography } from '@mui/material'
import { sendPasswordResetEmail } from 'firebase/auth'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { E0002 } from '@/constants/messages'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { auth } from '@/firebase'
import { validEmailFormat } from '@/utils/validation/validEmailFormat'
import { BaseTemplate } from '@c/templates/BaseTemplate'
import { Button } from '@c/uiParts/common/Button'
import { FormText } from '@c/uiParts/common/FormText'

const INITIAL_FORM_VALUE = { email: '' }

const formSchema = z.object({
  email: z.string()
    .min(1, { message: '必須入力です。' })
    .email({ message: 'Emailアドレスの形式ではありません。' })
})

export function PasswordReset() {
  const navigate = useNavigate()
  const { snackbar } = useSnackbarContext()

  const form = useForm({
    defaultValues: INITIAL_FORM_VALUE,
    mode: 'all',
    resolver: zodResolver(formSchema)
  })
  const { formState: { isValid } } = form

  const handleSubmit = () => {
    form.handleSubmit(({ email }) => {
      (async () => {
        try {
          await sendPasswordResetEmail(auth, email)
          snackbar({ message: 'メールを送信しました。' })
          navigate('/login')
        } catch (err) {
          console.error('👹', err)
          snackbar({ message: 'メール送信に失敗しました。', severity: 'error' })
        }
      })()
    })()
  }
  const transitToLogin = () => {
    navigate('/login')
  }
  return (
    <BaseTemplate>
      <FormProvider {...form}>
        <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Stack sx={{ width: '400px', minWidth: '312px', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>パスワードリセット</Typography>
            <Stack spacing="20px" sx={{ marginTop: '32px', width: '100%' }}>
              <Typography sx={{ color: 'border.main' }}>
                「メール送信」ボタンをクリック後に、
                <br />
                <strong>メール内のリンク</strong>
                からパスワードを初期化してください。
              </Typography>

              <FormText
                name="email"
                rules={{
                  required: '必須入力です。',
                  validate: (value: string) =>
                    validEmailFormat(value) || E0002('メールアドレス')
                }}
                placeholder="メールアドレス"
                variant="outlined"
                sx={(theme) => ({
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.border.main}!important`
                  }
                })}
              />
              <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Typography
                  onClick={transitToLogin}
                  sx={{
                    fontSize: '12px',
                    color: 'text.secondary',
                    opacity: '0.8',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: '1'
                    }
                  }}
                >
                  ← ログイン画面へ
                </Typography>

                <Button
                  bgColor={(theme) => theme.palette.primary.main}
                  textColor={(theme) => theme.palette.base.main}
                  isShadow
                  sx={{
                    borderRadius: '12px',
                    padding: '8px 32px',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  メール送信
                </Button>
              </Stack>
            </Stack>

          </Stack>
        </Stack>
      </FormProvider>
    </BaseTemplate>
  )
}
