import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { styled, Box, Stack, Typography, CircularProgress } from '@mui/material'
import Card from '@mui/material/Card'
import { differenceInSeconds, format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { JoinedEventDoc } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'
import { Participants } from '@c/uiParts/common/Participants'

type Props = {
  items: JoinedEventDoc
}

const StyledHashTag = styled(Box)(({ theme }) => ({
  // padding: '4px 8px',
  borderRadius: '24px',
  fontSize: '12px',
  fontWeight: 'medium',
  color: theme.palette.text.secondary
}))

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '2px 12px',
  border: `1px solid ${theme.palette.base.dark}`,
  '&:after': {
    content: '"🐕"',
    fontSize: '12px',
    position: 'absolute',
    right: '-8px',
    top: '-12px',
    opacity: '0',
    transition: 'all 0.3s ease-in-out'
  },
  '&:hover:after': {
    opacity: '1',
    right: '4px'
  }
}))

export function EventSummary({ items }: Props) {
  const navigate = useNavigate()
  const [elapsedTime, setElapsedTime] = useState('')

  useEffect(() => {
    const timer = setInterval(() => {
      const diffSec = differenceInSeconds(
        new Date(),
        new Date((items.started_at as Timestamp).toMillis())
      )
      // [Todo] 日本時間の+9されてしまう分の調整
      setElapsedTime(format((diffSec - 9 * 3600) * 1000, 'HH:mm:ss'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const transitToEvent = () => {
    navigate(`/events/${items.id}/preview`)
  }
  return (
    <Card
      sx={(theme) => ({
        width: '200px',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '10px',
        backgroundColor: 'base.light',
        border: `1px solid ${theme.palette.base.dark}}`,
        // hover時に下向きのshadowを強めて浮かせる
        '&.MuiPaper-root': {
          transition: 'all 0.1s ease-in-out',
          '&:hover': {
            boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.3)'
            // transform: 'translate(-2px, -2px)'
          } }
      })}
    >
      <Stack sx={{ justifyContent: 'space-between', height: '100%' }}>
        {/* タイトルとタグ */}
        <Stack sx={{ gap: '2px' }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {items.name}
          </Typography>
          <Stack
            direction="row"
            width="100%"
            sx={{ flexWrap: 'wrap', gap: '0px 4px' }}
          >
            {items.hashtags && items.hashtags.map((hashtag) => (
              <StyledHashTag key={hashtag.id}>
                #
                {hashtag.name}
              </StyledHashTag>
            ))}
          </Stack>
        </Stack>

        {/* 開始時間と終了時間 */}
        <Stack sx={{ mt: '8px' }}>
          {/* 時間体系 */}
          <Stack sx={{ mt: '8px' }} spacing="0px">
            <Typography sx={{
              fontWeight: 'bold',
              fontSize: '12px',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center'
            }}
            >
              <ScheduleIcon sx={{ fontSize: '12px' }} />
              &nbsp;
              {format(new Date((items.start_time as Timestamp).toDate()), 'HH:mm')}
              -
              {format(new Date((items.end_time as Timestamp).toDate()), 'HH:mm')}
            </Typography>
            <Stack
              direction="row"
              sx={{
                whiteSpace: 'nowrap',
                alignItems: 'center',
                minHeight: '24px'
              }}
            >
              {elapsedTime ? (
                <Typography sx={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }}
                >
                  <DirectionsRunIcon sx={{ fontSize: '12px' }} />
                  &nbsp;
                  {elapsedTime}
                </Typography>
              ) : (
                <CircularProgress size={16} />
              )}
            </Stack>
          </Stack>

          <Stack sx={{ mt: '8px' }} spacing="0px">
            <Participants participants={items.participants} />
          </Stack>

          <Stack direction="row" spacing="4px" sx={{ mt: '16px', justifyContent: 'flex-end' }}>
            {/* [Todo] 主催イベントについては、右上に編集アイコンを配置 */}
            <StyledButton
              bgColor={(theme) => theme.palette.accent.main}
              textColor={(theme) => theme.palette.text.disabled}
              onClick={transitToEvent}
            >
              参加
            </StyledButton>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}
