import { EventDoc, HashtagDoc, UserDoc } from '@/types/common'

export const getUserIdsFromEventColumns = (events: EventDoc[]) => events.reduce((acc, cur) => Array.from(new Set(
  [
    ...acc,
    ...cur.participant_id_strings,
    ...cur.reserver_id_strings
  ]
)), [] as string[])

export const joinUserAndHashtagToEvent = (
  { events, users, hashtags } :{events: EventDoc[], users: UserDoc[], hashtags: HashtagDoc[]}
) => events.map((event) => ({
  ...event,
  participants: users.filter((user) => user.id && event.participant_id_strings.includes(user.id)),
  reservers: users.filter((user) => user.id && event.reserver_id_strings.includes(user.id)),
  hashtags: hashtags.filter((hashtag) => hashtag.id && event.hashtag_ids.includes(hashtag.id))
}))
