import { styled, Box, Stack, Typography } from '@mui/material'
import { serverTimestamp } from 'firebase/firestore'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { E0002 } from '@/constants/messages'
import { useAuthContext } from '@/contexts/AuthContext'
import { validEmailFormat } from '@/utils/validation/validEmailFormat'
import { validPasswordFormat } from '@/utils/validation/validPasswordFormat'
import { Button } from '@c/uiParts/common/Button'
import { FormCheckbox } from '@c/uiParts/common/FormCheckbox'
import { FormText } from '@c/uiParts/common/FormText'

const INITIAL_FORM_VALUE = {
  name: '',
  email: '',
  password: '',
  agree_policy: false,
  accept_update_email: false
}

const StyledPasswordConditionUl = styled('ul')(({ theme }) => ({
  paddingLeft: '12px',
  fontSize: '12px',
  color: theme.palette.text.secondary
}))

export function EmailSignup() {
  const form = useForm({ defaultValues: INITIAL_FORM_VALUE, mode: 'all' })
  const { signUp } = useAuthContext()
  const handleSubmit = () => {
    form.handleSubmit(({ email, password, name, accept_update_email }) => {
      if (signUp) {
        signUp(
          email,
          password,
          { displayName: name },
          {
            is_recieve_update_email: accept_update_email,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp()
          }
        )
      }
    })()
  }
  return (
    <FormProvider {...form}>
      <Box>
        <Typography
          sx={(theme) => ({
            fontSize: '20px',
            color: theme.palette.text.secondary
          })}
        >
          Eメールアドレスで登録
        </Typography>
        <Stack spacing="20px" sx={{ marginTop: '32px', width: '100%' }}>
          <FormText
            name="email"
            rules={{
              required: '必須入力です。',
              validate: (value: string) => validEmailFormat(value) || E0002('メールアドレス')
            }}
            placeholder="メールアドレス"
            autoComplete="email"
            variant="outlined"
            sx={(theme) => ({
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.border.main}!important`
              }
            })}
          />
          <Stack spacing="4px">
            <FormText
              name="password"
              rules={{
                required: '必須入力です。',
                validate: {
                  passFormat: (value: string) => validPasswordFormat(value)
                }
              }}
              placeholder="パスワード"
              autoComplete="new-password"
              type="password"
              variant="outlined"
              sx={(theme) => ({
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${theme.palette.border.main}!important`
                }
              })}
            />
            <StyledPasswordConditionUl>
              <li>8文字以上</li>
              <li>大文字 / 小文字 / 数字 / 特殊文字を各1文字以上</li>
            </StyledPasswordConditionUl>
          </Stack>
          <FormText
            name="name"
            rules={{
              required: '必須入力です。'
            }}
            placeholder="ニックネーム"
            variant="outlined"
            sx={(theme) => ({
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.border.main}!important`
              }
            })}
          />

          <FormCheckbox
            name="agree_policy"
            rules={{ required: 'プライバシーポリシーをご確認ください' }}
            labelElement={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography sx={{ fontSize: '14px' }}>
                <Link to="/privacy" target="_blank">
                  プライバシーポリシー
                </Link>
                に同意する
              </Typography>
            }
          />
          <FormCheckbox
            name="accept_update_email"
            rules={{}}
            labelElement={<Typography sx={{ fontSize: '14px' }}>定期的な製品アップデートの受信に同意する</Typography>}
          />
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isShadow
            sx={{
              borderRadius: '12px',
              padding: '8px 32px',
              fontWeight: 'bold',
              fontSize: '16px'
            }}
            onClick={handleSubmit}
          >
            登録
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  )
}
