import { Stack, Typography } from '@mui/material'
import { addDays } from 'date-fns'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'

import { BaseTemplate } from '@c/templates/BaseTemplate'
import { Button } from '@c/uiParts/common/Button'

const capitalize = (str: string) => {
  if (typeof str !== 'string' || !str) return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function NotSupported() {
  const navigate = useNavigate()
  const location = useLocation()
  const uaName = capitalize(location.state?.browserType || '???')
  const [cookies, setCookie] = useCookies()

  const handleClick = () => {
    const cookieDate = addDays(new Date(), 1)
    setCookie('not_supported', 'true', { expires: cookieDate, path: '/' })
    navigate('/')
  }

  useEffect(() => {
    if (cookies?.not_supported && cookies.not_supported === 'true') {
      navigate('/')
    }
  }, [])
  return (
    <BaseTemplate>
      <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Stack spacing="32px" sx={{ width: '400px', minWidth: '312px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>⚠️ Warning!</Typography>
          <Stack spacing="20px" sx={{ marginTop: '32px', width: '100%' }}>
            <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              MokuPa on
              {' '}
              {uaName}
              は現在beta版です。
            </Typography>
            <Typography sx={{ color: 'border.main' }}>
              {uaName}
              でご利用いただくと、一部機能で意図しない動作が発生する可能性がございます。
              <br />
              <br />
              そのため、運営チームからは
              <br />
              <span style={{ fontWeight: 'bold' }}>
                「Google Chrome」か「Firefox」
                でのご利用を推奨
              </span>
              させていただいております。
              <br />
              <br />
            </Typography>
          </Stack>
          <Button
            onClick={handleClick}
            bgColor={(theme) => theme.palette.button.primary}
            textColor={(theme) => theme.palette.base.light}
            isShadow
            sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 32px' }}
          >
            問題ない、このブラウザで利用開始する。
          </Button>
        </Stack>
      </Stack>
    </BaseTemplate>
  )
}
