import CloseIcon from '@mui/icons-material/Close'
import {
  Box, Stack,
  Typography, TextField,
  Dialog, DialogTitle, DialogActions, DialogContent
} from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'

import { Button } from '@/components/uiParts/common/Button'
import { useAuthContext } from '@/contexts/AuthContext'
import { useParticipateEventContext } from '@/contexts/ParticipateEvent'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { useEvents } from '@/hooks/firestore/useEvents'

type Props = {
  text: string
  clearText: () => void
}

export function SubmitDialog({ text, clearText }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false)

  const { updateEvent } = useEvents()
  const { participateEvent: event, eventId } = useParticipateEventContext()
  const { user } = useAuthContext()
  const { snackbar } = useSnackbarContext()

  const handleClickClose = () => {
    setDialogOpen(false)
  }
  const sendAnnounce = async () => {
    if (eventId && event && user) {
      const { id, ...idRemovedEvent } = event

      try {
        updateEvent(eventId, {
          ...idRemovedEvent,
          announces: [
            ...(event.announces || []),
            {
              id: uuid(),
              body: text,
              created_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              created_by: user.uid
            }
          ] })
        snackbar({ message: 'アナウンスを送信しました。' })
        clearText()
      } catch (err) {
        console.error(err)
        snackbar({ message: 'アナウンスの送信に失敗しました。', severity: 'error' })
      }
      setDialogOpen(false)
    }
  }

  return (
    <Box>
      <Button
        bgColor={(theme) => theme.palette.primary.main}
        textColor={(theme) => theme.palette.base.main}
        isRounded
        isShadow
        sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px', width: '100%' }}
        onClick={() => { setDialogOpen(true) }}
      >
        全体アナウンス
      </Button>

      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        sx={{ '& .MuiDialog-paperScrollPaper': {
          overflowY: 'visible',
          maxWidth: 'none',
          minWidth: '400px'
        } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 24px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          })}
        >
          📢 全体アナウンス
          <CloseIcon
            sx={{
              width: '32px', height: '32px', cursor: 'pointer' }}
            onClick={handleClickClose}
          />
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'visible', marginTop: '24px', padding: '0 24px' }}>
          <Stack spacing="20px" sx={{ minHeight: '72px' }}>
            <Typography sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontSize: '12px',
              color: 'text.primary'
            }}
            >
              {'※ こちらのメッセージはすべての参加者に送信されます。\n誤って送信されないようご注意ください。'}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '12px 20px'
          })}
        >
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isRounded
            isShadow
            sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
            onClick={sendAnnounce}
          >
            メッセージ送信
          </Button>
        </DialogActions>

      </Dialog>

    </Box>
  )
}

export function MessageBox() {
  const [text, setText] = useState('')
  const handleChangeText = (e: any) => {
    setText(e.target.value)
  }

  return (
    <Stack spacing="8px">
      <TextField
        value={text}
        onChange={handleChangeText}
        maxRows="4"
        minRows="4"
        multiline
        placeholder="メッセージを入力ください"
        sx={{ '& .MuiInputBase-root': { padding: '8px 12px', backgroundColor: 'base.main' } }}
      />
      <SubmitDialog text={text} clearText={() => { setText('') }} />
    </Stack>

  )
}
