import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import { Box, Stack, styled } from '@mui/material'

import { S3_BUCKET_URL } from '@/constants/common'

const StyledCard = styled(Stack)({
  maxWidth: '600px',
  backgroundColor: 'white',
  boxShadow: 'rgba(0,0,0,0.08) 0px 4px 20px 0px',
  borderRadius: '32px',
  padding: '40px 20px'
})

const StyledImgContainer = styled(Box)({
  padding: '0'
})

const StyledHeaderText = styled(Box)({
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '40px'
})

const StyledBodyText = styled(Box)({
  fontSize: '16px',
  lineHeight: '30px'
})

// eslint-disable-next-line import/prefer-default-export, react/function-component-definition
export const ThirdSectionSp = () => (
  <Stack spacing="16px" sx={{ alignItems: 'center' }}>
    {/* HeaderText */}
    <Stack direction="row" spacing="10px" sx={{ alignItems: 'center' }}>
      <SchoolOutlinedIcon sx={{ fontSize: '24px' }} />
      <Box sx={{ fontSize: '20px', lineHeight: '52px', fontWeight: 'bold' }}>
        人の集中には2つの要素が必要
      </Box>
    </Stack>

    {/* BodyCards */}
    <Stack
      spacing="32px"
      sx={{ justifyContent: 'space-between', maxWidth: '1000px' }}
    >
      <StyledCard spacing="40px">
        <StyledImgContainer>
          <img src={`${S3_BUCKET_URL}/run_in_forest.png`} width="100%" alt="" />
        </StyledImgContainer>
        <Stack spacing="10px">
          <StyledHeaderText>
            走り続けるのに余計な物の無い環境
          </StyledHeaderText>
          <StyledBodyText>
            人に見られていれば、YouTubeやInstagramなどの注意を散漫にさせるものを見ないように気をつけることができます。
          </StyledBodyText>
        </Stack>
      </StyledCard>

      <StyledCard spacing="40px">
        <StyledImgContainer>
          <img src={`${S3_BUCKET_URL}/prepare_running.png`} width="100%" alt="" />
        </StyledImgContainer>
        <Stack spacing="10px">
          <StyledHeaderText>いつでも何度でも走り出せる</StyledHeaderText>
          <Stack spacing="10px">
            <StyledBodyText>
              人間の集中力は20分程度しか保ちません。
              <br />
              だから、いつでももう一度集中するための環境や理性を整えることが重要です！
            </StyledBodyText>
            <StyledBodyText>
              だからMokuPaではお互いの動向を見守り合えます。
            </StyledBodyText>
            {/* <StyledBodyText>
                科学的にも「自分を見ている人数が多いほど、自分の行動がより意義深いものに感じてモチベーションが向上する」ことがわかっています。
                <br />
                （SocialFacilitation研究論文より）
              </StyledBodyText> */}
          </Stack>
        </Stack>
      </StyledCard>
    </Stack>
  </Stack>
)
