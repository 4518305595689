import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { Box, Stack } from '@mui/material'

import { ShareScreen } from '@/components/uiParts/Events/EventView/ShareScreen'
import { MY_PEER_ID, SCREEN_MODE } from '@/constants/common'
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '@/constants/styles'
import { useScreenModeContext } from '@/contexts/ScreenModeContext'

type Props = {
  remoteStreams: MediaStream[]
  canvasLocalStream: MediaStream | null
}

export function GridUI({
  remoteStreams,
  canvasLocalStream
}: Props) {
  const { updateMode, updateSelectedScreenId } = useScreenModeContext()

  const zoomScreen = (stream: any) => {
    updateMode(SCREEN_MODE.stage)
    updateSelectedScreenId(stream?.peerId || MY_PEER_ID)
  }
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        maxWidth: '880px',
        maxHeight: 'calc(100vh - 236px)',
        overflowY: 'auto',
        paddingRight: '20px',
        gap: '32px',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      <Box sx={{ minWidth: `${SCREEN_WIDTH}px`, width: `${SCREEN_WIDTH}px` }}>
        <ShareScreen
          width={SCREEN_WIDTH}
          height={SCREEN_HEIGHT}
          key="myscreen"
          stream={canvasLocalStream}
          onClickScreen={() => zoomScreen(canvasLocalStream)}
          hoverIcon={<ZoomOutMapIcon />}
        />
      </Box>
      {remoteStreams.map((stream, i) => (
        <Box
          key={(stream as any).peerId || i}
          sx={{ minWidth: `${SCREEN_WIDTH}px`, width: `${SCREEN_WIDTH}px` }}
        >
          <ShareScreen
            stream={stream}
            mode="remote"
            width={SCREEN_WIDTH}
            height={SCREEN_HEIGHT}
            onClickScreen={() => zoomScreen(stream)}
            hoverIcon={<ZoomOutMapIcon />}
          />
        </Box>
      ))}
    </Stack>

  )
}
