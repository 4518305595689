import { zodResolver } from '@hookform/resolvers/zod'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog, DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  BoxProps
} from '@mui/material'
import { isAfter, parse } from 'date-fns'
import { cloneElement, useState, useEffect, ReactElement } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import * as z from 'zod'

import { E0001, E0003, E0004, E0005 } from '@/constants/messages'
import { useSnackbarContext } from '@/contexts/Snackbar'
import { useEvents } from '@/hooks/firestore/useEvents'
import { normalizeEventToForm } from '@/hooks/firestore/useEvents/normalizeEventToForm'
import { EventDoc, EventForm } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'
import { FormDateTime } from '@c/uiParts/common/FormDateTime'
import { FormText } from '@c/uiParts/common/FormText'
import { DialogFormWithLabel } from '@c/uiParts/Events/DialogFormWithLabel'
import { SelectHashTag } from '@c/uiParts/Events/SelectHashTag'

type Props = Pick<BoxProps, 'sx'> & {
  titleText: string
  submitText: string
  onSubmit: (
    form: UseFormReturn<EventForm, any>,
    handleComplete: () => void,
    handleError: () => void
  ) => Promise<void>
  onComplete?: (id?: string) => void
  eventItem?: EventDoc
  isReserve?: boolean
  children: ReactElement
}

export function EventStartDialog({
  titleText,
  submitText,
  onSubmit,
  onComplete,
  eventItem,
  isReserve = false,
  children,
  ...rest
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { INITIAL_FORM_VALUES } = useEvents()

  const { snackbar } = useSnackbarContext()

  const isCreate = !eventItem

  const formSchema = z
    .object({
      event_name: z.string().min(1, E0001('イベント名')),
      event_start_time: z.string(),
      event_end_time: z.string(),
      event_capacity: z.string()
        .refine(
          (val) => Number(val) <= 10,
          { message: E0003('上限人数', 10) }
        )
        .refine(
          (val) => Number(val) > 0,
          { message: E0005('上限人数', 1) }
        ),
      event_public: z.boolean(),
      forbid_voice: z.boolean(),
      hashtags: z.array(z.string()),
      hashtagText: z.string(),
      event_started_at: z.string(),
      event_finished_at: z.string(),
      participant_ids: z.array(z.string()),
      reserver_ids: z.array(z.string()),
      announces: z.array(z.any())
    })
    .refine(
      ({ event_start_time, event_end_time }) => {
        const startTime = parse(event_start_time, "yyyy-MM-dd'T'HH:mm", new Date())
        const endTime = parse(event_end_time, "yyyy-MM-dd'T'HH:mm", new Date())
        return isAfter(endTime, startTime)
      },
      {
        message: E0004('終了日時', '開始日時'),
        path: ['event_end_time']
      }
    )

  const form = useForm({
    defaultValues: INITIAL_FORM_VALUES,
    mode: 'all',
    resolver: zodResolver(formSchema)
  })
  const { reset } = form
  useEffect(() => {
    if (eventItem) {
      reset(normalizeEventToForm(eventItem))
    } else {
      reset(INITIAL_FORM_VALUES)
    }
  }, [dialogOpen])

  const handleClickClose = () => {
    setDialogOpen(false)
  }

  const dialogOpenButton = cloneElement(children, {
    onClick: () => { setDialogOpen(true) }
  })

  const handleComplete = (id?: string) => {
    setDialogOpen(false)
    if (onComplete) {
      if (isReserve) {
        onComplete()
      } else {
        onComplete(id)
      }
    }
    snackbar({ message: `イベントを${isCreate ? '作成' : '更新'}しました。`, severity: 'success' })
  }
  const handleError = () => {
    snackbar({ message: `イベントの${isCreate ? '作成' : '更新'}に失敗しました。`, severity: 'error' })
  }
  const handleSubmit = async () => {
    await onSubmit(form, handleComplete, handleError)
  }

  return (
    <Box {...rest}>
      <FormProvider {...form}>
        {dialogOpenButton}
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .MuiDialog-paperScrollPaper': {
              overflowY: 'visible',
              maxWidth: 'none'
            }
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={(theme) => ({
              backgroundColor: theme.palette.base.light,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 24px',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
            })}
          >
            {titleText}
            <MuiButton disableRipple sx={{ color: 'text.primary' }} onClick={handleClickClose}>
              <CloseIcon
                sx={{
                  width: '32px',
                  height: '32px'
                }}
              />
            </MuiButton>
          </DialogTitle>
          <DialogContent sx={{ overflowY: 'visible', padding: '20px 72px', marginTop: '24px' }}>
            <DialogFormWithLabel label="イベント名">
              <FormText
                name="event_name"
                placeholder="フロントエンドもくもく会"
                variant="outlined"
                rules={{ }}
              />
            </DialogFormWithLabel>
            <DialogFormWithLabel required label="ハッシュタグ">
              <SelectHashTag />
            </DialogFormWithLabel>
            {isReserve ? (
              <>
                <DialogFormWithLabel label="イベント開始日時">
                  <FormDateTime name="event_start_time" variant="outlined" rules={{}} />
                </DialogFormWithLabel>
                <DialogFormWithLabel label="イベント終了日時">
                  <FormDateTime name="event_end_time" variant="outlined" rules={{}} />
                </DialogFormWithLabel>
              </>
            ) : null}
            {/* <DialogFormWithLabel label="上限人数">
              <FormText
                name="event_capacity"
                type="number"
                variant="outlined"
                rules={{ }}
              />
            </DialogFormWithLabel> */}
            {/* <DialogFormWithLabel label="イベントを公開">
              <FormCheckbox name="event_public" rules={{}} labelElement="※ONにすると全ての人が参加可能になります" />
            </DialogFormWithLabel> */}
            {/* <DialogFormWithLabel label="ボイス禁止">
              <FormCheckbox name="forbid_voice" rules={{}} labelElement={null} />
            </DialogFormWithLabel> */}
          </DialogContent>
          <DialogActions
            sx={(theme) => ({
              backgroundColor: theme.palette.base.light,
              boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.14)',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '12px 20px'
            })}
          >
            <Button
              bgColor={(theme) => theme.palette.primary.main}
              textColor={(theme) => theme.palette.base.main}
              isRounded
              isShadow
              sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
              onClick={handleSubmit}
            >
              {submitText}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </Box>
  )
}
