import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, BoxProps } from '@mui/material'
import React, { useState } from 'react'

import { useSnackbarContext } from '@/contexts/Snackbar'
import { useEvents } from '@/hooks/firestore/useEvents'
import { Button } from '@c/uiParts/common/Button'

type Props = BoxProps & {id?: string, onComplete: () => void}

export function DeleteButton({ id, onComplete, ...rest }: Props) {
  const { deleteEvent } = useEvents()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { snackbar } = useSnackbarContext()

  const handleClickOpen: React.MouseEventHandler<SVGSVGElement> | undefined = (e) => {
    e.stopPropagation()
    setDialogOpen(true)
  }
  const handleClickClose = (e: any) => {
    e.stopPropagation()
    setDialogOpen(false)
  }

  const handleDelete = async (e: any) => {
    e.stopPropagation()
    if (!id) return
    try {
      await deleteEvent(id)
      setDialogOpen(false)
      onComplete()
    } catch (err) {
      snackbar({ message: '削除に失敗しました。', severity: 'error' })
      console.log('👹', err)
    }
  }

  return (
    <Box {...rest}>
      <CloseIcon
        onClick={handleClickOpen}
        sx={(theme) => ({
          cursor: 'pointer',
          backgroundColor: theme.palette.base.light,
          opacity: '0.5',
          fontSize: '14px',
          '&:hover': {
            opacity: '1'
          } })}
      />
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        sx={{
          '& .MuiDialog-paperScrollPaper': {
            overflowY: 'visible',
            maxWidth: 'none'
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 24px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          })}
        >
          イベント削除
          <CloseIcon
            sx={{
              width: '32px',
              height: '32px',
              cursor: 'pointer'
            }}
            onClick={handleClickClose}
          />
        </DialogTitle>

        <DialogContent sx={{ overflowY: 'visible', padding: '20px 32px', marginTop: '24px' }}>
          イベントを削除すると復元できませんが、
          <br />
          削除してよろしいですか。
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            backgroundColor: theme.palette.base.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '12px 20px'
          })}
        >
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.main}
            isRounded
            isShadow
            sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
            onClick={handleDelete}
          >
            削除
          </Button>
        </DialogActions>

      </Dialog>

    </Box>
  )
}
