import { zodResolver } from '@hookform/resolvers/zod'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Box, Stack, Typography } from '@mui/material'
import { serverTimestamp } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { useAuthContext } from '@/contexts/AuthContext'
import { useUsers } from '@/hooks/firestore/useUsers'
import { UserDoc } from '@/types/common'
import { BaseTemplate } from '@c/templates/BaseTemplate'
import { Button } from '@c/uiParts/common/Button'
import { FormText } from '@c/uiParts/common/FormText'

// mypage
export function MyPage() {
  const isFirstRef = useRef(true)

  const { user: authUser, updateProfile } = useAuthContext()
  const [user, setUser] = useState<UserDoc | null>(null)

  const { getUser, updateUser } = useUsers()

  const formSchema = z.object({
    display_name: z.string().min(1, { message: '必須入力です' }),
    introduce: z.string()
  })
  const form = useForm({
    defaultValues: { display_name: '', introduce: '' },
    mode: 'all',
    resolver: zodResolver(formSchema)
  })
  const { reset } = form

  const handleSubmit = form.handleSubmit(({ display_name, introduce }) => {
    if (updateProfile)updateProfile({ displayName: display_name })
    if (user) {
      updateUser(authUser?.uid || '', {
        ...user,
        introduce,
        display_name,
        updated_at: serverTimestamp()
      })
    }
  })

  useEffect(() => {
    (async () => {
      if (isFirstRef.current && authUser) {
        const res = await getUser(authUser.uid)
        setUser(res || null)
        reset({
          display_name: res?.display_name || '',
          introduce: res?.introduce || ''
        })
        isFirstRef.current = false
      }
    })()
  }, [authUser])

  return (
    <BaseTemplate
      isFooter={false}
      isMaxWidth={false}
      sx={{
        width: '100vw',
        maxWidth: '1000px'

      }}
    >
      <FormProvider {...form}>
        <Stack
          spacing="48px"
          sx={{
            padding: '32px 16px'
          }}
        >
          <Typography sx={(theme) => ({
            fontSize: '24px',
            fontWeight: 'bold',
            lineHieght: '1.5',
            borderBottom: `1px solid ${theme.palette.border.main}`
          })}
          >
            {user?.display_name || ''}
            のページ
          </Typography>
          <Stack
            spacing="16px"
            sx={{
              width: '520px',
              alignItems: 'center'
            }}
          >
            <AccountCircleOutlinedIcon
              sx={{ width: '72px', height: '72px' }}
            />
            <Stack sx={{ padding: '0 40px', width: '100%' }}>
              <Stack direction="row" spacing="8px" sx={{ alignItems: 'center', padding: '8px' }}>
                <Box sx={{ width: '120px' }}>名前</Box>
                <FormText
                  name="display_name"
                  placeholder="表示名を入力ください"
                  variant="outlined"
                  rules={{ }}
                />
              </Stack>
              <Stack direction="row" spacing="8px" sx={{ alignItems: 'center', padding: '8px' }}>
                <Box sx={{ width: '120px' }}>自己紹介</Box>
                <FormText
                  name="introduce"
                  placeholder="自己紹介を入力ください"
                  variant="outlined"
                  rules={{ }}
                  multiline
                  rows={5}
                />
              </Stack>
            </Stack>
            <Stack sx={{ paddingTop: '64px', width: '100%', alignItems: 'flex-end' }}>
              <Button
                bgColor={(theme) => theme.palette.primary.main}
                textColor={(theme) => theme.palette.base.main}
                isRounded
                isShadow
                sx={{ fontSize: '14px', fontWeight: 'bold', padding: '8px 12px' }}
                onClick={handleSubmit}
              >
                プロフィール更新
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </BaseTemplate>
  )
}
