import { Box, styled, Link } from '@mui/material'

import MokupaIcon from '@/assets/mokupa.svg'

const Continer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  width: '100vw',
  position: 'fixed',
  top: '0',
  padding: '16px 24px',
  filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
  zIndex: '99'
})

export function HeaderSp() {
  return (
    <Continer>
      <Link href="/" sx={{ display: 'inline-flex' }}>
        <img src={MokupaIcon} alt="mokupa icon" width="100px" height="32px" />
      </Link>
    </Continer>
  )
}
