import { ScreenModeTypes } from '@/types/common'

export const S3_BUCKET_URL = 'https://totoinu.s3.ap-northeast-1.amazonaws.com/mokupa'

export const FORCE_EXIT_LIMIT_TIME = 30

export const SNACKBAR_CLOSE_TIME = 15

export const TOP_PAGE_LINK = '/events/inprogress'

export const DEVICE_KINDS = ['audioinput', 'audiooutput', 'videoinput'] as const

export const INITIAL_USER_DOC = {
  introduce: '',
  is_recieve_update_email: false,
  is_share_screen: false,
  is_voice_on: false,
  screen_quality: 'high'
}

export const SCREEN_QUALITY_OPTIONS = {
  LAW: { value: 'law', label: 'ぼかし（強）' },
  MID: { value: 'mid', label: 'ぼかし（弱）' },
  HIGH: { value: 'high', label: '加工無し' }
} as const

export const SCREEN_MODE: Record<ScreenModeTypes, ScreenModeTypes> = {
  grid: 'grid',
  stage: 'stage',
  rotation: 'rotation'
}

export const MY_PEER_ID = undefined
