/* eslint-disable import/no-duplicates */
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { styled, Box, Stack, Typography, Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import { addDays, format, isBefore } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

import { EventStartDialog } from '@/components/uiParts/Events/EventStartDialog'
import { useEvents } from '@/hooks/firestore/useEvents'
import { JoinedEventDoc } from '@/types/common'
import { Button } from '@c/uiParts/common/Button'
import { Participants } from '@c/uiParts/common/Participants'
import { DeleteButton } from '@c/uiParts/Events/ManagedEventSummary/DeleteButton'

type Props ={
  items: JoinedEventDoc
  refetchEvent: () => Promise<void>
}

const StyledHashTag = styled(Box)(({ theme }) => ({
  borderRadius: '24px',
  fontSize: '12px',
  fontWeight: 'medium',
  color: theme.palette.text.secondary
}))

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '2px 12px',
  border: `1px solid ${theme.palette.base.dark}`,
  '&:after': {
    content: '"🐕"',
    fontSize: '12px',
    position: 'absolute',
    right: '-8px',
    top: '-12px',
    opacity: '0',
    transition: 'all 0.3s ease-in-out'
  },
  '&:hover:after': {
    opacity: '1',
    right: '4px'
  }
}))

export function ManagedEventSummary({ items, refetchEvent }: Props) {
  const navigate = useNavigate()
  const { submitUpdateEventForm } = useEvents()

  const transitToEvent = () => {
    navigate(`/events/${items.id}/preview`)
  }

  return (
    <Card
      sx={(theme) => ({
        position: 'relative',
        width: '200px',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '10px',
        backgroundColor: 'base.light',
        border: `1px solid ${theme.palette.base.dark}}`,
        '&.MuiPaper-root': {
          transition: 'all 0.1s ease-in-out',
          '&:hover': {
            boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.3)'
          } }
      })}
    >

      <DeleteButton
        id={items.id}
        onComplete={refetchEvent}
        sx={{ position: 'absolute', top: '10px', right: '10px' }}
      />
      <EventStartDialog
        titleText="イベント更新"
        submitText="イベントを更新"
        onSubmit={submitUpdateEventForm(items.created_at, items.id)}
        onComplete={refetchEvent}
        eventItem={items}
        isReserve
        sx={{ height: '100%' }}
      >

        <Stack sx={{ justifyContent: 'space-between', height: '100%' }}>
          {/* タイトルとタグ */}
          <Stack sx={{ gap: '2px' }}>
            <Typography sx={{ fontWeight: 'bold', pr: '16px' }}>
              {items.name}
            </Typography>
            <Stack
              direction="row"
              width="100%"
              sx={{ flexWrap: 'wrap', gap: '0px 4px' }}
            >
              {items.hashtags && items.hashtags.map((hashtag) => (
                <StyledHashTag key={hashtag.id}>
                  #
                  {hashtag.name}
                </StyledHashTag>
              ))}
            </Stack>
          </Stack>

          {/* 開始時間と終了時間 */}
          <Stack sx={{ mt: '8px' }}>
            {/* 時間体系 */}
            <Stack sx={{ mt: '8px' }} spacing="0px">
              <Typography sx={{
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center'
              }}
              >
                <ScheduleIcon sx={{ fontSize: '12px' }} />
                &nbsp;
                {format(new Date((items.start_time as Timestamp).toDate()), 'HH:mm')}
                -
                {format(new Date((items.end_time as Timestamp).toDate()), 'HH:mm')}
              </Typography>
            </Stack>

            <Stack sx={{ mt: '8px' }} spacing="0px">
              <Participants participants={items.participants} />
            </Stack>

            <Stack direction="row" spacing="4px" sx={{ mt: '16px', justifyContent: 'flex-end' }}>
              {/* event終了日時が入力されていて、かつ、event終了日時から24時間以上経過している場合は、終了というクリックできないグレーボタンを表示する。 */}
              {/* date-fnsを使用して日時は計算する */}
              {items.finished_at && isBefore(
                new Date((items.finished_at as Timestamp).toDate()),
                addDays(new Date(Date.now()), -1)
              ) ? (
                <Stack direction="row" spacing="8px" justifyContent="center" alignItems="center">
                  <StyledButton
                    bgColor={(theme) => theme.palette.base.light}
                    textColor={(theme) => theme.palette.text.disabled}
                    disabled
                  >
                    終了
                  </StyledButton>
                  {/* tooltipを使用して、hoverすると「終了から1日以上経過したイベントは開始できません」と表示する。 */}
                  <Tooltip title="終了から1日以上経過したイベントは再開できません" arrow>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: '2px',
                        backgroundColor: 'text.primary',
                        opacity: '0.5',
                        borderRadius: '30px',
                        cursor: 'pointer' }}
                    >
                      <QuestionMarkIcon sx={{ fontSize: '10px', color: 'base.light' }} />
                    </Stack>
                  </Tooltip>

                </Stack>
                )
                : (
                  <StyledButton
                    bgColor={(theme) => theme.palette.accent.main}
                    textColor={(theme) => theme.palette.text.disabled}
                    onClick={transitToEvent}
                  >
                    参加する
                  </StyledButton>
                )}
            </Stack>
          </Stack>
        </Stack>
      </EventStartDialog>
    </Card>
  )
}
