/* eslint-disable consistent-return */
import { useEffect, useCallback, useRef, useState } from 'react'

export const useAnimationFrame = (callback: () => void) => {
  const requestRef = useRef<ReturnType<typeof requestAnimationFrame>>()
  const [tabHidden, setTabHidden] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      setTabHidden(document.hidden)
    }, 100)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (tabHidden) {
      callback()
      const timer = setInterval(() => {
        callback()
      }, 100)
      return () => clearInterval(timer)
    }
  }, [callback, tabHidden])

  // callback関数に変更があった場合のみanimateを再生成する
  const animate = useCallback(() => {
    callback()
    requestRef.current = requestAnimationFrame(animate)
  }, [callback])

  // callback関数に変更があった場合は一度破棄して再度呼び出す
  useEffect(() => {
    if (!tabHidden) {
      requestRef.current = requestAnimationFrame(animate)
      return () => {
        if (requestRef.current) {
          return cancelAnimationFrame(requestRef.current)
        }
      }
    }
  }, [animate, tabHidden])
}
