import { Box, Stack, StackProps, styled } from '@mui/material'

const StyledContainer = styled(Stack)(({ theme }) => ({
  width: '28px',
  height: '28px',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '4px',
  opacity: '0.8',
  cursor: 'pointer',
  transition: 'all 0.1s',
  border: `1px solid ${theme.palette.base.main}`,
  borderRadius: '2px',
  '&:hover': {
    opacity: '1',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px'
  }
}))

const StyledBar = styled(Box)(({ theme }) => ({
  height: '2px',
  width: '100%',
  backgroundColor: theme.palette.base.main,
  borderRadius: '2px'
}))

type Props = StackProps & {}

export function HamburgerIcon({ ...rest }: Props) {
  return (
    <StyledContainer {...rest}>
      {[1, 2, 3].map((el) => (
        <StyledBar key={el} />
      ))}
    </StyledContainer>
  )
}
