import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import { Box } from '@mui/material'

import { useEmojiReactionContext } from '@/contexts/Events/EventView/EmojiReactionContext'

export function EmojiReaction({ peerId }: {peerId: string}) {
  const { updateIsOpenPicker, updateTargetUser } = useEmojiReactionContext()
  return (
    <Box>
      <InsertEmoticonIcon onClick={() => {
        updateIsOpenPicker(true)
        updateTargetUser(peerId)
      }}
      />
    </Box>
  )
}
