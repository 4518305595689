import { addDoc, collection, CollectionReference, getDocs } from 'firebase/firestore'

import { db } from '@/firebase'
import { HashtagDoc } from '@/types/common'

export const useHashtags = () => {
  // [Todo] SnackbarやContextが使えるので、この中にユースケース毎の処理追加
  const createHashtag = async (data: HashtagDoc) => {
    const hashtagColRef = collection(db, 'hashtags')
    const newDoc = await addDoc(hashtagColRef, data)
    return newDoc
  }

  const listHashtags = async () => {
    const hashtagColRef = collection(db, 'hashtags') as CollectionReference<HashtagDoc>
    const querySnapshot = await getDocs(hashtagColRef)
    return querySnapshot.docs
      .map((resdoc) => ({ ...resdoc.data(), id: resdoc.id }))
  }

  return {
    createHashtag,
    listHashtags
  }
}
