import { styled, Stack } from '@mui/material'

export const StyledVideoContainer = styled(Stack)({
  position: 'relative',
  flexGrow: '1',
  maxWidth: '800px'
})

export const StyledVideoActionContainer = styled(Stack)({
  position: 'absolute',
  bottom: '0',
  width: '100%',
  height: '80px',
  background:
    'linear-gradient(180deg, rgba(31, 31, 31, 0.0156) 0%, rgba(17, 17, 17, 0.1092) 7.81%, rgba(0, 0, 0, 0.26) 100%)',
  alignItems: 'center',
  padding: '0 16px'
})
