import { Box, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { S3_BUCKET_URL } from '@/constants/common'
import useMediaQuery from '@/hooks/common/useMediaQuery'
import { Button } from '@c/uiParts/common/Button'

export function FiveSection() {
  const navigate = useNavigate()
  const isTablet = useMediaQuery({ variant: 'tablet' })
  const transitToSignup = () => {
    navigate('/signup')
  }

  return (
    <Stack spacing="48px" sx={{ alignItems: 'center', minHeight: '400px' }}>
      {/* リリース時期 */}
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-around', width: '100vw' }}
      >
        {!isTablet && (
          <Stack
            sx={{
              minWidth: '278px',
              height: '256px',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <img src={`${S3_BUCKET_URL}/sokosuto_connected.png`} width="245px" height="178px" alt="" />
          </Stack>
        )}

        <Stack spacing="32px" sx={{ maxWidth: '512px', alignItems: 'center' }}>
          <Box sx={{ fontSize: '16px', lineHeight: '26px' }}>
            この感動をいち早く体感いただきたいので
            <br />
            メールアドレスをご登録の上、️是非試してみてください！🏃
          </Box>
          <Button
            bgColor={(theme) => theme.palette.primary.main}
            textColor={(theme) => theme.palette.base.light}
            isRounded
            sx={{ padding: '8px 32px', fontWeight: 'bold', fontSize: '16px' }}
            onClick={transitToSignup}
          >
            サインアップして続ける&ensp;&gt;
          </Button>

        </Stack>
        {!isTablet && (
          <Stack sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            <img src={`${S3_BUCKET_URL}/sokosuto_study.png`} width="278px" height="256px" alt="" />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
