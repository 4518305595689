import { styled, Stack, Tabs, Tab } from '@mui/material'
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { BaseTemplate } from '@c/templates/BaseTemplate'

const StyledTabs = styled((props: {
  children?: ReactNode;
  value: number;
  onChange: (event: SyntheticEvent, newValue: number) => void;
}) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '80%',
    width: '100%',
    backgroundColor: `${theme.palette.border.main}AA`
  }
}))

const StyledTab = styled((props: {label: string; value: number;}) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.text.primary
  }
  // '&.Mui-focusVisible': {
  //   backgroundColor: `${theme.palette.primary.main}10`
  // }
}))

const linkItems = [
  {
    label: '🏃 開催中イベント',
    path: '/events/inprogress'
  },
  {
    label: '👑 主催イベント',
    path: '/events/manage'
  }
]

export function Events() {
  const location = useLocation()
  const navigate = useNavigate()

  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    // [Todo] /eventsの時のちらつきをloadingに変更
    if (location.pathname === '/events') {
      navigate('/events/inprogress')
    }
  }, [location])

  useEffect(() => {
    switch (tabValue) {
      case 0:
        navigate('/events/inprogress')
        break
      case 1:
        navigate('/events/manage')
        break
      default:
    }
  }, [tabValue])

  return (
    <BaseTemplate>
      <Stack
        direction="row"
        spacing="10px"
        sx={{
          marginTop: '20px', minWidth: '880px', minHeight: '212px' }}
      >
        <Stack>
          <StyledTabs value={tabValue} onChange={handleChange} sx={{ width: '100%' }}>
            {linkItems.map(({ label, path }, index) => (
              <StyledTab label={label} value={index} key={path} />
            ))}
          </StyledTabs>

          <Outlet />
        </Stack>
      </Stack>
    </BaseTemplate>
  )
}
